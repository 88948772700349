import { useContext, useEffect } from 'react'

import { NavigationEventsContext } from './NavigationEventsContext'

export const useNavigationEvents = (handler, deps) => {
  const onChange = useContext(NavigationEventsContext)

  useEffect(() => {
    const unsubscribe = onChange(handler)
    return () => {
      unsubscribe()
    }
  }, deps)
}
