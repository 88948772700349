import { css } from 'styled-components'

import { gridOffsetFrom, gridOffsetTo, modularScale } from '../constants/sizes'
import { between } from '../utils/between'
import { media, mediaMax, mediaObj } from '../utils/media'

// Temp mixin while I work on a PR to fix fontFace in polished
// https://github.com/styled-components/polished/issues/257

function betweenProperty(property, from, to, betweenOptions = {}) {
  if (Array.isArray(from) !== Array.isArray(to)) {
    throw new Error('From and to should both be of the same type')
  }

  if (Array.isArray(from) && Array.isArray(to)) {
    return css`
      ${property}: ${between(from[0], to[0], betweenOptions)}
        ${between(from[1], to[1], betweenOptions)};
    `
  }

  return css`
    ${property}: ${between(from, to, betweenOptions)};
  `
}

function horizontalPadding(multiplier = 1) {
  return css`
    ${betweenProperty(
      'padding-left',
      gridOffsetFrom * multiplier,
      gridOffsetTo * multiplier
    )};

    ${betweenProperty(
      'padding-right',
      gridOffsetFrom * multiplier,
      gridOffsetTo * multiplier
    )};

    ${mediaMax.md(css`
      padding-left: ${gridOffsetFrom * multiplier}px;
      padding-right: ${gridOffsetFrom}px;
    `)};
  `
}

function disableScrollbarOnMobile() {
  return mediaMax.md(css`
    ::-webkit-scrollbar {
      background-color: transparent;
      display: none;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    -ms-overflow-style: none;
    overflow: -moz=scrollbars-none;
  `)
}

// Usage:
//   position: relative;
//  &::before {
//    content: '';
//    ${mixins.increaseClickArea()};
//  }
function increaseClickArea(percentage = '20%') {
  return css`
    position: absolute;
    top: -${percentage};
    left: -${percentage};
    right: -${percentage};
    bottom: -${percentage};
  `
}

const COLUMN = 64
const GUTTER = 43

// Used to offset content relative to the 12 col grid
function offset({
  columns = 1,
  property = 'padding',
  direction = 'left',
} = {}) {
  const amount = COLUMN + GUTTER
  return css`
    ${`${property}-${direction}: ${between(
      (amount * columns) / modularScale,
      amount * columns
    )};`}
    ${mediaMax.md(`
      ${`${property}-${direction}`}: ${gridOffsetFrom * columns}px};
    `)}
  `
}

function span({ columns = 1, gutters = 1, noCss = false } = {}) {
  const amount = COLUMN * columns + GUTTER * gutters
  const spanVal = between(amount / modularScale, amount)

  return noCss
    ? spanVal
    : css`
        ${spanVal};
      `
}

function siteGutter({ left = true, right = true } = {}) {
  return css`
    ${left ? `padding-left: ${gridOffsetFrom}px;` : ''}
    ${right ? `padding-right: ${gridOffsetFrom}px;` : ''}
    ${media.md(`
      ${left ? `padding-left: ${between(gridOffsetFrom, gridOffsetTo)};` : ''}
      ${right ? `padding-right: ${between(gridOffsetFrom, gridOffsetTo)};` : ''}
    `)}
    @media (min-width: 1600px) {
      padding-left: 120px;
      padding-right: 120px;
    }
    @media (min-width: 2000px) {
      padding-left: 160px;
      padding-right: 160px;
    }
  `
}
function siteGutterObj({ left = true, right = true } = {}) {
  return {
    paddingLeft: left ? gridOffsetFrom : 0,
    paddingRight: right ? gridOffsetFrom : 0,

    [mediaObj.md]: {
      paddingLeft: left ? between(gridOffsetFrom, gridOffsetTo) : 0,
      paddingRight: right ? between(gridOffsetFrom, gridOffsetTo) : 0,
    },

    '@media (min-width: 1600px)': {
      paddingLeft: 120,
      paddingRight: 120,
    },

    '@media (min-width: 2000px)': {
      paddingLeft: 160,
      paddingRight: 160,
    },
  }
}

function breakFromSiteGutter({ left = true, right = true } = {}) {
  return css`
    ${left ? `margin-left: -${gridOffsetFrom}px;` : ''}
    ${right ? `margin-right: -${gridOffsetFrom}px;` : ''}
    ${media.md(`
      ${left ? `margin-left: -${between(gridOffsetFrom, gridOffsetTo)};` : ''}
      ${right ? `margin-right: -${between(gridOffsetFrom, gridOffsetTo)};` : ''}
    `)}
    @media (min-width: 1600px) {
      margin-left: -120px;
      margin-right: -120px;
    }
    @media (min-width: 2000px) {
      margin-left: -160px;
      margin-right: -160px;
    }
  `
}

export const mixins = {
  betweenProperty,
  increaseClickArea,
  horizontalPadding,
  offset,
  span,
  disableScrollbarOnMobile,
  siteGutter,
  siteGutterObj,
  breakFromSiteGutter,
}
