import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Container = styled.div``

export const Header = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing[1.5]} 0;
  text-align: left;
  border-top: 1px solid #bfbfbf;
  cursor: pointer;

  &:active {
    outline: 0;
  }
`

export const Content = styled(motion.div)<{ collapsed: boolean }>`
  height: ${({ collapsed }) => (collapsed ? 0 : 'auto')};
  overflow: hidden;
`
