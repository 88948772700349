import { gql } from '@apollo/client'

import { CART_FRAGMENT } from './fragments/cart'

export const REMOVE_MEMBERSHIP_TOKEN_TO_CART = gql`
  ${CART_FRAGMENT}
  mutation RemoveMembershipTokenFromCart($input: RemoveMembershipTokenInput!) {
    removeMembershipToken(input: $input) {
      ...CartFragment
    }
  }
`
