import { gql } from '@apollo/client'

import { CART_FRAGMENT } from './fragments/cart'

export const CREATE_CART = gql`
  ${CART_FRAGMENT}
  mutation CreateCart($input: CreateCartInput!) {
    createCart(input: $input) {
      ...CartFragment
    }
  }
`
