import { useEffect, useState } from 'react'

/**
 * Custom hooks that return the keys that are pressed on the keyboard
 */

export const useKeyDown = (callback?: (e: any, keys: string[]) => void) => {
  const [keys, setKeys] = useState([])

  const handleKeyDown = e => {
    if (keys.includes(e.keyCode)) {
      return
    }

    if (callback) {
      callback(e, [...keys, e.keyCode])
    }

    setKeys([...keys, e.keyCode])
  }

  const handleKeyUp = e => {
    const d = keys.indexOf(e.keyCode, 0)
    setKeys([...keys.slice(0, d), ...keys.slice(d + 1)])
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    document.addEventListener('keyup', handleKeyUp, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
      document.removeEventListener('keyup', handleKeyUp, false)
    }
  }, [keys])

  return keys
}
