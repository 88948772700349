import { cacheExchange, Client, fetchExchange, Provider } from 'urql'

import { correlationId } from './correlationId'

const changeLocalhostToIP = (url: string) => {
  const urlObject = new URL(url)
  if (urlObject.hostname === 'localhost') {
    urlObject.hostname = '127.0.0.1'
  }
  return urlObject.toString()
}

export const serverSideClient = new Client({
  url: changeLocalhostToIP(process.env.NEXT_PUBLIC_NEW_GRAPHQL_URL),
  exchanges: [cacheExchange, fetchExchange],

  fetchOptions: {
    headers: {
      'X-Correlation-Id': correlationId,
      'x-graphql-client-name': 'bluelagoon-server', // This is used to identify the client in Stellate
    },
  },
})

export const client = new Client({
  url: process.env.NEXT_PUBLIC_NEW_GRAPHQL_URL,
  exchanges: [cacheExchange, fetchExchange],
  fetchOptions: {
    headers: {
      'X-Correlation-Id': correlationId,
      'x-graphql-client-name': 'bluelagoon', // This is used to identify the client in Stellate
    },
  },
})

export const UrqlClientProvider = ({ children }) => {
  return <Provider value={client}>{children}</Provider>
}
