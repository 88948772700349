import CorrelationIdService from './CorrelationIdContext'
import { getOrSetCorrelationIdCookie } from './utils'

export const withCorrelationId = (Component: any) => {
  const WithCorrelationId = ({ correlationId, ...componentProps }) => {
    return (
      <CorrelationIdService correlationId={correlationId}>
        <Component {...componentProps} correlationId={correlationId} />
      </CorrelationIdService>
    )
  }

  WithCorrelationId.getInitialProps = async context => {
    let componentProps: Record<string, any> = {}

    const correlationId = getOrSetCorrelationIdCookie(context.ctx)

    if (Component.getInitialProps) {
      componentProps = await Component.getInitialProps({
        ...context,
        correlationId,
      })
    }

    return {
      ...componentProps,
      correlationId,
    }
  }

  return WithCorrelationId
}

export default withCorrelationId
