import { useMemo } from 'react'
import { useWindowSize } from '@react-hookz/web'

import { breakpoints } from '../constants/breakpoints'

type BreakpointsChecks = {
  mediaXs?: boolean
  mediaSm?: boolean
  mediaSmd?: boolean
  mediaMd?: boolean
  mediaBmd?: boolean
  mediaMlg?: boolean
  mediaLg?: boolean
  mediaXl?: boolean

  isMobile?: boolean
  isTablet?: boolean
  isDesktop?: boolean
}

// TODO: Would be 'better' to do something like this? And then.. Why?
// import { observe, useObserver, viewportWidth } from 'react-ui-observer'
// const isMobile = useObserver(
//   observe(viewportWidth(), (width: number) => width < breakpoints.md)
// )

export const useBreakpoints = (): BreakpointsChecks => {
  const { width } = useWindowSize(undefined, true)

  const mediaXs = true
  const mediaSm = width >= breakpoints.sm
  const mediaSmd = width >= breakpoints.smd
  const mediaMd = width >= breakpoints.md
  const mediaBmd = width >= breakpoints.bmd
  const mediaMlg = width >= breakpoints.mlg
  const mediaLg = width >= breakpoints.lg
  const mediaXl = width >= breakpoints.xl

  const isMobile = width < breakpoints.md
  const isTablet = width >= breakpoints.md && width < breakpoints.lg
  const isDesktop = width >= breakpoints.lg

  const memoizedBreakpoints = useMemo(
    () => ({
      mediaXs,
      mediaSm,
      mediaSmd,
      mediaMd,
      mediaBmd,
      mediaMlg,
      mediaLg,
      mediaXl,
      isMobile,
      isTablet,
      isDesktop,
    }),
    [
      mediaXs,
      mediaSm,
      mediaSmd,
      mediaMd,
      mediaBmd,
      mediaMlg,
      mediaLg,
      mediaXl,
      isMobile,
      isTablet,
      isDesktop,
    ]
  )

  return memoizedBreakpoints
}
