import { math } from 'polished'
import styled from 'styled-components'
import Grid from 'styled-components-grid'

import { gutter } from '../constants/sizes'

const Row = styled(Grid)`
  margin-left: ${math(`-${gutter}px / 2`)};
  margin-right: ${math(`-${gutter}px / 2`)};
`

export { Row }
