import { gql } from '@apollo/client'

export const VALIDATE_BOOKING_TDSM = gql`
  mutation validateTdsm($info: validateTDSMInput!) {
    validateTdsm(info: $info) {
      bookingId
      acsInfo {
        acsForm
        acsFormData {
          name
          value
        }
      }
    }
  }
`
