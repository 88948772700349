import styled from 'styled-components'

import { colors } from '../constants/colors'

type SliderPaginationProps = {
  activeIndex: number
  count: number
  onClick: (index: number) => void
}

const SliderPaginationContainer = styled.div({
  display: 'flex',
  columnGap: 12,
})

const Indicator = styled.button<{ active: boolean }>(({ active }) => ({
  width: active ? 50 : 24,
  height: 3,
  background: active ? colors.deepBlue : colors.blueOnDark,

  transition: 'width 0.5s, background 0.5s',
  transitionTimingFunction: 'cubic-bezier(0.645,0.045,0.355,1)',
}))

export const SliderPagination = ({
  activeIndex = 0,
  count,
  onClick,
}: SliderPaginationProps) => {
  return (
    <SliderPaginationContainer>
      {Array.from({ length: count }).map((_, index) => (
        <Indicator
          key={index}
          active={index === activeIndex % count}
          aria-label={`go to page ${index + 1}`}
          onClick={() => onClick(index)}
        />
      ))}
    </SliderPaginationContainer>
  )
}
