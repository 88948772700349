import { observe, scrollY, useObserver } from 'react-ui-observer'
import styled, { css } from 'styled-components'

import { durations } from '../../constants/durations'
import { mediaMax } from '../../utils/media'

type ScrollArrowContainer = {
  delay: number
  shouldAnimate: boolean
  bottom: string
}

const ScrollArrowContainer = styled.div<ScrollArrowContainer>`
  animation: jump ${durations.long * 2}ms ${props => props.delay}ms ease-in-out
    infinite;
  animation-play-state: ${props =>
    props.shouldAnimate ? 'running' : 'paused'};

  ${({ bottom }) =>
    bottom &&
    css`
      position: absolute;
      bottom: ${bottom};
    `};

  ${mediaMax.md(css`
    display: none;
  `)};

  @keyframes jump {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0);
    }
  }
`

export const ScrollArrow = ({
  rotate = 0,
  scale = 1,
  bottom = undefined,
  delay = 0,
  infinite = false,
}) => {
  const userHasScrolled = useObserver(
    observe(scrollY(), scrollY => scrollY >= 118)
  )

  return (
    <ScrollArrowContainer
      shouldAnimate={infinite || !userHasScrolled}
      bottom={bottom}
      delay={delay}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="24"
        style={{ transform: `rotate(${rotate}deg) scale(${scale})` }}
      >
        <g fill="currentColor">
          <path d="M10 24c-.128 0-.256-.05-.354-.146l-9-9c-.195-.195-.195-.512 0-.707.195-.195.512-.195.707 0L10 22.793l8.646-8.646c.195-.195.512-.195.707 0 .195.195.195.512 0 .707l-9 9c-.097.097-.225.146-.353.146z" />
          <path d="M10 24c-.276 0-.5-.224-.5-.5V.5c0-.276.224-.5.5-.5s.5.224.5.5v23c0 .276-.224.5-.5.5z" />
        </g>
      </svg>
    </ScrollArrowContainer>
  )
}

const ArrowContainer = styled.div`
  transition: transform 200ms ease;
`

export const Arrow = ({ rotate = 0, scale = 1, color = 'currentcolor' }) => (
  <ArrowContainer style={{ transform: `rotate(${rotate}deg) scale(${scale})` }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20">
      <g fill={color}>
        <path d="M24 10c0 .128-.05.256-.146.354l-9 9c-.195.195-.512.195-.707 0-.195-.195-.195-.512 0-.707L22.793 10l-8.646-8.646c-.195-.195-.195-.512 0-.707.195-.195.512-.195.707 0l9 9c.097.097.146.225.146.353z" />
        <path d="M24 10c0 .276-.224.5-.5.5H.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h23c.276 0 .5.224.5.5z" />
      </g>
    </svg>
  </ArrowContainer>
)

export const NavArrow = ({ rotate = 0, scale = 1, color = 'currentcolor' }) => (
  <ArrowContainer style={{ transform: `rotate(${rotate}deg) scale(${scale})` }}>
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 1L1 7L7 13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ArrowContainer>
)

export const LinkArrow = ({
  rotate = 0,
  scale = 1,
  y = 0 as string | number,
  width = 15 as string | number,
  height = 10 as string | number,
  thick = false,
  fill = 'currentColor',
}) =>
  thick ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 10"
      fill="none"
      style={{
        transform: `rotate(${rotate}deg) scale(${scale})  translateY(${y})`,
      }}
    >
      <path
        fill={fill}
        d="M11.7816 4.10794L11.2491 3.58056L7.90438 0.220371C7.6119 -0.073457 7.13944 -0.073457 6.84696 0.220371C6.55448 0.514199 6.55448 0.988844 6.84696 1.28267L9.44176 3.88945H0.749941C0.337473 3.88945 0 4.22848 0 4.64286C0 5.05723 0.337473 5.39626 0.749941 5.39626H9.44176L6.84696 8.00304C6.55448 8.29687 6.55448 8.77152 6.84696 9.06534C7.13944 9.35917 7.6119 9.35917 7.90438 9.06534L11.2491 5.70516L11.7741 5.17777C12.074 4.87641 12.074 4.40177 11.7816 4.10794Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 15 10"
      width={width}
      height={height}
      style={{
        transform: `rotate(${rotate}deg) scale(${scale})  translateY(${y})`,
      }}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M9.58702 1.06368l3.31708 3.31128H.62497C.28124 4.37496 0 4.6562 0 4.99994c0 .34373.28124.62497.62497.62497H12.9046L9.5919 8.93653c-.24374.24366-.24374.63727 0 .88093.24374.24364.6375.24364.8812 0l3.8999-3.90485.4437-.43735c.1268-.12672.1876-.294.1825-.45965.0001-.00215.0001-.0043.0001-.00645.0122-.17357-.0482-.35122-.1812-.48421l-.4438-.43735L10.4682.18275c-.2437-.24367-.63744-.24367-.88118 0-.24374.24366-.24374.63727 0 .88093z"
        clipRule="evenodd"
      />
    </svg>
  )
