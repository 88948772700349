import { lastValueSymbol, Node } from './nodes'

const eventHandler = (onChange, event) => {
  window.addEventListener(event, onChange)
  return () => {
    window.removeEventListener(event, onChange)
  }
}

const intervalHandler = (onChange, ms) => {
  // eslint-disable-next-line @typescript-eslint/no-implied-eval
  const intervalId = setInterval(onChange, ms)
  return () => {
    clearInterval(intervalId)
  }
}

const scrollYHandler = () => window.pageYOffset

const viewportHeightHandler = () => window.innerHeight

const elementWidthHandler = element => element && element.offsetWidth

const elementHeightHandler = element => element && element.offsetHeight

const viewportRatioHandler = (ratio, scroll, size) => scroll + ratio * size

const elementYHandler = (ratio, offset, size) => offset.top + ratio * size

const rootElementHandler = context => context.rootElement

const elementOffsetHandler = element => {
  let x = 0
  let y = 0
  while (element && !isNaN(element.offsetLeft) && !isNaN(element.offsetTop)) {
    x += element.offsetLeft + element.clientLeft
    y += element.offsetTop + element.clientTop

    if (element === document.body) {
      x -=
        window.pageXOffset ||
        document.documentElement.scrollLeft ||
        document.body.scrollLeft ||
        0
      y -=
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0
    } else {
      x -= element.scrollLeft
      y -= element.scrollTop
    }

    element = element.offsetParent
  }
  return {
    top: y + window.pageYOffset + document.documentElement.offsetTop,
    left: x + window.pageXOffset + document.documentElement.offsetTop,
  }
}

const createNodeFactory =
  type =>
  (...deps) => {
    const func = deps.pop()
    return new Node(func, deps, type)
  }

const subscribe = createNodeFactory('event')

const context = createNodeFactory('context')

export const observe = createNodeFactory('observe')

export const lastValue = () => lastValueSymbol

export const resizeEvent = () => subscribe('resize', eventHandler)

const scrollEvent = () => subscribe('scroll', eventHandler)

export const intervalEvent = ms => subscribe(ms, intervalHandler)

const scrollY = () => observe(scrollEvent(), scrollYHandler)

const viewportHeight = () => observe(resizeEvent(), viewportHeightHandler)

export const viewportY = (ratio = 0) =>
  ratio
    ? observe(ratio, scrollY(), viewportHeight(), viewportRatioHandler)
    : scrollY()

const rootElement = () => context(rootElementHandler)

const elementOffset = (element = rootElement(), deps = [resizeEvent()]) =>
  observe(element, ...deps, elementOffsetHandler)

export const elementHeight = (
  element = rootElement(),
  deps = [resizeEvent()]
) => observe(element, ...deps, elementHeightHandler)

export const elementWidth = (element = rootElement(), deps = [resizeEvent()]) =>
  observe(element, ...deps, elementWidthHandler)

export const elementY = (
  ratio = 0,
  element = rootElement(),
  deps = [resizeEvent()]
) =>
  observe(
    ratio,
    elementOffset(element, deps),
    elementHeight(element, deps),
    elementYHandler
  )
