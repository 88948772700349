import { gql } from '@apollo/client'

export const GET_AVAILABLE_DAYS = gql`
  query dayAvailability($startDate: String!, $endDate: String!) {
    dayAvailability(startDate: $startDate, endDate: $endDate) {
      available
      date
      lowestPrice
      soldOut
    }
  }
`
