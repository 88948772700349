const memoryStorage: { [key: string]: string } = {}

const getItem = (key: string) => {
  try {
    return window.sessionStorage.getItem(key)
  } catch {
    return memoryStorage[key] || null
  }
}

const setItem = (key: string, value: string) => {
  try {
    window.sessionStorage.setItem(key, value)
  } catch {
    memoryStorage[key] = value
  }
}

export const sessionStorage = {
  getItem,
  setItem,
}
