import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { media } from 'bl-common/src/utils/media'

export const HEIGHT = 24

export const Container = styled(motion.div)`
  position: relative;
  z-index: ${zIndex.aboveMenu};

  ${media.mlg(css`
    z-index: 12;
  `)}
`

export const Content = styled.div`
  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  ${media.md(css`
    flex-direction: row;
    flex-wrap: wrap;
  `)}
`

export const Banner = styled.div`
  align-items: center;
  color: ${colors.white};
  display: flex;
  position: relative;
  padding-left: 8px;

  i {
    color: ${colors.blueOnDark};
  }

  ${media.md(css`
    padding-right: ${({ theme }) => theme.spacing[2]};

    :not(:last-child) {
      ::after {
        content: '|';
        position: absolute;
        right: 0;
      }
    }
  `)}
`

export const Text = styled(Type)`
  /* A feature requirement was to be able to underline text so we replace italic with underline */
  & i {
    font-weight: bold;
    font-style: unset;

    text-decoration: underline;
    white-space: nowrap;
    padding-left: 4px;
  }
`
export const CloseButton = styled.button`
  cursor: pointer;
  padding: 16px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 48px;
  margin: auto;
`
