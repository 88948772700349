import { gql } from '@apollo/client'

export const CHECKOUT = gql`
  mutation Checkout($input: CheckoutInput!) {
    checkout(input: $input) {
      hotelGroupConfirmationCode
      hotelConfirmationCodes
    }
  }
`
