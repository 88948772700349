import { gql } from '@apollo/client'

export const VERIFY_MEMBERSHIP_LOGIN_CODE = gql`
  mutation verifyMembershipLoginCode($info: VerifyMembershipLoginCodeInfo!) {
    verifyMembershipLoginCode(info: $info) {
      mainContact {
        id
        firstName
        lastName
        personalId
        email
        phoneNumber
      }
      secondaryContact {
        id
        firstName
        lastName
        personalId
        email
        phoneNumber
      }
    }
  }
`
