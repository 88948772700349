import { gql } from '@apollo/client'

import { CART_FRAGMENT } from './fragments/cart'

export const REMOVE_ITEM_FROM_CART = gql`
  ${CART_FRAGMENT}
  mutation RemoveItemFromCart($input: RemoveCartItemInput!) {
    removeItem(input: $input) {
      ...CartFragment
    }
  }
`
