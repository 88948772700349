const isServer = typeof window === 'undefined'

type VercelEnvironment = 'development' | 'preview' | 'production'
type FeatureFlagEnvironment = 'development' | 'production'

export const isLocalhost =
  typeof window !== 'undefined'
    ? window.location.hostname === 'localhost'
    : process.env.NODE_ENV !== 'production'

export const getCurrentEnvironment = (): FeatureFlagEnvironment => {
  if (isServer) {
    const vercelEnv = process.env.VERCEL_ENV as VercelEnvironment

    return vercelEnv === 'preview' ? 'development' : vercelEnv
  }

  if (window.location.origin.includes('bluelagoon.com')) {
    return 'production'
  }

  return 'development'
}

export const isActiveOnEnvironment = (
  environments: FeatureFlagEnvironment[]
) => {
  const currentEnv = getCurrentEnvironment()

  return environments.includes(currentEnv)
}

export const requireEnvironment =
  (visibleEnvironments: FeatureFlagEnvironment[], fallbackPath: string, cb) =>
  ctx => {
    const isVisible = isActiveOnEnvironment(visibleEnvironments)

    if (!isVisible) {
      ctx.res.writeHead(307, { Location: fallbackPath })
      return ctx.res.end()
    }

    return cb(ctx)
  }
