import { gql } from '@apollo/client'

export const FIND_GIFTCARD = gql`
  query findGiftCard($where: FindGiftCardPayload!) {
    findGiftCard(where: $where) {
      amount
      customerName
      customerEmail
      number
      externalDocumentNo
      status
      status
      reasonBlocked
      validTo
      recipientName
      Message
      recipientEmail
      recipientAddress
      recipientAddress2
      recipientPostCode
      recipientPostCode
      recipientCountryCode
      recipientPhoneNumber
      url
      title
      isExperience
      giftCardName
      shippingMethod
      shippingAmount
    }
  }
`
