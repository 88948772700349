import { useState } from 'react'

import { Input } from './Input/Input'

const formatNationalId = value => {
  const val = value.replace(/\D/g, '')

  const formatted = val
    .replace(/(\d{6})/, '$1-')
    .replace(/(\d{4}) (\d{4})/, '$1-$2')

  return formatted
}

export const NationalIdInput = ({ onChange, value, ...rest }) => {
  const [text, setText] = useState(value || '')

  const handleChange = event => {
    let newValue = event.target.value.trim()

    if (newValue.length > text.length) {
      newValue = formatNationalId(newValue)
    }

    if (newValue.length > 11) {
      newValue = newValue.slice(0, 11)
    }

    setText(newValue)
    onChange(event.target.name)(newValue)
  }

  return (
    <Input value={text} {...rest} inputMode="numeric" onChange={handleChange} />
  )
}
