import styled, { css } from 'styled-components'

import { colors } from '../constants/colors'
import { between } from '../utils/between'
import { media } from '../utils/media'
import { Type } from './Typography/Typography'

type CopyProps = {
  itemStyle?: string
}

const Copy = styled(Type)<CopyProps>`
  p,
  li {
    font-size: 14px;
    ${media.md(css`
      font-size: ${({ theme }) => theme.spacing[1]};
    `)};
  }

  a {
    color: ${colors.deepBlue};
    font-weight: 600;
  }

  h2 {
    font-size: 20px;
    font-weight: 500;
    ${media.md(css`
      font-size: ${({ theme }) => theme.spacing[2]};
    `)};
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    ${media.md(css`
      font-size: ${({ theme }) => theme.spacing[1.5]};
    `)};
  }

  h4,
  h5,
  h6 {
    font-weight: 600;
  }

  blockquote {
    font-size: 0.9em;
    margin: 2rem 0;
    padding: 0;
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      content: '';
      width: ${between(4, 8)};
      background: linear-gradient(45deg, #6793ae 0%, #50a7ba 100%);
    }

    ${media.lg(css`
      &::before {
        width: 8px;
      }
    `)};
  }

  ul,
  ol {
    margin-right: ${({ theme }) => theme.spacing[1]};
  }

  li {
    position: relative;

    &::before {
      content: '•';
      position: absolute;
      left: 0;
      top: 0;

      ${({ itemStyle }) =>
        itemStyle === 'Additional' &&
        css`
          content: '+';
          font-weight: 600;
        `};
    }
  }

  br {
    display: block;
  }

  ${({ theme }) => css`
    ul,
    ol {
      margin-right: ${theme.spacing[1]};
    }

    li {
      padding-left: ${theme.spacing[1.5]};
    }

    * + *:not(li):not(hr) {
      padding-top: ${theme.spacing[1]};
    }

    *:not(li):not(hr) + h1,
    *:not(li):not(hr) + h2,
    *:not(li):not(hr) + h3 {
      padding-top: ${theme.spacing[2]};
    }

    h1 + *:not(li):not(hr),
    h2 + *:not(li):not(hr) {
      padding-top: ${theme.spacing[2]};
    }

    blockquote {
      padding-left: ${theme.spacing[2]};
    }
  `};

  hr {
    margin: 2em 0;
  }

  table {
    border-spacing: 0;
    width: 100%;

    thead {
      background: rgba(0, 0, 0, 0.08);
      text-align: left;
    }

    tr {
      &:nth-of-type(even) {
        background: rgba(0, 0, 0, 0.03);
      }

      td,
      th {
        ${({ theme }) => css`
          padding: ${theme.spacing[1]};
        `};
      }
    }
  }
`

export default Copy
