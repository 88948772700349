import Script from 'next/script'

const GOOGLE_TAG_MANAGER_CONTAINER_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID

declare global {
  interface Window {
    cookiehub?: any
  }
}

export const LoadTracking = () => {
  return (
    <>
      {/* Google Tag Manager handles loading of cookiehub and consequently all trackers */}
      <Script
        strategy="afterInteractive"
        id="google-tag-manager-script"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_CONTAINER_ID}');`,
        }}
      />
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_CONTAINER_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      {/* These scripts do not run until cookiehub enables them: https://support.cookiehub.com/article/156-adjust-script-and-iframe-tags-to-delay-load# */}
      <Script
        strategy="afterInteractive"
        type="text/plain"
        data-consent="analytics"
        id="vwo-script"
      >
        {`
          window._vwo_code = window._vwo_code || (function(){
          var account_id=570197,
          settings_tolerance=2000,
          library_tolerance=2500,
          use_existing_jquery=false,
          is_spa=1,
          hide_element=false,
          /* DO NOT EDIT BELOW THIS LINE */
          f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
          window.settings_timer=setTimeout(function () {_vwo_code.finish() },settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());
      `}
      </Script>
    </>
  )
}
