import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { differenceInMinutes, parseISO } from 'date-fns'
import throttle from 'lodash/throttle'
import { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { CloseIcon } from 'bl-common/src/elements/Icons/CloseIcon'
import { useIsomorphicLayoutEffect } from 'bl-common/src/hooks/useIsomorphicLayoutEffect'
import { HyperLink } from 'bl-common/src/richText/RichTextBlocks'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

import { BannerHeightContext } from '../BannerHeight'
import { Banner, CloseButton, Container, Content, Text } from './styles'

const documentOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Text preset="text" weight="medium">
        {children}
      </Text>
    ),
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
      } = node
      return (
        <HyperLink
          to={uri}
          color={colors.blueOnDark}
          target={'_blank'}
          style={{ textDecoration: 'underline' }}
        >
          {children}
        </HyperLink>
      )
    },
  },
}

export const BANNER_ID = 'information-banner'

export default ({ banners = [], color = 'dark' }) => {
  const ref = useRef<HTMLDivElement>()
  const { setBannerHeight } = useContext(BannerHeightContext)
  const [closed, setClosed] = useState(false)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(true)
  }, [])

  const isWindowDefined = typeof window !== 'undefined'

  const onClose = contentfulId => {
    if (isWindowDefined) {
      window.sessionStorage.setItem(
        `has-closed-banner-${contentfulId}`,
        new Date().toISOString()
      )
    }
    setClosed(true)
  }
  const shouldShowBannerAgain = contentfulId => {
    const closedBannerKey = `has-closed-banner-${contentfulId}`

    if (isWindowDefined) {
      const bannerTimeStamp = window.sessionStorage.getItem(closedBannerKey)
      const bannerTimeStampISO = bannerTimeStamp && parseISO(bannerTimeStamp)

      if (
        bannerTimeStamp &&
        // we want to show the banner again after 45 minutes
        differenceInMinutes(new Date(), bannerTimeStampISO) >= 1
      ) {
        window.sessionStorage.removeItem(closedBannerKey)
      }
    }
  }

  // on reloads, check if closed banners should be shown again
  useEffect(() => {
    banners.forEach(banner => {
      shouldShowBannerAgain(banner?.sys?.id)
    })
  }, [banners])

  // only include banners that havent already been closed
  const filteredBanners = banners?.filter(banner => {
    if (
      isWindowDefined &&
      !window.sessionStorage.getItem(`has-closed-banner-${banner?.sys?.id}`)
    ) {
      return banner
    }
  })

  const updateBannerHeight = useCallback(() => {
    if (ref?.current) {
      setBannerHeight(ref.current.offsetHeight + ref.current.offsetTop)
    }
  }, [ref])

  useIsomorphicLayoutEffect(() => {
    updateBannerHeight()
  }, [banners, isReady])

  useEffect(() => {
    window.addEventListener(
      'resize',
      throttle(() => updateBannerHeight(), 100)
    )
    return () => window.removeEventListener('resize', updateBannerHeight)
  }, [])

  if (banners.length === 0 || !isReady) {
    return null
  }

  const renderBanner = banner => {
    // Check if the banner is a notification popup
    if (banner.sys?.contentType?.sys?.id === 'notificationPopup') {
      return (
        <Banner key={banner.fields.name}>
          <RichTextRenderer
            document={banner.fields.content}
            customOptions={documentOptions}
          />
        </Banner>
      )
    }

    // Default behavior as it was
    return (
      <Banner key={banner.fields.text}>
        <Text
          dangerouslySetInnerHTML={{
            __html: formatHtmlText(banner.fields.text, css``, '_self'),
          }}
          preset="text"
          weight="medium"
        />
      </Banner>
    )
  }

  return (
    <Container
      ref={ref}
      id={BANNER_ID}
      style={{
        backgroundColor: colors[color],
      }}
      initial={{ opacity: 1, height: 'auto' }}
      animate={{
        opacity: closed ? 0 : 1,
        height: closed ? 0 : 'auto',
        transitionEnd: { display: closed && 'none' },
      }}
      transition={{ duration: 0.5 }}
      onAnimationComplete={() => {
        if (closed) {
          setBannerHeight(0)
        }
      }}
    >
      {filteredBanners.map(banner => (
        <div key={banner.sys?.id}>
          <Content>{renderBanner(banner)}</Content>
          <CloseButton onClick={() => onClose(banner?.sys?.id)}>
            <CloseIcon color={colors.white} />
          </CloseButton>
        </div>
      ))}
    </Container>
  )
}
