import { gql } from '@apollo/client'

export const ORDER_MEMBERSHIP = gql`
  mutation orderMembership($info: OrderMembershipInformation!) {
    orderMembership(info: $info) {
      membershipRef
      acsInfo {
        acsForm
        acsFormData {
          name
          value
        }
      }
      tdsmInfo {
        tdsmContent
      }
    }
  }
`
