import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { Type } from '../../elements/Typography/Typography'
import { PartialBookingEngine } from '../../styles/types'
import { media } from '../../utils/media'

type RadioWrapperProps = {
  withBorder?: boolean
  checked?: boolean
  $width?: string
}

const RadioWrapper = styled.label<RadioWrapperProps>`
  align-items: start;
  cursor: pointer;
  display: flex;
  position: relative;
  padding: ${({ theme }) => theme.spacing[1]}0;
  width: ${({ $width }) => $width || 'inherit'};

  ${media.md(css`
    padding: ${({ theme }) => theme.spacing[0.5]} 0;
  `)}

  ${({ theme, withBorder, checked }) =>
    withBorder &&
    css`
      border: 1px solid ${checked ? colors.fountainBlue : '#d2d2d2'};
      padding: ${theme.spacing[1]} ${theme.spacing[1]};

      ${media.md(css`
        padding: ${theme.spacing[1]} ${theme.spacing[1]};
      `)}
    `}
`

// This container is a Type because the dot relies on parent font-size to determine its size(using lh units).
// This is needed because the dot is not a descendant of the label.
const RadioDotContainer = styled(Type)`
  order: -1;
`

const RadioDot = styled.div<{
  themeStyle?: PartialBookingEngine['massageCard']['radio']
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.15lh;
  height: 0.75lh;
  min-height: 0.75lh;
  width: 0.75lh;
  min-width: 0.75lh;
  border-radius: 50%;
  border: 1px solid
    ${props => props?.themeStyle?.radioDotBorderColor ?? colors.midGrey};
  position: relative;
  margin-right: ${({ theme }) => theme.spacing[1]};
  order: -1;

  ::after {
    background: ${props =>
      props?.themeStyle?.radioDotColor ?? colors.fountainBlue};
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
`

const Input = styled.input<{
  themeStyle?: PartialBookingEngine['massageCard']['radio']
}>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  :checked + ${RadioDotContainer} ${RadioDot} {
    ::after {
      content: '';
    }
  }

  :focus + ${RadioDotContainer} ${RadioDot} {
    box-shadow: 0 0 0 2px
      ${props => props?.themeStyle?.radioDotBorderColor ?? colors.fountainBlue};
  }
`

export const SimpleRadio = ({
  id,
  name,
  label,
  value,
  ariaLabel = '',
  checked = false,
  withBorder = false,
  onChange,
  wrapperWidth = undefined,
  themeStyle = undefined as PartialBookingEngine['massageCard']['radio'],
}) => {
  return (
    <RadioWrapper
      htmlFor={id}
      withBorder={withBorder}
      checked={checked}
      $width={wrapperWidth}
    >
      {label}
      <Input
        id={id}
        name={name}
        type="radio"
        value={value}
        onChange={onChange || (() => {})}
        checked={checked}
        aria-label={ariaLabel}
        themeStyle={themeStyle}
      />
      <RadioDotContainer preset="text">
        <RadioDot themeStyle={themeStyle} />
      </RadioDotContainer>
    </RadioWrapper>
  )
}
