import { gql } from '@apollo/client'

export const VALIDATE_MEMBERSHIP = gql`
  mutation validateMembership($info: ValidateMembership!) {
    validateMembership(info: $info) {
      type
      name
      userName
      validFrom
      validTo
      firstName
      lastName
      email
      price
      phone
      numberOfCardHolders
      membershipValidationToken
    }
  }
`
