import { Component, ReactNode } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Stagger } from 'bl-common/src/units/Stagger/Stagger'

// TODO: Are any of these props actually used? this looks to be the same always in the only place used (PageManager)
// Also, stagger is both in PageWrapper and in withTransition, is that necessary? Who knows
type PageWrapperProps = {
  className?: string
  isActive?: boolean
  isFirstLoad?: boolean
  setShouldUpdateScroll?(): void
  registerScrollElement?(): void
  unregisterScrollElement?(): void
  onPageReady?(): void
  children?: ReactNode
}

type PageWrapperContainerProps = {
  isActive?: boolean
}

const PageWrapperContainer = styled.div<PageWrapperContainerProps>`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  position: relative;

  ${props =>
    !props.isActive &&
    css`
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      width: 100%;
    `};
`

export class PageWrapper extends Component<PageWrapperProps> {
  static childContextTypes = {
    pageState: PropTypes.object,
  }

  getChildContext() {
    const { children, className, ...pageState } = this.props
    return {
      pageState,
    }
  }

  render() {
    const { children, isActive, isFirstLoad } = this.props
    return (
      <PageWrapperContainer isActive={isActive}>
        <Stagger appear={!isFirstLoad} in={isActive}>
          {children}
        </Stagger>
      </PageWrapperContainer>
    )
  }
}
