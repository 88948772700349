import { gql } from '@apollo/client'

export const HOTEL_ROOM_CART_ITEM_FRAGMENT = gql`
  fragment HotelRoomCartItem on HotelRoomItem {
    id
    productId
    qty
    displayName
    packageTitle
    fullName
    price
    discountedPrice
    linePrice
    totalLinePrice
    fields
    type
    offer {
      productId
      rateCodes
      fields
    }
    meta {
      arrivalDate
      departureDate
      groupCode
      numberOfAdults
      numberOfChildren
      numberOfInfants
      rateCode
      offers {
        offerId
        date
      }
    }
  }
`
const PRODUCT_CART_ITEM_FRAGMENT = gql`
  fragment ProductCartItem on ProductItem {
    id
    productId
    qty
    displayName
    fullName
    price
    discountedPrice
    linePrice
    totalLinePrice
    fields
    type
    meta {
      bookingRef
    }
  }
`

const ACTIVITY_CART_ITEM_FRAGMENT = gql`
  fragment ActivityCartItem on ActivityItem {
    id
    productId
    qty
    displayName
    fullName
    price
    discountedPrice
    linePrice
    totalLinePrice
    fields
    type
    meta {
      arrivalTime
      noOfPersons
      comment
    }
  }
`

const PRIVATE_TRANSFER_CART_ITEM_FRAGMENT = gql`
  fragment PrivateTransferCartItem on PrivateTransferItem {
    id
    productId
    qty
    displayName
    fullName
    price
    discountedPrice
    linePrice
    totalLinePrice
    fields
    type
    meta {
      arrivalTime
      comment
    }
  }
`

const TRANSPORTATION_CART_ITEM_FRAGMENT = gql`
  fragment TransportationCartItem on TransportationItem {
    id
    productId
    qty
    displayName
    fullName
    price
    discountedPrice
    linePrice
    totalLinePrice
    fields
    type
    reservationStatus
    meta {
      inboundPickupLocationId
      inboundDepartureTime
      inboundComponentId
      outboundDropoffLocationId
      outboundComponentId
      outboundDepartureTime
    }
  }
`

const ADMISSION_CART_ITEM_FRAGMENT = gql`
  fragment AdmissionCartItem on AdmissionItem {
    id
    productId
    productType
    qty
    displayName
    fullName
    price
    discountedPrice
    linePrice
    totalLinePrice
    fields
    type
    meta {
      arrivalTime
      noOfPersons
    }
  }
`

export const CART_FRAGMENT = gql`
  ${HOTEL_ROOM_CART_ITEM_FRAGMENT}
  ${PRODUCT_CART_ITEM_FRAGMENT}
  ${ACTIVITY_CART_ITEM_FRAGMENT}
  ${PRIVATE_TRANSFER_CART_ITEM_FRAGMENT}
  ${ADMISSION_CART_ITEM_FRAGMENT}
  ${TRANSPORTATION_CART_ITEM_FRAGMENT}
  fragment CartFragment on Cart {
    id
    items {
      __typename
      ...HotelRoomCartItem
      ...ProductCartItem
      ...ActivityCartItem
      ...PrivateTransferCartItem
      ...AdmissionCartItem
      ...TransportationCartItem
    }
    totalAmount
    discountedAmount
    giftCardAmount
    refundAmount
    paymentRequired
    paymentAmount
    currency
    membership {
      membershipToken
      type
      subType
    }
    giftCards {
      no
      balance
      chargeAmount
    }
    promoCode
    customer {
      firstName
      lastName
      email
      phone
      nationality
      newsletter
    }
  }
`
