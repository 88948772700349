/**
 * @deprecated Use ui-primitives/src/utils/sizes instead
 */
export const modularScale = 1.618

/**
 * @deprecated Use ui-primitives/src/utils/sizes instead
 */
export const base = 16

/**
 * @deprecated Use ui-primitives/src/utils/sizes instead
 */
export const topicLineSize = 20

/**
 * @deprecated Use ui-primitives/src/utils/sizes instead
 */
export const gridOffsetFrom = 24

/**
 * @deprecated Use ui-primitives/src/utils/sizes instead
 */
export const gridOffsetTo = 78

/**
 * @deprecated Use ui-primitives/src/utils/sizes instead
 */
export const gutter = 40
