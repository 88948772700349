import { gql } from '@apollo/client'

export const ORDER_GIFTCARD = gql`
  mutation orderGiftCard($info: OrderGiftCardInfo!) {
    orderGiftCard(info: $info) {
      giftCardNo
      acsInfo {
        acsForm
        acsFormData {
          name
          value
        }
      }
      tdsmInfo {
        tdsmContent
      }
    }
  }
`
