import { Fragment } from 'react'
import styled from 'styled-components'

import { colors } from '../constants/colors'
import { Link } from '../elements/Link'
import { Type } from '../elements/Typography/Typography'

const StyledLink = styled(Link)`
  color: ${colors.deepBlue};
  display: block;
  font-weight: 600;
`

const InlineLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin-right: 1em;
  }
`

export const HelpfulLinks = ({ title, links }) => (
  <Fragment>
    {title && (
      <Type preset="label" bottom={{ xs: 0.5, md: 1 }}>
        {title}
      </Type>
    )}
    <InlineLinks>
      {links.map(link => (
        <StyledLink key={link.fields.url} to={link.fields.url}>
          {link.fields.label}
        </StyledLink>
      ))}
    </InlineLinks>
  </Fragment>
)
