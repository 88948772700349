import { gql } from '@apollo/client'

import { HOTEL_ROOM_CART_ITEM_FRAGMENT } from './fragments/cart'

export const ADD_ITEM_TO_CART = gql`
  ${HOTEL_ROOM_CART_ITEM_FRAGMENT}
  mutation AddHotelRoomToCart($input: AddHotelRoomToCartInput!) {
    addHotelRoomToCart(input: $input) {
      ...HotelRoomCartItem
    }
  }
`
