import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

type UseMailListSignupOptions = {
  mailchimpListId?: string
  klaviyoListId: string
}

type SignupData = {
  email: string
  firstName?: string
  lastName?: string
  $consent: string[] | boolean
}

export type NewsletterSignup = (
  data: SignupData,
  callback?: any
) => Promise<void>

export const useMailListSignup = ({
  mailchimpListId = undefined,
  klaviyoListId,
}: UseMailListSignupOptions) => {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const { i18n } = useTranslation()

  const signUp = async (data: SignupData, callback = undefined) => {
    // don't submit twice
    if (success) {
      return
    }

    setError('')
    setSuccess(false)
    setLoading(true)

    try {
      await axios({
        method: 'post',
        url: '/api/newsletter-signup',
        data: {
          ...data,
          mailchimpListId,
          klaviyoListId,
          locale: i18n.language,
        },
      })

      if (callback) {
        callback()
      }

      setLoading(false)
      setSuccess(true)
    } catch (e) {
      setError(e.message)
      setLoading(false)
    }
  }

  return { isLoading, error, success, signUp }
}
