import {
  captureException,
  captureMessage,
  init,
  withScope,
} from '@sentry/nextjs'
import type { Extras, Primitive, SeverityLevel } from '@sentry/types'

let isSentryInitialized = false
let internalEnvironment = 'development'
let scopeCorrelationId: string | undefined = undefined

const ignorePatterns = [
  {
    message: "reading 'openDialog'",
    pathRegex: /e9690605/,
  },
] as const

export const initializeSentry = ({
  dsn,
  tracesSampleRate,
  environment,
  maxValueLength = 10000,
  correlationId,
}: {
  dsn: string
  tracesSampleRate: number
  environment: 'development' | 'production'
  maxValueLength?: number
  correlationId?: string
}) => {
  internalEnvironment = environment
  if (dsn) {
    init({
      dsn,
      tracesSampleRate: tracesSampleRate ?? 0,
      maxValueLength,
      environment,
      ignoreErrors: [
        'NotAllowedError',
        'AbortError',
        'ResizeObserver loop',
        'SecurityError',
        'InitializationError',
        'Non-Error promise rejection captured',
        'preloadedListeners',
        'elementFromPoint',
        /^Failed to fetch$/,
      ],
      beforeSend(event, hint) {
        if (hint.originalException instanceof Error) {
          const errorMessage = hint.originalException.message || ''
          const stackTrace = hint.originalException.stack || ''

          const isIgnoredError = ignorePatterns.some(
            ({ message, pathRegex }) => {
              return (
                errorMessage.includes(message) || stackTrace.match(pathRegex)
              )
            }
          )

          if (isIgnoredError) {
            return null
          }
        }
        return event
      },
    })
    isSentryInitialized = true
    if (correlationId) {
      scopeCorrelationId = correlationId
    }
    return isSentryInitialized
  }
  console.warn('No SENTRY_DSN found. Sentry will not be initialized.')
  return isSentryInitialized
}

type SentryLogOptions =
  | {
      error: Error
      message?: never
      severity?: SeverityLevel
      extras?: Extras
      tags?: { [key: string]: Primitive }
    }
  | {
      message: string
      error?: never
      severity?: SeverityLevel
      extras?: Extras
      tags?: { [key: string]: Primitive }
    }

export const sentryLogging = ({
  error,
  message,
  severity,
  extras = {},
  tags = {},
}: SentryLogOptions) => {
  // Log to console if in development environment (preview, development or localhost)
  if (internalEnvironment === 'development') {
    if (error) {
      console.error(error, extras)
    } else if (message) {
      console.log(message, extras)
    }
  }

  // Don't try to log to Sentry if it's not initialized by parent app.
  if (!isSentryInitialized) {
    return
  }

  withScope(scope => {
    scope.setExtras(extras)
    scope.setTags({
      correlationId: scopeCorrelationId,
      userDate: new Date().toISOString(),
      ...tags,
    })
    if (error) {
      scope.setLevel(severity || 'error')
      // Check if the error is an instance of Error
      if (error instanceof Error) {
        captureException(error)
      } else {
        // Capture non-Error objects as messages with additional context
        captureMessage(
          `Non-Error exception captured: ${JSON.stringify(error)}`,
          severity || 'error'
        )
      }
    } else if (message) {
      scope.setLevel(severity || 'info')
      captureMessage(message)
    }
  })
}
