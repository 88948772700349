import { useInitPosthog } from 'bl-posthog/src/useInitPosthog'
import { localStorage } from 'bl-utils/src/storage/localStorage'

const getQueryParam = (asPath: string, paramName: string) => {
  if (typeof window === 'undefined') {
    return
  }
  const url = new URL(asPath, window.location.href)
  const paramValue = url.searchParams.get(paramName)

  return paramValue
}

const withPosthog = PosthogWrapper => {
  const WithPosthog = props => {
    const paramKey = 'posthog_persistence'
    const posthogPersistence = getQueryParam(props.router?.asPath, paramKey)

    if (posthogPersistence !== null) {
      localStorage.setItem(paramKey, posthogPersistence)
    }
    useInitPosthog({
      correlationId: props.correlationId,
      persistence: posthogPersistence || localStorage.getItem(paramKey),
    })

    return <PosthogWrapper {...props} />
  }
  WithPosthog.getInitialProps = async ctx => {
    return PosthogWrapper.getInitialProps
      ? await PosthogWrapper.getInitialProps(ctx)
      : {}
  }

  return WithPosthog
}

export default withPosthog
