export const PersonIcon = props => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.34309 8.27062C9.11956 7.2118 9.61368 5.80003 9.61368 4.67062C9.61368 2.62356 7.91956 0.929443 5.8725 0.929443C3.82545 0.929443 2.13133 2.69415 2.13133 4.74121C2.13133 5.87062 2.62545 7.35297 3.4725 8.4118C2.55486 8.90591 0.296034 10.4589 0.013681 13.9177C-0.0569072 14.4118 0.154858 14.9059 0.43721 15.2589C0.719563 15.6118 1.14309 15.753 1.56662 15.753H10.4607C10.8843 15.753 11.3078 15.6118 11.5901 15.2589C11.9431 14.9059 12.0843 14.4118 12.0137 13.9177C11.7313 10.1059 8.9078 8.55297 8.34309 8.27062ZM5.8725 2.4118C7.14309 2.4118 8.20192 3.47062 8.20192 4.74121C8.20192 6.22356 7.00192 8.48238 5.8725 8.48238C4.74309 8.48238 3.54309 6.22356 3.54309 4.74121C3.54309 3.47062 4.60192 2.4118 5.8725 2.4118ZM10.5313 14.3412L10.4607 14.4118H1.56662C1.56662 14.4118 1.49603 14.4118 1.49603 14.3412C1.42545 14.2706 1.42545 14.2 1.42545 14.0589C1.7078 10.6706 4.24897 9.6118 4.39015 9.54121C4.46074 9.54121 4.53133 9.47062 4.53133 9.40003C4.95486 9.68238 5.37839 9.82356 5.8725 9.82356C6.43721 9.82356 6.93133 9.6118 7.42545 9.25885C7.49603 9.40003 7.56662 9.47062 7.7078 9.54121C7.7078 9.54121 10.3902 10.6706 10.6725 14.0589C10.6019 14.2 10.6019 14.2706 10.5313 14.3412Z"
      fill="currentColor"
    />
  </svg>
)
