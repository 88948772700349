import { sentryLogging } from './sentryUtils'

/* We need to be able to display linebreaks with \n here, but for some reason \n that come from contentful localization don't register
  as linebreaks, but just write '\n' in the text instead. This replacement fixes that
*/
export const replaceLineBreaks = (text: string) => {
  try {
    return text.replaceAll('\\n', '\n')
  } catch (error) {
    sentryLogging({
      message: 'Error in replaceLineBreaks',
      extras: { text, error },
    })
    return text
  }
}
