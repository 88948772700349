import { Children, cloneElement } from 'react'
import { useInView } from 'react-intersection-observer'

import { isEdge } from 'bl-utils/src/isEdge'

import { durations } from '../constants/durations'
// import {
//   elementY,
//   intervalEvent,
//   observe,
//   resizeEvent,
//   viewportY,
// } from '../react-observe/lib/api'
// import { Observer } from '../react-observe/Observer'
import { Stagger } from './Stagger/Stagger'

// const isVisible = (wasVisible, elementY, viewportEnter, viewportExit) =>
//   wasVisible ? elementY < viewportExit : elementY < viewportEnter

// const includeLast = fn => {
//   let lastValue = undefined
//   return (...args) => (lastValue = fn(lastValue, ...args))
// }

// const isVisibleObserver = observe(
//   elementY(0, undefined, [resizeEvent(), intervalEvent(2000)]),
//   viewportY(0.85),
//   viewportY(1.1),
//   includeLast(isVisible)
// )

// export const IsVisible = ({ children }) => (
//   <Observer value={isVisibleObserver}>{children}</Observer>
// )

export const Appear = ({
  observer = false,
  children,
  in: isIn = true,
  delay = 0,
  styleChild = false,
}) => {
  const { ref, inView } = useInView({
    // triggerOnce: true,
    // threshold: 0.1, // Not sure if we need it
  })

  const renderStaggerChildren = ({ value, delay }) => {
    const style = {
      transition: `transform ${durations.long}ms ${delay}ms, opacity ${durations.long}ms ${delay}ms`,
      transform: undefined,
      opacity: undefined,
    }

    if (!value) {
      style.transform = 'translateY(50px)'
      style.opacity = isEdge ? 1 : 0
    }

    const props = {
      className: 'Appear',
      style,
    }
    return styleChild ? (
      cloneElement(Children.only(children), props)
    ) : (
      <div suppressHydrationWarning {...props}>
        {children}
      </div>
    )
  }

  const renderChildren = observer ? children : renderStaggerChildren

  // if (!isClient) {
  //   return <div suppressHydrationWarning>{children}</div>
  // }

  const renderStagger = isVisible => (
    <Stagger delay={delay} in={isIn && isVisible}>
      {renderChildren}
    </Stagger>
  )

  return !observer ? (
    renderStagger(true)
  ) : (
    // <Observer value={isVisibleObserver}>{renderStagger}</Observer>
    <div ref={ref}>{renderStagger(inView)}</div>
  )
}
