import { gql } from '@apollo/client'

export const GET_AVAILABLE_RESERVATION_TIMES = gql`
  query restaurantAvailability($date: String!) {
    restaurantAvailability(date: $date) {
      date
      slots {
        available
        time
      }
    }
  }
`
