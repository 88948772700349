import { createContext } from 'react'

export interface Breadcrumb {
  to: string
  delta: number
  name: string
}

interface BreadcrumbContextState {
  saveBreadcrumb: (breadcrumb?: string) => void
  breadcrumb: Breadcrumb
}

export const BreadcrumbContext = createContext<BreadcrumbContextState>({
  saveBreadcrumb: () => {},
  breadcrumb: null,
})
