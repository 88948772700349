const memoryStorage: { [key: string]: string } = {}

const getItem = (key: string) => {
  try {
    return window.localStorage.getItem(key)
  } catch {
    return memoryStorage[key] || null
  }
}

const setItem = (key: string, value: string) => {
  try {
    window.localStorage.setItem(key, value)
  } catch {
    memoryStorage[key] = value
  }
}

const clear = () => {
  if (typeof window === 'undefined') return
  try {
    window.localStorage.clear()
  } catch (e) {
    console.log('Error clearing local storage', e)
  }
}

// Clear all local storage items except the ones specified in the keys array
const clearExcept = (keys?: string[]) => {
  if (typeof window === 'undefined') return
  if (keys === undefined) clear()
  try {
    Object.keys(window.localStorage)
      .filter(key => !keys?.includes(key))
      .forEach(key => window.localStorage.removeItem(key))
  } catch (e) {
    console.log('Error clearing local storage items', e)
  }
}

export const localStorage = {
  getItem,
  setItem,
  clear,
  clearExcept,
}
