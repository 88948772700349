import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Head from 'next/head'
import { NextRouter, withRouter } from 'next/router'
import { Asset } from 'contentful'

import { IMetadata } from 'bl-common/src/generated/contentful'
import { getImageUrl } from 'bl-common/src/units/SmartImage/getImageUrl'

import { SiteConfigContext } from './SiteConfig'

const allowedDomains = ['pixel.mathtag.com']

interface MetadataBaseInterface {
  router: NextRouter
  metadata?: Partial<IMetadata>
  title?: string
  description?: string
  image?: Asset
  overrideTitle?: string
}

const validScript = (url: string) =>
  allowedDomains.some(domain => url.includes(domain))

const MetadataBase: FC<MetadataBaseInterface> = ({
  metadata,
  title,
  description,
  image,
  overrideTitle,
  router,
}) => {
  const { t } = useTranslation()
  const siteConfig = useContext(SiteConfigContext)
  const defaultMeta = siteConfig?.metadata?.fields || {}
  const fields = metadata ? metadata.fields : {}
  const meta = {
    title: fields.title || title || defaultMeta.title,
    ogTitle:
      fields.ogTitle ||
      fields.title ||
      title ||
      defaultMeta.ogTitle ||
      defaultMeta.title,
    description: fields.description || description || defaultMeta.description,
    ogDescription:
      fields.ogDescription ||
      fields.description ||
      description ||
      defaultMeta.ogDescription ||
      defaultMeta.description,
    imageUrl: getImageUrl(
      fields.image || image || defaultMeta.ogImage || defaultMeta.image,
      {
        width: 1200,
        height: 630,
        exact: true,
        format: null,
        forceProtocol: true,
      }
    ),
  }

  const trackingScripts: string[] = fields.trackingScripts || []

  return (
    <Head>
      <title key="title">
        {overrideTitle
          ? meta.title
          : `${meta.title} | ${t('defaultMetaTitle')}`}
      </title>
      <meta name="description" content={meta.description} />
      {fields.canonicalUrl && (
        <link rel="canonical" href={fields.canonicalUrl} />
      )}
      <meta name="twitter:card" content="summary" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={meta.ogTitle} />
      <meta property="og:url" content={fields.canonicalUrl || router.asPath} />
      {meta.imageUrl && <meta property="og:image" content={meta.imageUrl} />}
      <meta property="og:description" content={meta.ogDescription} />
      <meta property="og:site_name" content="Blue Lagoon Iceland" />
      {trackingScripts.map(
        src => validScript(src) && <script key={src} async src={src} />
      )}
    </Head>
  )
}

export const Metadata = withRouter(MetadataBase)
