import { createGlobalStyle } from 'styled-components'

export const CookieConsentStyles = createGlobalStyle`
  .cc-container a,.cc-container a:link,.cc-container a:visited {
    color: #999;
    transition: all 950ms ease !important
  }

  .cc-dialog-container {
    display: none !important;
    left: auto !important;
    right: 0 !important;
    transition: margin-bottom 950ms ease 1s,opacity 950ms ease 1.1s !important;
    width: auto !important;
  }
  .cc-dialog-container.visible { display: block !important; }

  .cc-settings, .cc-close {
    transition: margin-right 950ms ease !important
  }

  .cc-dialog .cc-message
  {
    clear:both !important;
    float:left !important;
    padding:0px !important;
    width:100% !important;
    position: relative !important;
  }

  @media screen and (max-width: 520px) {
    .cc-dialog .cc-message > p {
      max-height: 106px;
      overflow: scroll;
    }

    .cc-dialog .cc-message::after {
      content: '';
      display: block;
      background: linear-gradient(transparent, white 30%);
      height: 2em;
      width: 100%;
      position: absolute;
      bottom: -1.4em;
    }
  }


  .cc-dialog .cc-message p
  {
    padding-bottom:38px;
    line-height:25px !important;
    font-size:15px !important;
    color:#454647;
  }

  .cc-dialog .cc-message p a
  {
    /*margin-left:5px;
    display:inline !important;*/
    font-weight:500 !important;
    color:#52a5b8 !important;
  }

  .cc-dialog .cc-message p a:hover
  {
    color:#6793AE !important;
  }

  .cc-dialog .cc-actions
  {
    clear:both !important;
    float:left !important;
    padding:0px !important;
    width:100% !important;
  }

  .cc-container.cc-ui-shadow.cc-theme-light .cc-dialog
  {
    float:right;
    margin:0px 50px 0px 0px;
    width:340px;
    background:#fff !important;
    border:1px solid #f2f2f2 !important;
    box-shadow: 0 20px 37px -22px rgba(0,0,0,0.3), 0 22px 80px -20px rgba(0,0,0,0.07) !important;
  }

  .cc-container.cc-ui-shadow.cc-theme-light .cc-dialog::before
  {
    display:block;
    padding:19px 0px;
    line-height:20px;
    font-size:20px;
    font-weight:600;
    color:#454647;
    content:'Blue Lagoon - Cookies';
  }

  .cc-dialog .cc-actions a[role="button"].cc-primary-btn
  {
    padding:25px 20px !important;
    line-height:15px;
    color: #ffffff !important;
    font-size:13px !important;
    background: linear-gradient(-125deg,#6793AE,#50A7BA) !important;

    text-transform: uppercase !important;
    transition:background 400ms !important;
  }

  .cc-dialog .cc-actions a[role="button"].cc-primary-btn:hover
  {
    color:#fff !important;
    background: linear-gradient(125deg,#52a5b8,#52a5b8) !important;
    transition:background 400ms !important;
  }

  .cc-container.cc-theme-light .cc-settings,
  .cc-container.cc-theme-light .cc-settings .cc-about
  {
    background:#ffffff !important;
  }

  .cc-settings a[role="button"].cc-save-btn
  {
    color: #ffffff !important;
    font-size:12px !important;
    background: linear-gradient(-125deg,#6793AE,#50A7BA) !important;
    text-transform: uppercase !important;
  }

  .cc-settings a[role="button"].cc-save-btn:hover
  {
    color:#fff !important;
    background: linear-gradient(0deg,#52a5b8,#52a5b8) !important;
  }

  .cc-icon {
    display: none;
  }

  @media screen and (max-width:520px)
  {
    .cc-container.cc-ui-shadow.cc-theme-light .cc-dialog
    {
      margin:0px !important;
      width:100% !important;
      box-sizing:border-box !important;
    }

    .cc-dialog-container.visible
    {
      margin-bottom:0px !important;
    }
  }

  @media screen and (max-height:420px)
  {
    .cc-container.cc-ui-shadow.cc-theme-light .cc-dialog
    {
      margin:0px !important;
    }

    .cc-dialog-container.visible
    {
      margin-bottom:0px !important;
    }
  }
  `
