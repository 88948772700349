import { sentryLogging } from '../sentryUtils'

export const calcPriceWithFractions = (amount: number, rate: number = 1) => {
  let internalRate = rate
  if (isNaN(amount)) {
    sentryLogging({
      message: 'calcPrice: Amount missing or not a number',
      extras: { rate, amount },
    })
    return null
  }

  if (isNaN(internalRate) || internalRate <= 0) {
    sentryLogging({
      message: 'calcPrice: Rate is not a number or negative',
      extras: { rate },
    })
    internalRate = 1
  }

  if (rate > 1) {
    return amount / internalRate
  }

  return amount * internalRate
}

export const calcPrice = (amount: number, rate: number = 1) => {
  const result = calcPriceWithFractions(amount, rate)

  return result === null ? null : parseFloat(result.toFixed(0))
}
