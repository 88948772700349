import { useCallback, useEffect, useRef } from 'react'

import { useNavigationEvents } from 'bl-common/src/context/useNavigationEvents'
import { usePageState } from 'bl-common/src/context/usePageState'

const getScroll = node => {
  if (node === window) {
    return { left: node.scrollX, top: node.scrollY }
  } else {
    return { left: node.scrollLeft, top: node.scrollTop }
  }
}

const useScrollRestore = (id = 'window', defaultScroll) => {
  const { getState, setState } = usePageState()
  const node = useRef(null)
  const key = `scroll:${id}`
  const navigating = useRef(false)

  const onScroll = useCallback(
    event => {
      if (navigating.current) {
        return
      }
      setState(key, getScroll(event.currentTarget))
    },
    [key]
  )

  const ref = useCallback(newNode => {
    if (node.current && newNode == null) {
      node.current.removeEventListener('scroll', onScroll)
    }
    node.current = newNode
    if (node.current) {
      node.current.addEventListener('scroll', onScroll)
    }
  }, [])

  useEffect(() => {
    history.scrollRestoration = 'manual'

    if (id === 'window') {
      ref(window)
    }

    if (node.current) {
      const scroll = getState(key)
      const defScroll =
        typeof defaultScroll === 'function' ? defaultScroll() : defaultScroll
      if (id && scroll) {
        node.current.scrollTo(scroll.left, scroll.top)
      } else if (defScroll) {
        node.current.scrollTo(defScroll[0], defScroll[1])
      } else if (defScroll !== false) {
        node.current.scrollTo(0, 0)
      }
    }

    const anchorRegex = /^#[A-Za-z]+[\w\-:.]*$/
    // If there is a anchor present in the url, check if we have an element with a corresponding id attribute and scroll to it
    if (
      id === 'window' &&
      window.location.hash &&
      anchorRegex.test(window.location.hash)
    ) {
      const anchorNode = document.querySelector(window.location.hash)
      if (anchorNode) {
        anchorNode.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }

    return () => {
      if (id === 'window') {
        ref(null)
      }
    }
  }, [])

  useNavigationEvents(event => {
    if (event === 'routeChangeStart') {
      navigating.current = true
    }
  }, [])

  return ref
}

export const ScrollRestore = ({ id = 'window', defaultScroll }) => {
  useScrollRestore(id, defaultScroll)

  return null
}
