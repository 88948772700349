/* eslint-disable @typescript-eslint/no-misused-promises */
import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observe, useObserver, viewportWidth } from 'react-ui-observer'
import { addDays } from 'date-fns/addDays'
import { getDay } from 'date-fns/getDay'
import { getHours } from 'date-fns/getHours'
import { getMonth } from 'date-fns/getMonth'
import { isToday } from 'date-fns/isToday'
import { isWeekend } from 'date-fns/isWeekend'
import { parse } from 'date-fns/parse'
import * as formik from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import isEmpty from 'lodash/isEmpty'

import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import { breakpoints } from '../../constants/breakpoints'
import { colors } from '../../constants/colors'
import { Amount } from '../../elements/Amount'
import { Button } from '../../elements/Button/Button'
import Copy from '../../elements/Copy'
import { Caret } from '../../elements/Icons/Caret'
import { CloseIcon } from '../../elements/Icons/CloseIcon'
import { Type } from '../../elements/Typography/Typography'
import { ButtonInput } from '../../form/ButtonInput'
import { Checkbox as C } from '../../form/Checkbox/Checkbox'
import { CardNumberInput, ExpirationDateInput } from '../../form/CreditCard'
import { Datalist } from '../../form/Datalist'
import { Datepicker } from '../../form/Datepicker/Datepicker'
import { Input } from '../../form/Input/Input'
import { NationalIdInput } from '../../form/NationalIdInput'
import { PhoneInput } from '../../form/PhoneInput'
import { Radio } from '../../form/Radio/Radio'
import { SimpleRadio } from '../../form/Radio/SimpleRadio'
import { Select } from '../../form/Select/Select'
import { TextArea } from '../../form/TextArea/TextArea'
import { Collapse } from '../../units/Collapse/Collapse'
import availableCountries from './countries.json'
import * as styles from './styles'

const { getIn } = formik

const COUNTRIES = availableCountries.map(country => country.label)
const INITIAL = '__initial'

const WInput = ({ field, hasTriedSubmitting, errors, touched, isHidden }) => {
  const subtleError = !!(
    (hasTriedSubmitting || touched[field.key]) &&
    getIn(errors, field.key)
  )
  return (
    <styles.FieldWrapper
      isHidden={isHidden}
      id="form-field"
      hasError={subtleError}
    >
      <styles.TopWrapper>
        {field.name && (
          <styles.Label htmlFor={field.key} isRequired={field.isRequired}>
            {field.name}
            {field.isRequired ? '*' : ''}
          </styles.Label>
        )}
        {subtleError && (
          <styles.ErrorMessage>{getIn(errors, field.key)}</styles.ErrorMessage>
        )}
      </styles.TopWrapper>
      <formik.Field name={field.key}>
        {({ field: fieldProps }) => (
          <Input
            id={fieldProps.name}
            label={field.name}
            placeholder={field.placeholder}
            isRequired={field.isRequired}
            type={field.type}
            readOnly={field.readOnly}
            min={field.min}
            {...fieldProps}
            hasError={subtleError}
            autoComplete={field.autoComplete}
          />
        )}
      </formik.Field>
    </styles.FieldWrapper>
  )
}

const Derived = ({
  field,
  hasTriedSubmitting,
  errors,
  touched,
  isHidden,
  values,
  marginBottom,
}) => {
  const subtleError = !!(
    (hasTriedSubmitting || touched[field.key]) &&
    getIn(errors, field.key)
  )
  return (
    <styles.FieldWrapper
      isHidden={isHidden}
      marginBottom={marginBottom}
      id="form-field"
      hasError={subtleError}
    >
      <styles.TopWrapper>
        {field.name && (
          <styles.Label htmlFor={field.key}>{field.name}</styles.Label>
        )}
        {subtleError && (
          <styles.ErrorMessage>{getIn(errors, field.key)}</styles.ErrorMessage>
        )}
      </styles.TopWrapper>
      <Input
        id={field.key}
        name={field.name}
        label={field.name}
        placeholder={field.placeholder}
        value={field.getValue(values)}
        readOnly
        hasError={subtleError}
      />
    </styles.FieldWrapper>
  )
}

const File = ({ field, hasTriedSubmitting, errors, isHidden }) => (
  <styles.FieldWrapper isHidden={isHidden} id="form-field">
    <styles.TopWrapper>
      {field.name && (
        <styles.Label htmlFor={field.key} isRequired={field.isRequired}>
          {field.name}
          {field.isRequired ? '*' : ''}
        </styles.Label>
      )}
      {getIn(errors, field.key) && hasTriedSubmitting && (
        <styles.ErrorMessage>{getIn(errors, field.key)}</styles.ErrorMessage>
      )}
    </styles.TopWrapper>
    <formik.Field name={field.key}>
      {({ field: fieldProps, form }) => (
        <Input
          {...fieldProps}
          id={field.key}
          type="file"
          multiple={field.multiple}
          value={undefined}
          onChange={e =>
            form.setFieldValue(field.key, Array.from(e.target.files))
          }
        />
      )}
    </formik.Field>
  </styles.FieldWrapper>
)

const Name = ({
  field,
  hasTriedSubmitting,
  errors = {},
  touched = {},
  isHidden,
}) => {
  const { t } = useTranslation()
  const subtleError = !!(
    (hasTriedSubmitting || touched[field.key]) &&
    getIn(errors, field.key)
  )

  const errorMessage =
    subtleError &&
    (getIn(errors, field.key).first || getIn(errors, field.key).last)
  const hasFirstNameError = !!(subtleError && getIn(errors, field.key).first)
  const hasLastNameError = !!(subtleError && getIn(errors, field.key).last)
  return (
    <styles.FieldWrapper
      isHidden={isHidden}
      id="form-field"
      hasError={subtleError}
    >
      <styles.TopWrapper>
        {field.name && (
          <styles.Label htmlFor={field.key} isRequired={field.isRequired}>
            {field.name}
            {field.isRequired ? '*' : ''}
          </styles.Label>
        )}
        {subtleError && (
          <styles.ErrorMessage>{errorMessage}</styles.ErrorMessage>
        )}
      </styles.TopWrapper>
      <styles.NestedField>
        <formik.Field name={`${field.key}.first`}>
          {({ field: fieldProps }) => (
            <Input
              {...fieldProps}
              placeholder={t('formFirstNamePlaceholder', 'First name')}
              label="First name"
              isRequired={field.isRequired}
              id="firstName"
              hasError={hasFirstNameError}
              readOnly={field.readOnly}
            />
          )}
        </formik.Field>
        <formik.Field name={`${field.key}.last`}>
          {({ field: fieldProps }) => (
            <Input
              {...fieldProps}
              placeholder={t('formLastNamePlaceholder', 'Last name')}
              label="Last name"
              isRequired={field.isRequired}
              id="lastName"
              hasError={hasLastNameError}
              readOnly={field.readOnly}
            />
          )}
        </formik.Field>
      </styles.NestedField>
    </styles.FieldWrapper>
  )
}

const DataList = ({ name, list, subtleError = undefined, ...rest }) => (
  <formik.Field name={name}>
    {({ field: fieldProps }) => (
      <Datalist
        list={list}
        {...fieldProps}
        {...rest}
        hasError={!!subtleError}
      />
    )}
  </formik.Field>
)

const Address = ({ field, hasTriedSubmitting, errors, touched, isHidden }) => {
  const subtleError = !!(
    (hasTriedSubmitting || touched[field.key]) &&
    getIn(errors, field.key)
  )
  return (
    <styles.FieldWrapper
      isHidden={isHidden}
      id="form-field"
      hasError={subtleError}
    >
      <styles.TopWrapper>
        {field.name && (
          <styles.Label htmlFor={field.key} isRequired={field.isRequired}>
            {field.name}
            {field.isRequired ? '*' : ''}
          </styles.Label>
        )}
        {subtleError && (
          <styles.ErrorMessage>{getIn(errors, field.key)}</styles.ErrorMessage>
        )}
      </styles.TopWrapper>
      <styles.NestedField bottom={{ xs: 1, md: 1 }}>
        <formik.Field name={`${field.key}.address`}>
          {({ field: fieldProps }) => (
            <Input
              placeholder="Street address"
              {...fieldProps}
              id="streetAddress"
              hasError={subtleError}
            />
          )}
        </formik.Field>
      </styles.NestedField>
      <styles.NestedField bottom={{ xs: 1, md: 1 }}>
        <formik.Field name={`${field.key}.city`}>
          {({ field: fieldProps }) => (
            <Input
              placeholder="City"
              {...fieldProps}
              id="city"
              hasError={subtleError}
            />
          )}
        </formik.Field>
        <formik.Field name={`${field.key}.state`}>
          {({ field: fieldProps }) => (
            <Input
              placeholder="Region"
              {...fieldProps}
              id="region"
              hasError={subtleError}
            />
          )}
        </formik.Field>
      </styles.NestedField>

      <styles.NestedField>
        <formik.Field name={`${field.key}.zip`}>
          {({ field: fieldProps }) => (
            <Input
              placeholder="Postal / Zip Code"
              {...fieldProps}
              id="zip"
              hasError={subtleError}
            />
          )}
        </formik.Field>
        <formik.Field name={`${field.key}.country`}>
          {() => (
            <DataList
              id="countryList"
              name={`${field.key}.country`}
              placeholder={field.countryPlaceholder || 'Country'}
              list={COUNTRIES}
              subtleError={subtleError}
            />
          )}
        </formik.Field>
      </styles.NestedField>
    </styles.FieldWrapper>
  )
}

const WSelect = ({
  field,
  hasTriedSubmitting,
  errors,
  touched,
  isHidden,
  marginBottom,
}) => {
  const subtleError = !!(
    (hasTriedSubmitting || touched[field.key]) &&
    getIn(errors, field.key)
  )
  return (
    <styles.FieldWrapper
      isHidden={isHidden}
      marginBottom={marginBottom}
      id="form-field"
      hasError={subtleError}
    >
      <styles.TopWrapper>
        {field.name && (
          <styles.Label htmlFor={field.key} isRequired={field.isRequired}>
            {field.name}
            {field.isRequired ? '*' : ''}
          </styles.Label>
        )}
        {subtleError && (
          <styles.ErrorMessage>{getIn(errors, field.key)}</styles.ErrorMessage>
        )}
      </styles.TopWrapper>
      <formik.Field name={field.key}>
        {({ field: fieldProps, form }) => (
          <Select
            id={fieldProps.name}
            name={fieldProps.name}
            value={fieldProps.value || INITIAL}
            hasError={subtleError}
            autoComplete={field.autoComplete}
            onChange={e =>
              e.target.value === INITIAL
                ? form.setFieldValue(field.key, undefined)
                : fieldProps.onChange(e)
            }
          >
            <option value={INITIAL} disabled={field.isRequired}>
              {field.placeholder || field.name}
            </option>
            {field.options.map(option => (
              <option key={option.value || option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
      </formik.Field>
    </styles.FieldWrapper>
  )
}

const WTextArea = ({
  field,
  hasTriedSubmitting,
  errors,
  touched,
  isHidden,
}) => {
  const subtleError = !!(
    (hasTriedSubmitting || touched[field.key]) &&
    getIn(errors, field.key)
  )
  return (
    <styles.FieldWrapper
      isHidden={isHidden}
      id="form-field"
      hasError={subtleError}
    >
      <styles.TopWrapper>
        {field.name && (
          <styles.Label htmlFor={field.key} isRequired={field.isRequired}>
            {field.name}
            {field.isRequired ? '*' : ''}
          </styles.Label>
        )}
        {subtleError && (
          <styles.ErrorMessage>{getIn(errors, field.key)}</styles.ErrorMessage>
        )}
      </styles.TopWrapper>
      <formik.Field name={field.key}>
        {({ field: fieldProps }) => (
          <TextArea
            id={fieldProps.name}
            name={fieldProps.name}
            onChange={fieldProps.onChange}
            placeholder={field.placeholder}
            value={fieldProps.value}
            rows={field.rows || 12}
            hasError={subtleError}
            isRequired={field.isRequired}
            maxLength={field.maxLength}
          />
        )}
      </formik.Field>
    </styles.FieldWrapper>
  )
}

const WRadio = ({ field, hasTriedSubmitting, errors, isHidden }) => {
  const hasError = !!getIn(errors, field.key)
  const [checked, setChecked] = useState('')
  return (
    <styles.FieldWrapper isHidden={isHidden} id="form-field">
      <styles.TopWrapper>
        {field.name && (
          <styles.Label htmlFor={field.key} isRequired={field.isRequired}>
            {field.name}
            {field.isRequired ? '*' : ''}
          </styles.Label>
        )}
        {getIn(errors, field.key) && hasTriedSubmitting && (
          <styles.ErrorMessage>{getIn(errors, field.key)}</styles.ErrorMessage>
        )}
      </styles.TopWrapper>
      <styles.RadioWrapper>
        <formik.Field name={field.key}>
          {({ field: fieldProps, form }) =>
            field.options &&
            field.options.map(option => {
              return (
                <Radio
                  key={option.value}
                  label={option.label}
                  id={option.label}
                  name={fieldProps.name}
                  hasError={hasError}
                  value={option.value}
                  checked={checked === option.value}
                  onChange={() => {
                    setChecked(option.value)
                    form.setFieldValue(fieldProps.name, option.value, false)
                  }}
                  {...option}
                />
              )
            })
          }
        </formik.Field>
      </styles.RadioWrapper>
    </styles.FieldWrapper>
  )
}

const WSimpleRadio = ({ field, hasTriedSubmitting, errors, isHidden }) => {
  const [checked, setChecked] = useState('')
  return (
    <styles.FieldWrapper isHidden={isHidden} id="form-field">
      <styles.TopWrapper>
        {field.name && (
          <styles.Label htmlFor={field.key} isRequired={field.isRequired}>
            {field.name}
            {field.isRequired ? '*' : ''}
          </styles.Label>
        )}
        {getIn(errors, field.key) && hasTriedSubmitting && (
          <styles.ErrorMessage>{getIn(errors, field.key)}</styles.ErrorMessage>
        )}
      </styles.TopWrapper>
      <formik.Field name={field.key}>
        {({ field: fieldProps, form }) =>
          field.options &&
          field.options.map(option => (
            <SimpleRadio
              key={option.value}
              label={
                <Type preset={option.preset || 'textLarge'}>
                  {option.label}
                </Type>
              }
              id={option.label}
              name={fieldProps.name}
              value={option.value}
              checked={checked === option.value}
              onChange={() => {
                setChecked(option.value)
                form.setFieldValue(fieldProps.name, option.value)
              }}
              {...option}
            />
          ))
        }
      </formik.Field>
    </styles.FieldWrapper>
  )
}

const Checkbox = ({
  field,
  hasTriedSubmitting,
  errors,
  touched,
  isHidden = false,
}) => {
  const subtleError = !!(
    (hasTriedSubmitting || touched[field.key]) &&
    getIn(errors, field.key)
  )
  return (
    <styles.FieldWrapper isHidden={isHidden} id="form-field">
      <styles.TopWrapper>
        {field.name && (
          <styles.Label
            htmlFor={field.key}
            isRequired={field.isRequired}
            data-cy={`${field.key}-label`}
          >
            {field.name}
            {field.isRequired ? '*' : ''}
          </styles.Label>
        )}
        {subtleError && (
          <styles.ErrorMessage
            data-cy={`${field.key}-error`}
            style={{ textAlign: 'left' }}
          >
            {getIn(errors, field.key)}
          </styles.ErrorMessage>
        )}
      </styles.TopWrapper>
      <formik.Field name={`${field.key}`}>
        {({ field: fieldProps }) => {
          return (
            <styles.Inline>
              <C
                {...fieldProps}
                name={fieldProps.name}
                id={field.key}
                checked={fieldProps.value}
                error={subtleError}
              >
                {field.label && (
                  <Copy as="div" size={{ xs: 15, md: 16 }}>
                    <span dangerouslySetInnerHTML={{ __html: field.label }} />
                  </Copy>
                )}
              </C>
            </styles.Inline>
          )
        }}
      </formik.Field>
    </styles.FieldWrapper>
  )
}

const Reservation = ({
  field,
  hasTriedSubmitting,
  errors,
  touched,
  isHidden,
}) => {
  const subtleErrors =
    (hasTriedSubmitting || touched[field.key]) && getIn(errors, field.key)

  const [showCalendar, setShowCalendar] = useState(false)
  const [date, setDate] = useState('')

  const parsedDate = new Date(parse(date, 'dd-MM-yyyy', new Date()))

  const isDateWeekend = date && isWeekend(parsedDate)

  const isDateToday = date && isToday(parsedDate)

  const dayOfTheWeek = date && getDay(parsedDate)

  const minDate = field.minDate
    ? new Date(parse(field.minDate, 'dd-MM-yyyy', new Date()))
    : field.noSameDayReservations
      ? addDays(new Date(), 1)
      : new Date()
  const maxDate = field.maxDate
    ? new Date(parse(field.maxDate, 'dd-MM-yyyy', new Date()))
    : undefined

  const options =
    field.monthOptions && field.monthOptions[getMonth(parsedDate)]
      ? field.monthOptions[getMonth(parsedDate)]
      : field

  let timeOptions = []
  if (options.dayOverrides && options.dayOverrides[date]) {
    timeOptions = options.dayOverrides[date]
  } else if (
    options.weekdayOverrides &&
    options.weekdayOverrides[dayOfTheWeek]
  ) {
    timeOptions = options.weekdayOverrides[dayOfTheWeek]
  } else if (options.closedWeekdays === true && !isDateWeekend) {
    timeOptions = []
  } else if (options.closedWeekends === true && isDateWeekend) {
    timeOptions = []
  } else if (isDateWeekend && options.weekendTimeOptions) {
    timeOptions = options.weekendTimeOptions
  } else {
    timeOptions = options.timeOptions || []
  }

  const excludedTimes = field.excludedTimes
    ? field.excludedTimes[date] || []
    : []

  const todayMinHours = field.todayMinHours ? field.todayMinHours : 1

  const maxKids = field.maxKids || 2
  const minKids = typeof field.minKids === 'number' ? field.minKids : 0

  const kidsOptions = maxKids - minKids + 1

  const checkIfTimeIsAllowed = time => {
    if (isDateToday) {
      const currentHour = getHours(new Date())

      // Change an hour ("11:11") into int (11)
      const getHourIntFromStr = hourStr => {
        const hr = hourStr.split(':')[0]
        return parseInt(hr)
      }

      // If the time is less than X hours from now, it's filtered out.
      if (getHourIntFromStr(time.label) <= currentHour + todayMinHours) {
        return false
      }
    }

    return true
  }

  const isDateDisabled = ({ date }) => {
    const formattedDate = formatDateInUTC(date, 'dd-MM-yyyy')

    // the options could either come from a month config or the form config
    const options =
      field.monthOptions && field.monthOptions[getMonth(date)]
        ? field.monthOptions[getMonth(date)]
        : field

    // day overrides trumps all other options
    if (options.dayOverrides && options.dayOverrides[formattedDate]) {
      return false
      // option to disable certain weekdays
    }

    if (options.disabledWeekdays) {
      return options.disabledWeekdays.some(curr => curr === date.getDay())
    }

    if (options.closedWeekdays && !isWeekend(date)) {
      return true
    }

    if (options.closedWeekends && isWeekend(date)) {
      return true
    }
  }

  return (
    <styles.FieldWrapper isHidden={isHidden} id="form-field">
      <styles.TopWrapper>
        {field.name && (
          <styles.Label htmlFor={field.key} isRequired={field.isRequired}>
            {field.name}
            {field.isRequired ? '*' : ''}
          </styles.Label>
        )}
        {subtleErrors && subtleErrors.time && (
          <styles.ErrorMessage>{subtleErrors.time}</styles.ErrorMessage>
        )}
      </styles.TopWrapper>
      <styles.CalendarWrapper data-portal="calendar">
        <styles.NestedField bottom={{ xs: 1, md: 1 }}>
          <formik.Field name={`${field.key}.date`}>
            {({ field: fieldProps, form }) => (
              <Datepicker
                onFocus={() => setShowCalendar(true)}
                id={fieldProps.name}
                name={fieldProps.name}
                minDate={minDate}
                maxDate={maxDate}
                placeholder={field.dateLabel || 'Pick a date'}
                showCalendar={showCalendar}
                value={fieldProps.value}
                hasError={!!(subtleErrors && subtleErrors.time)}
                onChange={value => {
                  form.setFieldValue(fieldProps.name, value, false)
                  form.setFieldValue('reservation.time', undefined) // clear selected time
                  setDate(value)
                  setShowCalendar(false)
                }}
                handleDismiss={() => setShowCalendar(false)}
                isDateDisabled={isDateDisabled}
              />
            )}
          </formik.Field>
          {!isEmpty(timeOptions) && (
            <formik.Field name={`${field.key}.time`}>
              {({ field: fieldProps, form }) => (
                <Select
                  id={fieldProps.name}
                  name={fieldProps.name}
                  value={fieldProps.value || INITIAL}
                  hasError={!!(subtleErrors && subtleErrors.time)}
                  onChange={e =>
                    e.target.value === INITIAL
                      ? form.setFieldValue(field.key, undefined)
                      : fieldProps.onChange(e)
                  }
                >
                  <option value={INITIAL} disabled={field.isRequired}>
                    {field.timeLabel || 'Select time'}
                  </option>
                  {timeOptions &&
                    timeOptions.filter(checkIfTimeIsAllowed).map(option => {
                      const isDisabled = excludedTimes.includes(option.value)
                      return (
                        <option
                          key={option.value || option.label}
                          value={option.value}
                          disabled={isDisabled}
                        >
                          {option.label} {isDisabled ? field.disabledLabel : ''}
                        </option>
                      )
                    })}
                </Select>
              )}
            </formik.Field>
          )}
        </styles.NestedField>
      </styles.CalendarWrapper>
      {!field.hideChildPicker && (
        <styles.NestedField>
          <formik.Field name={`${field.key}.kids`}>
            {({ field: fieldProps, form }) => (
              <styles.NestedFieldInner>
                <styles.TopWrapper>
                  {fieldProps.name && (
                    <styles.Label
                      htmlFor={fieldProps.name}
                      isRequired={field.isRequired}
                    >
                      {field.kidsLabel || 'Number of children'}
                      {field.isRequired ? '*' : ''}
                    </styles.Label>
                  )}
                  {subtleErrors && subtleErrors.kids && (
                    <styles.ErrorMessage>
                      {subtleErrors.kids}
                    </styles.ErrorMessage>
                  )}
                </styles.TopWrapper>
                <Select
                  id={fieldProps.name}
                  name={fieldProps.name}
                  value={fieldProps.value || INITIAL}
                  hasError={!!(subtleErrors && subtleErrors.kids)}
                  onChange={e =>
                    e.target.value === INITIAL
                      ? form.setFieldValue(field.key, undefined)
                      : fieldProps.onChange(e)
                  }
                >
                  <option value={INITIAL} disabled={field.isRequired}>
                    {field.kidsLabel || 'Number of children'}
                  </option>
                  {Array.from({ length: kidsOptions }, (_, i) => {
                    const num = i + minKids
                    return (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    )
                  })}
                </Select>
              </styles.NestedFieldInner>
            )}
          </formik.Field>
        </styles.NestedField>
      )}
    </styles.FieldWrapper>
  )
}

const Country = ({ field, hasTriedSubmitting, errors, touched, isHidden }) => {
  const subtleError = !!(
    (hasTriedSubmitting || touched[field.key]) &&
    getIn(errors, field.key)
  )
  return (
    <styles.FieldWrapper isHidden={isHidden} id="form-field">
      <styles.TopWrapper>
        {field.name && (
          <styles.Label htmlFor={field.key} isRequired={field.isRequired}>
            {field.name}
            {field.isRequired ? '*' : ''}
          </styles.Label>
        )}
        {subtleError && (
          <styles.ErrorMessage>{getIn(errors, field.key)}</styles.ErrorMessage>
        )}
      </styles.TopWrapper>
      <formik.Field name={field.key}>
        {() => (
          <DataList
            id="countryList"
            name={`${field.key}`}
            placeholder={field.countryPlaceholder || 'Country'}
            list={COUNTRIES}
            subtleError={subtleError}
          />
        )}
      </formik.Field>
    </styles.FieldWrapper>
  )
}

const Card = ({ field, hasTriedSubmitting, errors, touched, isHidden }) => {
  const { t } = useTranslation()

  const keyPrefix = field.keyPrefix ? `${field.keyPrefix}.` : ''
  const numberKey = `${keyPrefix}number`
  const expiryKey = `${keyPrefix}expiryDate`
  const cvcKey = `${keyPrefix}cvc`

  const showErrors = {
    number:
      (hasTriedSubmitting || getIn(touched, numberKey)) &&
      getIn(errors, numberKey),
    expiryDate:
      (hasTriedSubmitting || getIn(touched, expiryKey)) &&
      getIn(errors, expiryKey),
    cvc:
      (hasTriedSubmitting || getIn(touched, cvcKey)) && getIn(errors, cvcKey),
  }

  const cardNumberLabel = t('formCardNumberLabel', 'Card number')
  const expiryLabel = t('formCardExpirationDateLabel', 'Expiration date')
  const cvcLabel = t('formCardCVCLabel', 'CVC')

  return (
    <styles.FieldWrapper
      isHidden={isHidden}
      id="form-field"
      hasError={
        !!showErrors.number || !!showErrors.expiryDate || showErrors.cvc
      }
    >
      <styles.TopWrapper>
        <styles.Label htmlFor={numberKey} isRequired={field.isRequired}>
          {cardNumberLabel}*
        </styles.Label>
        <styles.ErrorMessage>
          {showErrors.number && getIn(errors, numberKey)}
        </styles.ErrorMessage>
      </styles.TopWrapper>
      <formik.Field name={numberKey}>
        {({ field: fieldProps }) => (
          <CardNumberInput
            id={numberKey}
            placeholder={field.placeholder || '**** **** **** ****'}
            isRequired={field.isRequired}
            label={cardNumberLabel}
            {...fieldProps}
            hasError={!!showErrors.number}
            autoComplete="cc-number"
          />
        )}
      </formik.Field>
      <styles.NestedField
        bottom={2}
        top={2}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '0 0 auto',
            width: 'calc((50%) - 16px)',
          }}
        >
          <styles.TopWrapper>
            <styles.Label htmlFor={expiryKey} isRequired={field.isRequired}>
              {expiryLabel}*
            </styles.Label>
            <styles.ErrorMessage>
              {showErrors.expiryDate && getIn(errors, expiryKey)}
            </styles.ErrorMessage>
          </styles.TopWrapper>
          <formik.Field name={expiryKey}>
            {({ field: fieldProps }) => (
              <ExpirationDateInput
                id={expiryKey}
                placeholder={t('formCardExpirationDatePlaceholder', 'MM/YY')}
                isRequired={field.isRequired}
                label={expiryLabel}
                {...fieldProps}
                hasError={!!showErrors.expiryDate}
                autoComplete="cc-exp"
              />
            )}
          </formik.Field>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '0 0 auto',
            width: 'calc((50%) - 16px)',
            marginTop: 0,
          }}
        >
          <styles.TopWrapper>
            <styles.Label htmlFor={cvcKey} isRequired={field.isRequired}>
              {cvcLabel}*
            </styles.Label>
            <styles.ErrorMessage>
              {showErrors.cvc && getIn(errors, cvcKey)}
            </styles.ErrorMessage>
          </styles.TopWrapper>
          <formik.Field name={cvcKey}>
            {({ field: fieldProps }) => (
              <Input
                id={cvcKey}
                placeholder="***"
                isRequired={field.isRequired}
                label={cvcLabel}
                {...fieldProps}
                hasError={!!showErrors.cvc}
                autoComplete="cc-csc"
              />
            )}
          </formik.Field>
        </div>
      </styles.NestedField>
    </styles.FieldWrapper>
  )
}

const Phone = ({
  field,
  hasTriedSubmitting,
  errors,
  touched,
  isHidden,
  onEnterKeyPress,
}) => {
  const subtleError = !!(
    (hasTriedSubmitting || touched[field.key]) &&
    getIn(errors, field.key)
  )

  return (
    <styles.FieldWrapper
      isHidden={isHidden}
      id="form-field"
      hasError={subtleError}
    >
      <styles.TopWrapper>
        {field.name && (
          <styles.Label
            htmlFor="phone-form-control"
            isRequired={field.isRequired}
          >
            {field.name}
            {field.isRequired ? '*' : ''}
          </styles.Label>
        )}
        {subtleError && (
          <styles.ErrorMessage>{getIn(errors, field.key)}</styles.ErrorMessage>
        )}
      </styles.TopWrapper>
      <formik.Field name={field.key}>
        {({ field: fieldProps }) => (
          <PhoneInput
            id={field.key}
            placeholder={field.placeholder}
            readOnly={field.readOnly}
            {...fieldProps}
            hasError={subtleError}
            onEnterKeyPress={onEnterKeyPress}
          />
        )}
      </formik.Field>
    </styles.FieldWrapper>
  )
}

const preferredCountries = ['US', 'GB', 'IS']

const PhoneWithNationality = ({
  field,
  hasTriedSubmitting,
  errors,
  touched,
  isHidden,
}) => {
  const availableCountries = useMemo(() => {
    const collator = new Intl.Collator('en', {
      numeric: true,
      sensitivity: 'base',
    })

    return [...(field?.availableCountries || [])].sort((a, b) => {
      if (
        preferredCountries.includes(a.value) ||
        preferredCountries.includes(b.value)
      ) {
        if (
          preferredCountries.includes(a.value) &&
          preferredCountries.includes(b.value)
        ) {
          return collator.compare(a.label, b.label)
        }
        return preferredCountries.includes(a.value) ? -1 : 1
      }
      return collator.compare(a.label, b.label)
    })
  }, [field.availableCountries])

  const [countryCode, setCountryCode] = useState(null)
  const subtleError = !!(
    (hasTriedSubmitting || touched[field.key]) &&
    errors[field.key]
  )

  const nationalityKey = field?.nationalityKey || 'nationality'

  return (
    <styles.FieldWrapper
      isHidden={isHidden}
      id="form-field"
      hasError={subtleError}
    >
      <styles.MultiFieldWrapper>
        <styles.NationalityWrapper>
          <styles.TopWrapper>
            {field?.nationalityLabel ? (
              <styles.Label htmlFor={nationalityKey} isRequired={false}>
                {field.nationalityLabel}
              </styles.Label>
            ) : null}
          </styles.TopWrapper>

          <formik.Field name={nationalityKey}>
            {({ field: fieldProps }) => {
              return (
                <Select
                  id={nationalityKey}
                  name={nationalityKey}
                  value={fieldProps.value}
                  onChange={e => {
                    const { value } = e.target
                    fieldProps.onChange(e)

                    if (value) {
                      setCountryCode(value)
                    }
                  }}
                >
                  {field?.nationalityPlaceholder ? (
                    <option value="" disabled={!!field.isRequired}>
                      {field.nationalityPlaceholder}
                    </option>
                  ) : null}
                  {availableCountries.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              )
            }}
          </formik.Field>
        </styles.NationalityWrapper>
        <div>
          <styles.TopWrapper>
            {field.name && (
              <styles.Label
                htmlFor="phone-form-control"
                isRequired={field.isRequired}
              >
                {field.name}
                {field.isRequired ? '*' : ''}
              </styles.Label>
            )}
            {subtleError && (
              <styles.ErrorMessage>{errors[field.key]}</styles.ErrorMessage>
            )}
          </styles.TopWrapper>

          <formik.Field name={field.key}>
            {({ field: fieldProps }) => (
              <PhoneInput
                id={field.key}
                placeholder={field.placeholder}
                {...fieldProps}
                name={field.key}
                hasError={subtleError}
                selectedCode={countryCode}
              />
            )}
          </formik.Field>
        </div>
      </styles.MultiFieldWrapper>
    </styles.FieldWrapper>
  )
}

const WButtonInput = ({
  field,
  hasTriedSubmitting,
  errors,
  touched,
  isHidden = false,
}) => {
  const subtleError = !!(
    (hasTriedSubmitting || touched[field.key]) &&
    getIn(errors, field.key)
  )

  return (
    <styles.FieldWrapper
      isHidden={isHidden}
      id="form-field"
      compact
      hasError={subtleError}
    >
      <styles.TopWrapper>
        {field.name && (
          <styles.Label htmlFor={field.key} isRequired={field.isRequired}>
            {field.name}
            {field.isRequired ? '*' : ''}
          </styles.Label>
        )}
        {subtleError && (
          <styles.ErrorMessage data-cy={`${field.key}-error`}>
            {getIn(errors, field.key)}
          </styles.ErrorMessage>
        )}
      </styles.TopWrapper>
      <formik.Field name={field.key}>
        {({ field: fieldProps }) => (
          <ButtonInput
            id={fieldProps.name}
            buttonLabel={field.buttonLabel}
            name={fieldProps.name}
            onChange={fieldProps.onChange}
            placeholder={field.placeholder}
            value={fieldProps.value}
            hasError={subtleError}
            isRequired={field.isRequired}
          />
        )}
      </formik.Field>
    </styles.FieldWrapper>
  )
}

const variants = {
  inactive: { pathLength: 0 },
  active: { pathLength: 1 },
}

const Tick = ({ active }) => (
  <styles.TickWrapper
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.path
      d="M1 6L6 12L15 1"
      stroke="white"
      strokeWidth="2"
      initial={{ pathLength: 0 }}
      variants={variants}
      animate={active ? 'active' : 'inactive'}
      exit={{}}
      transition={{ duration: 0.3 }}
    />
  </styles.TickWrapper>
)

const Check = () => (
  <styles.CheckWrapper
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.77637 7.49275L8.92562 12.5077L16.2092 3.01514"
      stroke="#454647"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.806 7.31343C16.9254 7.85075 16.9851 8.41791 16.9851 8.98507C16.9851 13.403 13.403 16.9851 8.98507 16.9851C4.56716 16.9851 0.985075 13.403 0.985075 8.98507C0.985075 4.56716 4.56716 0.985075 8.98507 0.985075C10.2388 0.985075 11.4328 1.28358 12.5075 1.79104L13.1045 0.985075C11.8806 0.358209 10.4776 0 8.98507 0C4.02985 0 0 4.02985 0 8.98507C0 13.9403 4.02985 17.9701 8.98507 17.9701C13.9403 17.9701 17.9701 13.9403 17.9701 8.98507C17.9701 8.0597 17.8209 7.19403 17.5821 6.35821L16.806 7.31343Z"
      fill="#454647"
    />
  </styles.CheckWrapper>
)

const PromoCode = ({ field, isHidden, setFieldValue, value, update }) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [error, setError] = useState(false)
  const [couponAdded, setCouponAdded] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [discount, setDiscount] = useState(0)
  const caretVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
  }
  const successVariants = {
    open: { y: 0 },
    closed: { y: '-100%' },
  }

  // TODO: Switch to useBreakpoints
  const isMobile = useObserver(
    observe(viewportWidth(), width => width < breakpoints.md)
  )

  return (
    <styles.FieldWrapper isHidden={isHidden} id="form-field" hasError={error}>
      <styles.TopWrapper>
        {field.name && (
          <button
            type="button"
            aria-controls="bookingCoupon"
            aria-expanded={visible ? 'true' : 'false'}
            onClick={() => setVisible(v => !v)}
          >
            <styles.Label
              htmlFor={field.key}
              isRequired={field.isRequired}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              {field.name}
              {field.isRequired ? '*' : ''}
              <motion.div
                animate={visible ? 'open' : 'closed'}
                variants={caretVariants}
                transition={{ duration: 0.3, type: 'tween' }}
                style={{ marginLeft: '8px' }}
              >
                <Caret height="6px" />
              </motion.div>
            </styles.Label>
          </button>
        )}
      </styles.TopWrapper>
      <AnimatePresence>
        {visible && (
          <motion.div
            id="bookingCoupon"
            role="region"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, type: 'tween' }}
            style={{
              position: 'relative',
              display: 'flex',
              overflow: 'hidden',
            }}
          >
            <formik.Field name={field.key}>
              {({ field: fieldProps }) => (
                <Input
                  id={fieldProps.name}
                  label={field.name}
                  placeholder={field.placeholder}
                  isRequired={field.isRequired}
                  {...fieldProps}
                  hasError={error}
                />
              )}
            </formik.Field>
            <Button
              paddingSize="small"
              style={{ height: '48px' }}
              disabled={loading}
              onClick={async e => {
                e.preventDefault()
                setLoading(true)
                const discountedPrice = await update()

                if (typeof discountedPrice === 'number') {
                  if (discountedPrice > 0) {
                    setError(false)
                    setCouponAdded(true)
                    window.setTimeout(() => setShowSuccess(true), 600)
                    setDiscount(discountedPrice)
                  } else {
                    setError(true)
                  }
                }
                setLoading(false)
              }}
            >
              {couponAdded ? <Tick active={visible} /> : 'Add'}
            </Button>
            <styles.CouponSuccess
              animate={showSuccess ? 'open' : 'closed'}
              variants={successVariants}
            >
              <Check />
              <Type preset="labelTiny">
                Coupon <strong>{value}</strong> active: Saving{' '}
                <Amount value={discount} useSymbol />
              </Type>

              <button
                onClick={async e => {
                  e.preventDefault()
                  setFieldValue(field.key, '')
                  setCouponAdded(false)
                  setShowSuccess(false)
                  setDiscount(0)
                  setLoading(true)
                  await update()
                  setLoading(false)
                }}
                style={{ fontWeight: 'bold', cursor: 'pointer' }}
              >
                {isMobile ? <CloseIcon width="10" height="10" /> : 'Remove'}
              </button>
            </styles.CouponSuccess>
          </motion.div>
        )}
      </AnimatePresence>
      {error && (
        <div style={{ marginTop: '4px' }}>
          <styles.ErrorMessage>
            This is not a valid coupon code
          </styles.ErrorMessage>
        </div>
      )}
    </styles.FieldWrapper>
  )
}

const NationalId = ({
  field,
  hasTriedSubmitting,
  errors,
  touched,
  isHidden,
}) => {
  const subtleError = !!(
    (hasTriedSubmitting || touched[field.key]) &&
    getIn(errors, field.key)
  )

  return (
    <styles.FieldWrapper isHidden={isHidden} id="form-field">
      <styles.TopWrapper>
        {field.name && (
          <styles.Label htmlFor={field.key} isRequired={field.isRequired}>
            {field.name}
            {field.isRequired ? '*' : ''}
          </styles.Label>
        )}
        <styles.ErrorMessage>
          {subtleError && getIn(errors, field.key)}
        </styles.ErrorMessage>
      </styles.TopWrapper>
      <formik.Field name={field.key}>
        {({ field: fieldProps }) => (
          <NationalIdInput
            id={fieldProps.name}
            label={field.name}
            placeholder={field.placeholder}
            isRequired={field.isRequired}
            readOnly={field.readOnly}
            {...fieldProps}
            hasError={subtleError}
          />
        )}
      </formik.Field>
    </styles.FieldWrapper>
  )
}

const Row = ({ field: row, ...rest }) => {
  const subtleError = !!(
    (rest.hasTriedSubmitting || rest.touched[row.key]) &&
    getIn(rest.errors, row.key)
  )
  return (
    <styles.RowOuter>
      <styles.Row>
        {row.fields.map(({ field, width }, i) => {
          const Widget = widgets[field.type]
          if (!Widget) {
            return null
          }
          return (
            <styles.RowItem
              key={field.key}
              width={width}
              spaceBetween={i === row.fields.length - 1 ? 0 : row.spaceBetween}
            >
              <Widget field={field} marginBottom={0} {...rest} />
            </styles.RowItem>
          )
        })}
      </styles.Row>
      {subtleError ? (
        <styles.RowErrorMessage>
          {subtleError && getIn(rest.errors, row.key)}
        </styles.RowErrorMessage>
      ) : null}
    </styles.RowOuter>
  )
}

const CollapsableList = props => {
  const { errors, field, hasTriedSubmitting, touched } = props
  const {
    key,
    length,
    entries = Array.from({ length }).map(() => ({})),
    fields = [],
  } = field
  return (
    <formik.FieldArray name={key}>
      {() =>
        entries.map((entry, index) => {
          const itemErrors = errors?.[key]?.[index] || {}
          const hasError = Object.values(itemErrors).filter(Boolean).length > 0
          const prefix = [key, index].join('.') + '.'
          return (
            <Fragment key={index}>
              <Collapse
                open={index === 0}
                header={
                  entries.length > 1 ? (
                    <Type
                      preset="textLarge"
                      weight="bold"
                      color={
                        hasTriedSubmitting && hasError
                          ? colors.errorRed
                          : colors.dark
                      }
                    >
                      {entry.name}
                    </Type>
                  ) : null
                }
              >
                {(entry.fields || fields).map(field => {
                  const Widget = widgets[field.type]
                  if (!Widget) {
                    return null
                  }
                  const prefixedKey = [prefix, field.key].join('')
                  return (
                    <Widget
                      key={prefixedKey}
                      field={{ ...field, key: prefixedKey }}
                      hasTriedSubmitting={hasTriedSubmitting}
                      errors={errors}
                      touched={touched}
                    />
                  )
                })}
              </Collapse>
              {index === entries.length - 1 && entries.length > 1 && (
                <styles.Divider />
              )}
            </Fragment>
          )
        })
      }
    </formik.FieldArray>
  )
}

export const widgets = {
  address: Address,
  buttonInput: WButtonInput,
  card: Card,
  checkbox: Checkbox,
  collapsableList: CollapsableList,
  country: Country,
  derived: Derived,
  email: WInput,
  file: File,
  name: Name,
  nationalId: NationalId,
  number: WInput,
  phone: Phone,
  phoneWithNationality: PhoneWithNationality,
  promoCode: PromoCode,
  radio: WRadio,
  simpleRadio: WSimpleRadio,
  reservation: Reservation,
  row: Row,
  select: WSelect,
  text: WInput,
  textArea: WTextArea,
}
