import { gql } from '@apollo/client'

export const GET_DIRECTIONS = gql`
  query directions(
    $startLatitude: Float!
    $startLongitude: Float!
    $endLatitude: Float!
    $endLongitude: Float!
  ) {
    directions(
      startLatitude: $startLatitude
      startLongitude: $startLongitude
      endLatitude: $endLatitude
      endLongitude: $endLongitude
    ) {
      coordinates
    }
  }
`
