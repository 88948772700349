import { gql } from '@apollo/client'

export const VALIDATE_MEMBERSHIP_PAYMENT = gql`
  mutation validateMembershipPayment(
    $payment: MembershipPaymentValidationInformation!
  ) {
    validateMembershipPayment(payment: $payment) {
      membershipRef
    }
  }
`
