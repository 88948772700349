import { FC, ReactNode } from 'react'

import { PartialBookingEngine } from '../../styles/types'
import { Arrow, StyledSelect, TextAreaContainer } from './styles'

interface SelectProps {
  id: string
  name: string
  children: ReactNode
  value?: string
  required?: boolean
  hasError?: boolean
  rounded?: boolean
  className?: string
  autoComplete?: string
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
  themeStyle?: PartialBookingEngine['selectField']['select']
}

const DownArrowRounded = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1L5 5L1 1"
      stroke="#52A5B8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const Select: FC<SelectProps> = ({
  id,
  name,
  value = '',
  required = false,
  hasError = false,
  onChange,
  rounded,
  className,
  autoComplete,
  themeStyle,
  ...rest
}) => {
  return (
    <TextAreaContainer
      isRounded={rounded}
      themeStyle={themeStyle}
      className={className}
    >
      <StyledSelect
        id={id}
        name={name}
        value={value}
        showingPlaceholder={value === '__initial' || value === ''}
        required={required}
        hasError={hasError}
        onChange={onChange}
        isRounded={rounded}
        autoComplete={autoComplete}
        themeStyle={themeStyle}
        {...rest}
      />
      {rounded && (
        <Arrow>
          <DownArrowRounded />
        </Arrow>
      )}
    </TextAreaContainer>
  )
}
