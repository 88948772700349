export const myBookingUrl = 'my.bluelagoon.com'

export const getMyBookingLink = () => {
  const blEnv = process.env.NEXT_PUBLIC_BL_ENV

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    return `https://${myBookingUrl}`
  }

  switch (blEnv) {
    case 'rc':
    case 'staging':
    case 'upgrade':
      return `https://${blEnv}-my.bluelagoon.dev`
    default:
      return 'https://my.bluelagoon.dev'
  }
}
