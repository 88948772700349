import { gql } from '@apollo/client'

export const PRODUCT_AVAILABILITY = gql`
  query ProductsAvailability($input: ProductAvailabilitiesInput!) {
    productAvailabilities(input: $input) {
      productId
      available
      lengthOfStay
      price
      fields
      time
      offerId
      subOffers {
        offerId
        date
        price
      }
    }
  }
`
