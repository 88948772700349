import { gql } from '@apollo/client'

import { CART_FRAGMENT } from './fragments/cart'

export const REMOVE_GIFT_CARD_FROM_CART = gql`
  ${CART_FRAGMENT}
  mutation RemoveGiftCardFromCart($input: RemoveGiftCardInput!) {
    removeGiftCard(input: $input) {
      ...CartFragment
    }
  }
`
