import { gql } from '@apollo/client'

export const ROOM_AVAILABILITY = gql`
  query RoomAvailability($input: RoomAvailabilityInput!) {
    roomAvailability(input: $input) {
      productId
      rateCodes
      available
      lengthOfStay
      allowedGuests
      allowedAdults
      allowedChildren
      allowedInfants
      price
      fields
    }
  }
`

export const ROOM_RANGE_AVAILABILITY = gql`
  query RoomRangeAvailability($input: RoomRangeAvailabilityInput!) {
    roomRangeAvailability(input: $input) {
      date
      roomType
      property
      totalRooms
      availableRooms
      validArrivalDate
      validDepartureDate
    }
  }
`

export const ROOM_RANGE_AVAILABILITY_RESTRICTIONS = gql`
  query RoomRangeAvailabilityRestrictions($input: RoomRangeAvailabilityInput!) {
    roomRangeAvailabilityRestrictions(input: $input) {
      date
      roomType
      property
      totalRooms
      availableRooms
      validArrivalDate
      validDepartureDate
    }
  }
`
