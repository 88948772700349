import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import * as styles from './styles'

interface Props {
  open: boolean
  children: React.ReactNode
  header?: React.ReactNode
}

export const Collapse: React.FC<Props> = ({ open, header, children }) => {
  const [collapsed, setCollapsed] = useState(!open)

  const toggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setCollapsed(x => !x)
  }

  useEffect(() => {
    if (open !== !collapsed) {
      setCollapsed(!collapsed)
    }
  }, [open])

  return (
    <styles.Container>
      {header ? (
        <styles.Header onClick={toggle}>
          {header}
          <motion.div
            animate={collapsed ? 'closed' : 'open'}
            variants={{
              closed: {
                rotate: 180,
              },
              open: {
                rotate: 0,
              },
            }}
            transition={{ duration: 0.4 }}
          >
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 5L5 1L1 5"
                stroke="#454647"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </motion.div>
        </styles.Header>
      ) : null}
      <styles.Content
        collapsed={collapsed}
        aria-expanded={collapsed}
        aria-label="Click to expand"
        animate={collapsed ? 'closed' : 'open'}
        variants={{
          open: {
            opacity: 1,
            height: 'auto',
          },
          closed: {
            opacity: 0,
            height: 0,
          },
        }}
        transition={{ duration: 0.4 }}
      >
        {children}
      </styles.Content>
    </styles.Container>
  )
}
