import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useMutationObserver } from 'rooks'

// Context to store dynamic elements that need to be passed as shards to react - focus - on

// Selectors for external elements to track
const COOKIE_DIALOG_SELECTOR = '.ch2-dialog.ch2-visible'
const ZENDESK_CHAT_SELECTOR = '#launcher'

const SELECTORS = [COOKIE_DIALOG_SELECTOR, ZENDESK_CHAT_SELECTOR]

const DynamicShardsContext = createContext<HTMLElement[] | null>(null)

export const DynamicShardsProvider = ({ children }) => {
  const [dynamicShards, setDynamicShards] = useState([])
  const observerRef = useRef(null)

  // Callback to check for dynamic elements in the DOM
  const checkForDynamicElements = useCallback(() => {
    const elementsToTrack = SELECTORS.map(selector =>
      document.querySelector(selector)
    ).filter(Boolean)

    setDynamicShards(prev => {
      const updatedShards = Array.from(new Set([...prev, ...elementsToTrack]))
      return prev.length === updatedShards.length ? prev : updatedShards
    })
  }, [])

  useMutationObserver(observerRef, checkForDynamicElements, {
    childList: true,
    subtree: true,
  })

  // Initialize observer and perform an initial check
  useEffect(() => {
    observerRef.current = document.body
    checkForDynamicElements()
  }, [checkForDynamicElements])

  const memoizedShards = useMemo(() => dynamicShards, [dynamicShards])

  return (
    <DynamicShardsContext.Provider value={memoizedShards}>
      {children}
    </DynamicShardsContext.Provider>
  )
}

// Hook to consume the context
export const useDynamicShards = () => {
  const context = useContext(DynamicShardsContext)
  if (!context) {
    throw new Error(
      'useDynamicShards must be used within a DynamicShardsProvider'
    )
  }
  return context
}
