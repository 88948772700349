import { gql } from '@apollo/client'

export const CONFIRM_BOOKING = gql`
  mutation confirmBooking($booking: ConfirmedBooking!) {
    confirmBooking(booking: $booking) {
      bookingId
      acsInfo {
        acsForm
        acsFormData {
          name
          value
        }
      }
      tdsmInfo {
        tdsmContent
      }
    }
  }
`
