import { gql } from '@apollo/client'

export const GET_DROPOFF_LOCATIONS = gql`
  query dropoffLocations {
    dropoffLocations {
      id
      name
      latitude
      longitude
      bus
      address
      busStop {
        id
        name
        number
        address
        latitude
        longitude
      }
    }
  }
`
