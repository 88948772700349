import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors } from '../constants/colors'
import { StyledInput } from './Input/Input'

const Container = styled.div`
  position: relative;
  width: 100%;

  &::after {
    border-bottom: 2px solid ${colors.fountainBlue};
    content: '';
    border-left: 2px solid ${colors.fountainBlue};
    display: block;
    height: 10px;
    right: 16px;
    position: absolute;
    top: -4px;
    bottom: 0;
    margin: auto;
    transform: rotate(-45deg);
    width: 10px;
  }
`

const DataListInput = styled(StyledInput)`
  ::-webkit-list-button,
  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`

export const Datalist = ({
  id,
  name,
  list,
  value = '',
  placeholder,
  required = false,
  hasError = false,
  onChange = () => {},
}) => {
  return (
    <Container>
      <datalist id={`${id}-data`}>
        {list.map(value => (
          <option key={value} value={value} />
        ))}
      </datalist>
      <DataListInput
        list={`${id}-data`}
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        required={required}
        hasError={hasError}
        onChange={onChange}
      />
    </Container>
  )
}

Datalist.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  list: PropTypes.array.isRequired,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
}
