import { gql } from '@apollo/client'

export const UPDATE_BOOKING = gql`
  mutation updateBooking($info: BookingInformation!) {
    updateBooking(info: $info) {
      bookingId
      booking {
        adultCount
        adultPrice
        discountedAdultPrice
        childrenCount
        childrenPrice
        discountedChildrenPrice
        totalAmount
        giftCardAmount
        giftCardPaymentAmount
        paymentAmount
        comment
        promoCode
        busTransfer {
          discountedAdultPrice
          adultPrice
        }
        massage {
          discountedPrice
          price
          guests
          productID
          time
        }
        products {
          price
          totalAmount
          productId
          qty
        }
      }
    }
  }
`
export const UPDATE_BOOKING_RAW = `
mutation updateBooking($info: BookingInformation!) {
  updateBooking(info: $info) {
    bookingId
    booking {
      adultCount
      adultPrice
      discountedAdultPrice
      childrenCount
      childrenPrice
      discountedChildrenPrice
      totalAmount
      giftCardAmount
      giftCardPaymentAmount
      paymentAmount
      comment
      promoCode
      busTransfer {
        discountedAdultPrice
        adultPrice
      }
      massage {
        discountedPrice
        price
        guests
        productID,
        time
      }
      products {
        price
        totalAmount
        productId
        qty
      }
    }
  }
}
`
