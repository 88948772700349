const en = {
  back: 'Back',
  continue: 'Continue',
  currency: 'Currency',
  closeCurrency: 'Close currency selection',
  breadcrumbBack: 'Back to {{name}}',
  followUs: 'Social media',
  supportCenter: 'Support center',
  supportBody: 'Our service center is open from 9am-7pm every day',
  setMenu: 'Lunch set menus',
  tastingMenu: 'Dinner tasting menu',
  alaCarteMenuTitle: 'A la carte {{name}} menu',
  lunch: 'Lunch',
  dinner: 'Dinner',
  viewDinnerMenu: 'View dinner menu',
  viewLunchMenu: 'View lunch menu',
  lunchMenu: 'Lunch menu',
  dinnerMenu: 'Dinner menu',
  bookDinnerTable: 'Book a table for dinner',
  bookLunchTable: 'Book a table for lunch',
  mainDishes: 'Main Dishes',
  starters: 'Starters',
  desserts: 'Desserts',
  euro: 'Euro',
  poundSterling: 'Pound sterling',
  usDollar: 'US Dollar',
  icelandicKrona: 'Icelandic Krona',
  language: 'Language',
  closeLanguage: 'Close language selection',
  english: 'English',
  icelandic: 'Icelandic',
  defaultMetaTitle: 'Blue Lagoon Iceland',
  inclusions: 'Inclusions',
  bookNow: 'Book now',
  checkAvailability: 'Check availability',
  guest: 'guest',
  guests: 'guests',
  rooms: 'rooms',
  and: 'and',
  currencySelectorAria: 'select currency',
  readTime: '{{minutes}} min read',
  relatedStories: 'Related Stories',
  storyPath: '/stories',
  storyPathWithSlug: '/stories/{{slug}}',
  storyTopicPath: '/stories/topic/{{topicSlug}}',
  readMore: 'Read more',
  latestStories: 'Latest stories',
  showMoreStories: 'Show more',
  allStories: 'All stories',
  formFirstNamePlaceholder: 'First name',
  formLastNamePlaceholder: 'Last name',
  formCardNumberLabel: 'Card number',
  formCardExpirationDateLabel: 'Expiration date',
  formCardExpirationDatePlaceholder: 'MM/YY',
  formCardCVCLabel: 'CVC',
  filter_blue_lagoon: 'Blue Lagoon',
  filter_retreat_spa: 'Retreat Spa',
  filter_hotel: 'Hotel',
  filter_hotels: 'Hotel',
  filter_dining: 'Dining',
  filter_massage: 'Massage',
  filter_wellness: 'Wellness',
  filter_adventure: 'Adventure',
  filter_cultural: 'Cultural',
  filter_culinary: 'Culinary',
  filter_in_water: 'In-water',
  filter_facial: 'Facial',
  filter_add_on: 'Add-on',
  filter_self_guided: 'Self guided',
  filter_mindfulness: 'Mindfulness',
  filter_singleday: 'Single day',
  filter_overnight: 'Overnight stay',
  filter_romance: 'Romance',
  filter_family_friendly: 'Family friendly',
  filter_private_experience: 'Private experience',
  filter_suitable_for_groups: 'Suitable for groups',
  filter_popular: 'Popular',
  filter_action: 'Action',
  filter_spring: 'Spring',
  filter_summer: 'Summer',
  filter_autumn: 'Autumn',
  filter_winter: 'Winter',
  filter_allYear: 'All year',
  filter_duration: 'Duration',
  filter_seasons: 'Seasons',
  filter_ageLimit: 'Age limit',
  filter_location: 'Location',
  filter_types: 'Types',
  filter_categories: 'Categories',
  filter_roomFeatures: 'Room Features',
  filter_king_bed: 'King bed',
  filter_twin_bed: 'Twin bed',
  filter_wheelchair_accessible: 'Wheelchair Accessible',
  filter_lounge: 'Lounge',
  filter_private_lagoon: 'Private Lagoon',
  filter_upper_level: 'Upper Level',
  filter_lower_level: 'Lower Level',
  filter_roomView: 'Room View',
  filter_mountain: 'Mountain',
  filter_moss: 'Moss',
  filter_lagoon: 'Lagoon',
  filter_lava: 'Lava',
  filter_RETREAT: 'The Retreat',
  filter_SILICA: 'Silica hotel',
  filterHeading: 'Interested in',
  recommendationHeading: 'You Might Also Like',
  showMore: 'View More',
  initialSort: 'Sort by',
  priceHigh: 'Highest first',
  priceLow: 'Lowest first',
  alphabetically: 'Alphabetically',
  close: 'Close',
  viewResults: 'View results',
  searchCount_empty:
    '{{count}} results, would you like to view these categories',
  searchCount: '{{count}} result',
  searchCount_plural: '{{count}} results',
  clearAll: 'Clear all',
  giftcards: 'Gift cards',
  giftCardNumber: 'Gift card number',
  moreDetail: 'More detail',
  balance: 'Balance',
  giftCardExpired: 'Gift card is expired',
  giftCardInvalid: 'Gift card is invalid',
  giftCardNumberReqired: 'Please provide a valid gift card number',
  giftCardNotFound: 'A giftcard with this number could not be found',
  backToGiftCards: 'See all gift cards',
  backToExperiences: 'See all experiences',
  help: 'Help',
  questions: 'Questions?',
  howCanWeHelp: 'How can we help?',
  editOrCancelBooking: 'Manage my booking',
  openingHours: 'Opening hours',
  onlineChat: 'Online chat',
  contactUs: 'Contact us',
  editOrCancel: 'Edit or Cancel',
  frequentlyAskedQuestions: 'Have questions?',
  blueLagoonBooking: 'Blue Lagoon booking',
  silicaHotelBooking: 'Silica Hotel booking',
  theRetreatBooking: 'The Retreat booking',
  ageInYears: '{{count}} year',
  ageInYears_plural: '{{count}} years',
  ageInYears_infant: 'newborn',
  childNrAge: 'Child {{count}} age',
  roomDetails: 'Room details',
  'roomDetails.included': 'Included',
  'breadcrumbs.paymentDetails.ariaLabel': 'go to checkout',
  'stepmanager.query.step': 'step',
  'breadcrumbs.subscriptions.ariaLabel': 'go to subscriptions',
  'breadcrumbs.cardHolders.ariaLabel': 'go to cardholders',
  'breadcrumbs.amount.ariaLabel': 'go to amount',
  'breadcrumbs.delivery.ariaLabel': 'go to delivery',
  'booking.general.guest_plural': 'Guests',
  'booking.general.guest': 'Guest',
  'booking.general.adult_plural': 'Adults',
  'booking.general.child_plural': 'Children',
  'booking.hotel.guestpicker.headline': 'How many of you are visiting?',
  'booking.general.infant_plural': 'Infants',
  'booking.general.year_plural': 'years',
  'booking.general.adultsDisclaimer': 'Only two adults per room',
  'booking.general.childrenDisclaimer': 'Only one child per room',
  'booking.general.infantsDisclaimer': 'Only one infant per room',
  'booking.general.room_plural': 'Rooms',
  'booking.general.room': 'Room',
  'booking.general.noOfRooms': 'No. of rooms',
  'booking.general.night_plural': 'Nights',
  'booking.general.night': 'Night',
  'booking.hotel.hotelpicker.headline': 'Select a destination / hotel',
  'booking.hotel.datepicker.headline': 'What date are you visiting?',
  'booking.hotel.datepicker.subheadline': 'We are open every day of the year',
  'booking.hotel.availability.button': 'Check availability',
  'booking.hotel.more.rooms': 'For more rooms ',
  SILICA: 'Silica Hotel',
  RETREAT: 'The Retreat',
  'booking.bar.hotelPicker.label': 'Hotel',
  'booking.bar.checkIn.label': 'Check in',
  'booking.bar.checkOut.label': 'Check out',
  'booking.bar.select.arrival.date': 'Select check in date',
  'booking.bar.select.departure.date': 'Select check out date',
  'booking.bar.guests.label': 'Guests',
  'booking.bar.guests.and.rooms.label': 'Guests / Rooms',
  'booking.bar.unavailable.dates.warning':
    'Your selection includes unavailable dates',
  'booking.bar.checkout.only.warning': 'Check-out only',
  'booking.bar.minlos.disclaimer':
    'Please note that many rooms at The Retreat have a minimum stay of 2 nights',
  'booking.bar.fullyBooked.warning': 'Not available',
  'booking.bar.dates.label': 'Dates',
  'booking.bar.price.from.label': 'Price from',
  'booking.cart.total': 'Your total: {{price}}',
  'booking.cart.edit': 'Edit cart',
  'booking.cart.price.icelandic':
    'You will be charged {{total}} (Icelandic currency)',
  'booking.cart.title': 'Your selection',
}

const is = {
  back: 'Til baka',
  continue: 'Áfram',
  currency: 'Gjaldmiðlar',
  closeCurrency: 'Loka gjaldmiðla valmynd',
  breadcrumbBack: 'Til baka',
  followUs: 'Samfélagsmiðlar',
  supportCenter: 'Þjónustuver',
  supportBody: 'Þjónustuverið okkar er opið frá 9 til 19 alla daga',
  setMenu: 'Samsettir seðlar',
  tastingMenu: 'Smökkun',
  alaCarteMenuTitle: 'Matseðlar',
  lunch: 'Hádegisverður',
  dinner: 'Kvöldverður',
  viewDinnerMenu: 'Skoða kvöldverði',
  viewLunchMenu: 'Skoða hádegisverði',
  lunchMenu: 'Hádegisverðir',
  dinnerMenu: 'Kvöldverðir',
  bookDinnerTable: 'Bóka kvöldverðaborð',
  bookLunchTable: 'Bóka hádegisverðaborð',
  mainDishes: 'Aðalréttir',
  starters: 'Forréttir',
  desserts: 'Eftirréttir',
  euro: 'Evra',
  poundSterling: 'Sterlingspund',
  usDollar: 'Bandaríkjadalur',
  icelandicKrona: 'Íslensk króna',
  language: 'Tungumál',
  closeLanguage: 'Loka tungumála valmynd',
  english: 'Enska',
  icelandic: 'Íslenska',
  defaultMetaTitle: 'Bláa Lónið',
  inclusions: 'Innifalið',
  bookNow: 'Bóka herbergi',
  checkAvailability: 'Leita',
  guest: 'gestur',
  guests: 'gestir',
  rooms: 'herbergi',
  and: 'og',
  currencySelectorAria: 'Velja tungumál',
  readTime: '{{minutes}} mín lestur',
  relatedStories: 'Aðrar sögur',
  storyPath: '/sogur',
  storyPathWithSlug: '/is/sogur/{{slug}}',
  storyTopicPath: '/is/sogur/topic/{{topicSlug}}',
  readMore: 'Lesa meira',
  latestStories: 'Nýjar greinar',
  showMoreStories: 'Fleiri sögur',
  allStories: 'Allar sögur',
  formFirstNamePlaceholder: 'Fornafn',
  formLastNamePlaceholder: 'Eftirnafn',
  formCardNumberLabel: 'Kortanúmer',
  formCardExpirationDateLabel: 'Gildistími',
  formCardExpirationDatePlaceholder: 'MM/ÁÁ',
  formCardCVCLabel: 'CVC',
  filter_blue_lagoon: 'Bláa Lónið',
  filter_retreat_spa: 'Spa',
  filter_hotel: 'Hótel',
  filter_hotels: 'Hótel',
  filter_dining: 'Veitingastaðir',
  filter_massage: 'Nudd',
  filter_wellness: 'Heilsa',
  filter_adventure: 'Ævintýri',
  filter_cultural: 'Menning',
  filter_culinary: 'Matreiðsla',
  filter_in_water: 'In-water',
  filter_facial: 'Andlitsmeðferð',
  filter_add_on: 'Add-on',
  filter_self_guided: 'Self guided',
  filter_mindfulness: 'Mindfulness',
  filter_singleday: 'Einn dagur',
  filter_overnight: 'Yfir nótt',
  filter_romance: 'Rómantík',
  filter_family_friendly: 'Fjölskylduvænt',
  filter_private_experience: 'Private Experience',
  filter_suitable_for_groups: 'Fyrir hópa',
  filter_popular: 'Popular',
  filter_action: 'Hasar',
  filter_spring: 'Vor',
  filter_summer: 'Sumar',
  filter_autumn: 'Haust',
  filter_winter: 'Vetur',
  filter_allYear: 'Allt árið',
  filter_duration: 'Lengd',
  filter_seasons: 'Árstíðir',
  filter_ageLimit: 'Aldurstakmark',
  filter_location: 'Staðsetning',
  filter_types: 'Tög',
  filter_categories: 'Upplifun',
  // TODO translate
  filter_roomFeatures: 'Room Features',
  filter_king_bed: 'King bed',
  filter_twin_bed: 'Twin bed',
  filter_wheelchair_accessible: 'Wheelchair Accessible',
  filter_lounge: 'Lounge',
  filter_private_lagoon: 'Private Lagoon',
  filter_upper_level: 'Upper Level',
  filter_lower_level: 'Lower Level',
  filter_roomView: 'Room View',
  filter_mountain: 'Mountain',
  filter_moss: 'Moss',
  filter_lagoon: 'Lagoon',
  filter_lava: 'Lava',
  filter_RETREAT: 'The Retreat',
  filter_SILICA: 'Silica hotel',
  filterHeading: 'Hef áhuga á',
  recommendationHeading: 'Fleiri upplifanir',
  showMore: 'Meira',
  initialSort: 'Raða eftir',
  priceHigh: 'Hæsta verði',
  priceLow: 'Lægsta verði',
  alphabetically: 'Stafrófsröð',
  close: 'Loka',
  viewResults: 'Sýna niðurstöður',
  searchCount_empty: 'Engar niðurstöður, má bjóða þér að skoða aðra flokka',
  searchCount: '{{count}} niðurstaða',
  searchCount_plural: '{{count}} niðurstöður',
  clearAll: 'Hreinsa',
  giftcards: 'Gjafabréf',
  giftCardNumber: 'Gjafabréfanúmer',
  moreDetail: 'Nánar',
  balance: 'Staða',
  giftCardExpired: 'Þetta gjafabréf er útrunnið',
  giftCardInvalid: 'Þetta gjafabréf er ógilt',
  giftCardNumberReqired: 'Vinsamlegast sláðu inn gjafabréfsnúmer',
  giftCardNotFound: 'Ekki fannst gjafabréf með þessu númeri',
  backToGiftCards: 'Sjá öll gjafabréf',
  backToExperiences: 'Sjá allar upplifanir',
  help: 'Hjálp',
  questions: 'Spurningar?',
  howCanWeHelp: 'Hvernig getum við aðstoðað?',
  editOrCancelBooking: 'Breyta eða hætta við bókun',
  openingHours: 'Opnunartímar',
  onlineChat: 'Netspjall',
  contactUs: 'Hafa samband',
  editOrCancel: 'Breyta eða hætta við bókun',
  frequentlyAskedQuestions: 'Ertu með spurningar?',
  blueLagoonBooking: 'Bláa Lóns bókun',
  silicaHotelBooking: 'Silica Hótel bókun',
  theRetreatBooking: 'The Retreat bókun',
  ageInYears: '{{count}} árs',
  ageInYears_plural: '{{count}} ára',
  ageInYears_infant: 'ungabarn',
  childNrAge: 'Barn {{count}} aldur',
  roomDetails: 'Herbergis lýsing',
  'roomDetails.included': 'Innifalið',
  'breadcrumbs.paymentDetails.ariaLabel': 'Fara í greiðsluskref',
  'stepmanager.query.step': 'skref',
  'breadcrumbs.subscriptions.ariaLabel': 'Velja kort',
  'breadcrumbs.cardHolders.ariaLabel': 'Velja korthafa',
  'breadcrumbs.amount.ariaLabel': 'Fara í upphæð',
  'breadcrumbs.delivery.ariaLabel': 'Fara í afhendingu',
  'booking.general.guest_plural': 'gestir',
  'booking.general.guest': 'gestur',
  'booking.general.adult_plural': 'Fullorðnir',
  'booking.general.child_plural': 'Börn',
  'booking.general.infant_plural': 'Ungabörn',
  'booking.general.year_plural': 'ára',
  'booking.general.adultsDisclaimer': 'Aðeins tveir fullorðnir á herbergi',
  'booking.general.childrenDisclaimer': 'Aðeins eitt barn á herbergi',
  'booking.general.infantsDisclaimer': 'Aðeins eitt ungabarn á herbergi',
  'booking.general.room_plural': 'herbergi',
  'booking.general.room': 'herbergi',
  'booking.general.noOfRooms': 'Fjöldi herbergja',
  'booking.general.night_plural': 'Nætur',
  'booking.general.night': 'Nótt',
  'booking.hotel.hotelpicker.headline': 'Veldu áfangastað',
  'booking.hotel.guestpicker.headline': 'How many of you are visiting?',
  'booking.hotel.datepicker.headline': 'What date are you visiting?',
  'booking.hotel.datepicker.subheadline': 'We are open every day of the year',
  'booking.hotel.availability.button': 'Skoða framboð',
  'booking.hotel.more.rooms': 'Fleiri herbergi ',
  SILICA: 'Silica Hotel',
  RETREAT: 'The Retreat',
  'booking.bar.hotelPicker.label': 'Áfangastaður / Hótel',
  'booking.bar.checkIn.label': 'Koma',
  'booking.bar.checkOut.label': 'Brottför',
  'booking.bar.select.arrival.date': 'Veldu innritunardag',
  'booking.bar.select.departure.date': 'Veldu útritunardag',
  'booking.bar.guests.label': 'Fjöldi gesta',
  'booking.bar.guests.and.rooms.label': 'Fjöldi gesta / herbergja',
  'booking.bar.unavailable.dates.warning':
    'Valið tímabil inniheldur dagsetningar sem ekki eru í boði',
  'booking.bar.checkout.only.warning': 'Aðeins útritun',
  'booking.bar.minlos.disclaimer':
    'Vinsamlegast athugið að mörg herbergi á Retreat hótelinu eru með lágmarks dvöl 2 nætur',
  'booking.bar.fullyBooked.warning': 'Ekkert laust',
  'booking.bar.dates.label': 'Dagsetningar',
  'booking.bar.price.from.label': 'Verð frá',
  'booking.cart.total': 'Samtals: {{price}}',
  'booking.cart.edit': 'Breyta',
  'booking.cart.price.icelandic': 'Verð í íslenskum krónum {{total}}',
  'booking.cart.title': 'Karfan þín',
}

export default {
  en: {
    translations: en,
  },
  is: {
    translations: is,
  },
}
