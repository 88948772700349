import { gql } from '@apollo/client'

export const GET_AVAILABLE_PACKAGES = gql`
  query packageAvailability($date: String!, $subscription: String) {
    packageAvailability(date: $date, subscription: $subscription) {
      date
      slots {
        time
        packages {
          available
          price
          type
          slots {
            time
            available
            price
            type
          }
        }
      }
    }
  }
`
