type AvailableCurrencies = {
  symbol: string
  ISO: string
  name: string
  id: string // used for translation
}

export const availableCurrencies: AvailableCurrencies[] = [
  {
    symbol: '€',
    ISO: 'EUR',
    name: 'Euro',
    id: 'euro',
  },
  {
    symbol: '$',
    ISO: 'USD',
    name: 'US Dollar',
    id: 'usDollar',
  },
  {
    symbol: '£',
    ISO: 'GBP',
    name: 'Pound sterling',
    id: 'poundSterling',
  },
  {
    symbol: '',
    ISO: 'ISK',
    name: 'Icelandic Krona',
    id: 'icelandicKrona',
  },
]
