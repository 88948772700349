import { gql } from '@apollo/client'

export const RESTRICTION_FRAGMENT = gql`
  fragment RestrictionFragment on Restriction {
    dateFrom
    dateTo
    allDays
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
    minimumLeadDays
    minimumLengthOfStay
    maximumLeadDays
    maximumLengthOfStay
    sellLimit
    property
    description
    type
    rateCode
    roomType
  }
`
