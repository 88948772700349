import { gql } from '@apollo/client'

import { CART_FRAGMENT } from './fragments/cart'

export const ADD_GIFT_CARD_TO_CART = gql`
  ${CART_FRAGMENT}
  mutation AddGiftCardToCart($input: AddGiftCardInput!) {
    addGiftCard(input: $input) {
      ...CartFragment
    }
  }
`
