import axios from 'axios'
import { differenceInHours } from 'date-fns/differenceInHours'
import isEmpty from 'lodash/isEmpty'

import { localStorage } from 'bl-utils/src/storage/localStorage'

import { availableCurrencies } from '../../constants/availableCurrencies'
import { countryCodeCurrencyMap } from '../../constants/countryCodeCurrencyMap'

type LocationInfoData = {
  city?: string
  continentCode?: string
  continentName?: string
  countryCode?: string
  countryName?: string
  ipAddress?: string
  stateProv?: string
}

const apiKey = process.env.NEXT_PUBLIC_DB_IP_API_KEY || 'free'
const url = `https://api.db-ip.com/v2/${apiKey}/self`

const getUserLocationInfo = async () => {
  try {
    const locationInfo = await axios.get<LocationInfoData>(url)
    return locationInfo.data
  } catch {
    return null
  }
}

export type Rates = { EUR?: number; GBP?: number; ISK?: number; USD?: number }
type ExchangeRates = {
  rates: Rates
  mcpRates: Rates
  timeCreated?: string
  retrievalReferenceNumber?: string
}

type ExchangeRatesResponse = {
  mcp: {
    retrievalReferenceNumber: string
    rates: Rates
  }
  rates: Rates
}

type Currency = {
  ISO?: string
  countryCode?: string
  timeCreated?: string
}

export const currencyKey = 'BLUELAGOON_CURRENCY'
const rateKey = 'BLUELAGOON_EXCHANGE_RATE'
const defaultCurrency = 'ISK'
const DEFAULT_COUNTRY_CODE = 'IS'

const currencyIsAvailable = {}
availableCurrencies.forEach(currencyData => {
  currencyIsAvailable[currencyData.ISO] = true
})

const isValid = (timestamp: string) => {
  const limit = 4 // hours
  const now = new Date()
  const timeCreated = new Date(timestamp)

  return differenceInHours(now, timeCreated) < limit
}

const getExchangeRates = async () => {
  const exchangeRates = JSON.parse(
    localStorage.getItem(rateKey)
  ) as ExchangeRates // check localstorage

  if (exchangeRates && isValid(exchangeRates.timeCreated)) {
    return exchangeRates
  }

  try {
    const res = await axios.get<ExchangeRatesResponse>('/api/exchange-rates')
    const retrievalReferenceNumber =
      res.data?.mcp.retrievalReferenceNumber || null
    const rates = res.data?.rates
    const mcpRates = res.data?.mcp.rates

    if (rates && !isEmpty(rates) && retrievalReferenceNumber) {
      const exchangeRatesToLocalStorage = {
        rates,
        mcpRates,
        timeCreated: new Date().toISOString(),
        retrievalReferenceNumber,
      }
      localStorage.setItem(rateKey, JSON.stringify(exchangeRatesToLocalStorage))

      return exchangeRatesToLocalStorage
    }

    return null
  } catch {
    return null
  }
}

const getCurrency = async () => {
  const res = localStorage.getItem(currencyKey) // check localstorage
  const currency = res ? (JSON.parse(res) as Currency) : null

  // Return the localstorage currency if it is valid.
  if (currency && currency.countryCode && currency.ISO) {
    return { currency: currency.ISO, countryCode: currency.countryCode }
  }

  let currencyToUse = defaultCurrency
  let countryCodeToUse = DEFAULT_COUNTRY_CODE
  try {
    const userLocationInfo = await getUserLocationInfo()
    if (userLocationInfo && userLocationInfo.countryCode) {
      const { countryCode } = userLocationInfo
      countryCodeToUse = countryCode
      const countryCurrency = countryCodeCurrencyMap[countryCode]

      if (currencyIsAvailable[countryCurrency]) {
        currencyToUse = countryCurrency
      }

      // save new currency to localstorage
      localStorage.setItem(
        currencyKey,
        JSON.stringify({
          countryCode: countryCodeToUse,
          ISO: currencyToUse,
          timeCreated: new Date().toISOString(),
        })
      )
    } else {
      //set a default if getUserLocationInfo fails
      localStorage.setItem(
        currencyKey,
        JSON.stringify({
          countryCode: countryCodeToUse,
          ISO: currencyToUse,
          timeCreated: new Date().toISOString(),
        })
      )
    }
  } catch (error) {
    //set a default if getUserLocationInfo fails
    localStorage.setItem(
      currencyKey,
      JSON.stringify({
        countryCode: countryCodeToUse,
        ISO: currencyToUse,
        timeCreated: new Date().toISOString(),
      })
    )
    console.error(error)
  }

  return { currency: currencyToUse, countryCode: countryCodeToUse }
}

export const getCurrencyInformation = async () => {
  return await Promise.all([getCurrency(), getExchangeRates()])
}
