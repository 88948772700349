import { gql } from '@apollo/client'

import { RESTRICTION_FRAGMENT } from '../cart/fragments/restriction'

export const ROOM_TYPE_RESTRICTIONS = gql`
  ${RESTRICTION_FRAGMENT}
  query roomTypeRestrictions($input: RoomTypeRestrictionsInput!) {
    roomTypeRestrictions(input: $input) {
      ...RestrictionFragment
    }
  }
`

export const RATE_CODE_RESTRICTIONS = gql`
  ${RESTRICTION_FRAGMENT}
  query rateCodeRestrictions($input: RateCodeRestrictionsInput!) {
    rateCodeRestrictions(input: $input) {
      ...RestrictionFragment
    }
  }
`

export const RESTRICTIONS = gql`
  query restrictions($input: RestrictionsInput!) {
    restrictions(input: $input) {
      rateCodeRestriction
      roomTypeRestriction
      propertyRestriction
    }
  }
`
