import { NextPageContext } from 'next'
import { getCookie, setCookie } from 'cookies-next'
import { v4 as uuidv4 } from 'uuid'

const CORRELATION_ID_COOKIE_NAME = 'bl-cid'
const DEFAULT_EXPIRATION_TIME_IN_SECONDS = 24 * 60 * 60 // 24 hours

export const getCorrelationId = (context?: NextPageContext): string | null => {
  try {
    const correlationId = getCookie(
      CORRELATION_ID_COOKIE_NAME,
      context
    ) as string
    return correlationId ?? null
  } catch {
    return null
  }
}

export const getOrSetCorrelationIdCookie = (context?: NextPageContext) => {
  let correlationId = getCorrelationId(context)

  if (correlationId === null) {
    correlationId = uuidv4()

    const cookieOptions = {
      maxAge: DEFAULT_EXPIRATION_TIME_IN_SECONDS,
      path: '/',
    }

    setCookie(CORRELATION_ID_COOKIE_NAME, correlationId, {
      ...context,
      ...cookieOptions,
    })
  }

  return correlationId
}
