import { gql } from '@apollo/client'

export const CREATE_PAYMENT_TRANSACTION = gql`
  mutation CreatePaymentTransaction($input: TransactionInput!) {
    createPaymentTransaction(input: $input) {
      cart {
        ...CartFragment
      }
      transactionId
      paymentId
    }
  }
`
