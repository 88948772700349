import { gql } from '@apollo/client'

import { CART_FRAGMENT } from './fragments/cart'

export const ADD_PROMO_CODE_TO_CART = gql`
  ${CART_FRAGMENT}
  mutation AddPromoCodeToCart($input: AddPromoCodeInput!) {
    addPromoCode(input: $input) {
      ...CartFragment
    }
  }
`
