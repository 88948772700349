import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import fetch from 'cross-fetch'
import { v4 as uuidv4 } from 'uuid'

const DEFAULT_UUID = uuidv4()

let client: ApolloClient<NormalizedCacheObject>

export const initializeApiClient = (
  apiUrl: string,
  initialCorrelationId?: string,
  ignorePreviousClient = false
): ApolloClient<NormalizedCacheObject> => {
  if (client && !ignorePreviousClient) {
    return client
  }

  const contextLink = setContext((_, previousContext) => {
    const { correlationId: prevCorrelationId } = previousContext as {
      correlationId: string
    }

    return {
      headers: {
        'x-correlation-id':
          prevCorrelationId || initialCorrelationId || DEFAULT_UUID,
      },
    }
  })

  const httpLink = new HttpLink({
    uri: apiUrl,
    fetch,
  })

  client = new ApolloClient({
    link: contextLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return client
}
