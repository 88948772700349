import { gql } from '@apollo/client'

export const GIFTCARD_TDSM = gql`
  mutation validateGiftCardTDSM($info: ValidateGiftCardTDSMInfo!) {
    validateGiftCardTDSM(info: $info) {
      giftCardNo
      acsInfo {
        acsForm
        acsFormData {
          name
          value
        }
      }
    }
  }
`
