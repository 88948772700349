import { useContext } from 'react'
import { FocusOn } from 'react-focus-on'
import { useTranslation } from 'react-i18next'
import type { Variants } from 'framer-motion'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { ClientOnlyModal } from 'bl-common/src/units/ClientOnlyModal'

import { availableCurrencies } from '../constants/availableCurrencies'
import { colors } from '../constants/colors'
import { durations } from '../constants/durations'
import { zIndex } from '../constants/zIndex'
import { CurrencyContext } from '../context/Currency/CurrencyProvider'
import { Type } from '../elements/Typography/Typography'
import { useBreakpoints } from '../hooks/useBreakpoints'
import { media } from '../utils/media'
import { VisuallyHidden } from './VisuallyHidden'

type LanguageSelectorProps = {
  position?: 'top' | 'bottom'
  align?: 'left' | 'right'
  isOpen: boolean
  toggleOpen: () => void
  label?: string
  dynamicShards?: HTMLElement[]
}

type DialogProps = Pick<LanguageSelectorProps, 'position' | 'align'> & {
  $isOpen: boolean
}

const AVAILABLE_LANGUAGES = [
  { code: 'en', name: 'English', path: '/' },
  { code: 'is', name: 'Íslenska', path: '/is' },
]

const menu: Variants = {
  closed: { opacity: 0 },
  open: {
    opacity: 1,
    transition: { duration: 0.3 },
  },
}

const GlobeSvg = ({ className }: { className?: string }) => (
  <svg
    className={className}
    role="presentation"
    viewBox="0 0 20 20"
    width="20"
    height="20"
    fill="none"
    stroke="#454647"
    strokeWidth="1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.89712 19.1487c5.10968 0 9.25158-4.1419 9.25158-9.25158 0-5.10968-4.1419-9.25161-9.25158-9.25161C4.78744.6455.64551 4.78744.64551 9.89712c0 5.10968 4.14193 9.25158 9.25161 9.25158Z"></path>
    <path d="M9.78034 19.1487c2.24516 0 4.06456-4.1419 4.06456-9.25158 0-5.10968-1.8194-9.25161-4.06456-9.25161-2.24452 0-4.06452 4.14193-4.06452 9.25161 0 5.10968 1.82 9.25158 4.06452 9.25158Z"></path>
    <path d="M17.1742 3.72211a18.95472 18.95472 0 0 1-7.27739 1.23871A19.66477 19.66477 0 0 1 2.90326 3.8705m0 12.3161a19.3556 19.3556 0 0 1 6.99355-1.1355c2.21869-.0387 4.42839.2865 6.54189.9613M.36133 10.0447H19.4775M9.91617.4834V19.606"></path>
  </svg>
)

const CheckIcon = () => (
  <svg
    aria-hidden="true"
    width="13"
    height="11"
    viewBox="0 0 13 11"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.6831 0.169778C12.3254 -0.0985145 11.8132 -0.0334739 11.553 0.30799L5.21153 8.02344L1.43917 3.45433C1.15462 3.11287 0.642425 3.06409 0.292831 3.34864C0.122099 3.48685 0.0245384 3.68197 0.000148118 3.90149C-0.0242421 4.121 0.0407985 4.33238 0.17901 4.49498L5.23592 10.6169L12.8294 1.30799C12.9595 1.13726 13.0164 0.925876 12.992 0.706364C12.9676 0.486851 12.8538 0.299859 12.6831 0.169778Z" />
  </svg>
)

// Make GlobeIcon a styled component and available to be used in other components like in navigation
export const GlobeIcon = styled(GlobeSvg)``

const Container = styled.div`
  position: relative;
  display: inline-flex;
`

const Selector = styled.button`
  position: relative;
  display: flex;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    inset: -5px;
  }

  ${media.md(css`
    margin-top: 0;
  `)};
`

const SelectorGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[0.5]};
`

const Dialog = styled(motion.div)<DialogProps>`
  display: none;
  grid-template-columns: 1fr 1fr;
  background: var(--color-background);
  color: var(--color-text);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: ${({ theme }) => theme.spacing[2.5]};
  left: 0;
  z-index: ${zIndex.aboveMenu};

  ${media.md(css`
    min-width: 420px;
  `)}

  ${media.xl(css`
    min-width: 20vw;
  `)}

  ${({ position }) =>
    position === 'bottom' &&
    css`
      top: auto;
      bottom: ${({ theme }) => theme.spacing[2.5]};
    `}

  ${({ align }) =>
    align === 'right' &&
    css`
      left: auto;
      right: 0;
    `}

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      display: grid;
    `};
`

const DesktopDialog = styled.div`
  display: none;

  ${media.md(css`
    display: block;
  `)}
`

const MobileDialog = styled.div`
  display: block;
  padding: ${({ theme }) => `${theme.spacing[4]} 0`};

  ${media.md(css`
    display: none;
  `)}
`

const Items = styled.div`
  text-align: left;
  padding: ${({ theme }) => `${theme.spacing[1.5]} 0`};
`

const ColumnLabel = styled(Type)`
  padding: ${({ theme }) => `0 ${theme.spacing[2.5]} ${theme.spacing[1.5]}`};

  ${media.md(css`
    padding: ${({ theme }) => `0 ${theme.spacing[2]} ${theme.spacing[1]}`};
  `)}
`

const LinkButton = styled.a<{ isActive: boolean }>`
  display: grid;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2.5]}`};
  text-align: left;
  color: ${({ isActive }) => (isActive ? 'var(--color-link)' : 'inherit')};
  transition: background ${durations.short}ms;
  line-height: var(--line-height);
  cursor: pointer;

  ${media.md(css`
    padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
  `)}

  &:hover {
    color: var(--color-link);
    background: ${colors.lightGrey};
  }

  .t-dark &:hover {
    background: ${colors.darkmodeOffset};
  }
`

const Value = styled(Type)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[0.5]};
`

type MenuItemProps = {
  value: string
  isActive: boolean
  href?: string
  onClick?: () => void
  isButton?: boolean
  children?: React.ReactNode
}

const MenuItem = ({
  value,
  isActive,
  onClick,
  children,
  isButton,
  href,
}: MenuItemProps) => {
  return (
    <li>
      <LinkButton
        isActive={isActive}
        onClick={onClick}
        {...(isButton ? { as: 'button', 'aria-pressed': isActive } : { href })}
      >
        <Value preset="textLarge" weight="medium">
          {value}
          {isActive && <CheckIcon />}
        </Value>
        {children}
      </LinkButton>
    </li>
  )
}

const LocaleContent = ({ toggleOpen }: { toggleOpen: () => void }) => {
  const { t, i18n } = useTranslation()
  const { currency, changeCurrency } = useContext(CurrencyContext)

  const handleChangeCurrency = (iso: string) => {
    changeCurrency(iso)
    toggleOpen()
  }

  return (
    <>
      <Items>
        <ColumnLabel preset="label" textAlign="left">
          {t('language')}
        </ColumnLabel>
        <ul>
          {AVAILABLE_LANGUAGES.map(item => {
            const isActive = item.code === i18n.language
            return (
              <MenuItem
                key={item.code}
                value={item.name}
                href={item.path}
                isActive={isActive}
              />
            )
          })}
        </ul>
      </Items>
      <Items>
        <ColumnLabel preset="label">{t('currency')}</ColumnLabel>
        <ul>
          {availableCurrencies.map(item => {
            const isActive = item.ISO === currency
            return (
              <MenuItem
                key={item.name}
                value={item.ISO}
                isButton
                isActive={isActive}
                onClick={() => handleChangeCurrency(item.ISO)}
              >
                <Type preset="textSmall">{t(item.id)}</Type>
              </MenuItem>
            )
          })}
        </ul>
      </Items>
    </>
  )
}

export const LocaleSwitcher = ({
  position = 'top',
  align = 'left',
  isOpen,
  toggleOpen,
  label,
  dynamicShards = [],
}: LanguageSelectorProps) => {
  const { isMobile } = useBreakpoints()

  return (
    <>
      <Container>
        <>
          <Selector onClick={toggleOpen} role="button">
            <VisuallyHidden>Select language and currency</VisuallyHidden>
            <SelectorGroup>
              <GlobeIcon />
              {!!label && <Type preset="labelSmall">{label}</Type>}
            </SelectorGroup>
          </Selector>
          <FocusOn
            enabled={isOpen && !isMobile}
            autoFocus={false}
            returnFocus
            onClickOutside={toggleOpen}
            onEscapeKey={toggleOpen}
            onDeactivation={toggleOpen}
          >
            <DesktopDialog>
              <Dialog
                $isOpen={isOpen}
                aria-expanded={isOpen}
                variants={menu}
                initial="closed"
                exit="closed"
                animate={isOpen ? 'open' : 'closed'}
                position={position}
                align={align}
              >
                <LocaleContent toggleOpen={toggleOpen} />
              </Dialog>
            </DesktopDialog>
          </FocusOn>
        </>
      </Container>
      <ClientOnlyModal
        show={isOpen && isMobile}
        onHide={toggleOpen}
        backgroundColor="var(--color-background)"
        closeButtonColor="var(--color-text)"
        animateDirection="bottom"
        shards={dynamicShards}
      >
        <MobileDialog>
          <LocaleContent toggleOpen={toggleOpen} />
        </MobileDialog>
      </ClientOnlyModal>
    </>
  )
}
