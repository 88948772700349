import { gql } from '@apollo/client'

import { CART_FRAGMENT } from './fragments/cart'

export const GET_CART = gql`
  ${CART_FRAGMENT}
  query GetCart($input: GetCartInput!) {
    getCart(input: $input) {
      ...CartFragment
    }
  }
`
