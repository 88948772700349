import { createContext } from 'react'

type GetFunc = (_?: any) => any
type SetFunc = (_?: any, __?: any) => any

export const PageStateContext = createContext({
  key: null,
  getState: (() => null) as GetFunc,
  setState: (() => {}) as SetFunc,
})
