export const getCookie = (name: string) => {
  if (typeof document === 'undefined') {
    return null
  }
  const cookies = document.cookie.split('; ')
  const cookie = cookies.find(row => row.startsWith(name + '='))
  return cookie ? cookie.split('=')[1] : null
}

export const setCookie = (name: string, value: string) => {
  document.cookie = name + '=' + value + '; path=/'
}

export const areCookiesEnabled = () => {
  if (typeof navigator === 'undefined') {
    return false
  }
  return navigator.cookieEnabled
}
