import { gql } from '@apollo/client'

export const GET_TRANSPORTATION_DATA = gql`
  query transportationData {
    transportationData {
      airport {
        id
        name
      }
      prices {
        adultPrice
      }
    }
  }
`
