// This has to be imported at global level because of projects using NextJS
import 'react-phone-input-2/lib/style.css'
import 'pure-react-carousel/dist/react-carousel.es.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'tippy.js/dist/tippy.css'
import 'swiper/css'
import 'swiper/css/pagination'

import { I18nextProvider } from 'react-i18next'
import { ParallaxProvider } from 'react-scroll-parallax'
import type { AppProps } from 'next/app'
import NextApp from 'next/app'
import { withRouter } from 'next/router'
import { ThemeProvider as LightOrDarkThemeProvider } from 'next-themes'
import { ApolloProvider } from '@apollo/client'
import { ErrorBoundary } from '@sentry/nextjs'
import { SpeedInsights } from '@vercel/speed-insights/next'
import { ThemeProvider } from 'styled-components'

import { BookingSessionContextProvider } from 'bl-common/src/context/BookingSessionContext'
import { CurrencyProvider } from 'bl-common/src/context/Currency/CurrencyProvider'
import { ErrorProvider } from 'bl-common/src/context/ErrorContext'
import { theme } from 'bl-common/src/styles/theme'

import { ApolloSingleton } from 'utils/apolloSingleton'
import { UrqlClientProvider } from 'utils/UrqlClientProvider'
import { withCorrelationId } from 'utils/withCorrelationId'

import { BannerHeightContextProvider } from '../components/BannerHeight'
import { BreadcrumbManager } from '../components/BreadcrumbLink'
import { Layout } from '../components/Layout'
import { RouterProvider } from '../components/RouterProvider'
import { SiteConfigContext } from '../components/SiteConfig'
import withPosthog from '../components/withPosthog'
import { geti18n } from '../i18n/i18n'
import { GlobalStyles } from '../styles/global'
import { GlobalThemeVariables } from '../styles/themeVariables'
import { DynamicShardsProvider } from '../utils/DynamicShardsContext'
import { fetchSiteConfig } from '../utils/fetchSiteConfig'
import { withNavigationService } from '../utils/withNavigationService'
import Error from './_error'

interface BLAppProps extends AppProps {
  siteConfig?: any // Note: This is ISiteConfigFields, but footer optional is causing problems, fix later
  correlationId?: string
}

// Routes to send to Speed Insights
const exactPaths = ['/', '/is']
const prefixPaths = ['/book', '/is/boka', '/day-visit', '/accommodation']

class App extends NextApp<BLAppProps> {
  i18n
  static getInitialProps = async ({ Component, ctx }) => {
    const [siteConfig, pageProps = {}] = await Promise.all([
      fetchSiteConfig(ctx.asPath),
      Component.getInitialProps ? Component.getInitialProps(ctx) : {},
    ])
    return { siteConfig, pageProps }
  }

  constructor(props) {
    super(props)
    this.i18n = geti18n(this.props.siteConfig.language)
    ApolloSingleton.createInstance(
      process.env.NEXT_PUBLIC_GRAPHQL_URL,
      props.correlationId
    )
  }

  componentDidUpdate(props) {
    const oldLanguage = this.props.siteConfig.language
    const newLanguage = props.siteConfig.language
    if (newLanguage !== oldLanguage) {
      this.i18n.changeLanguage(newLanguage)
      if (document) {
        document.documentElement.lang = newLanguage
      }
    }
  }

  render() {
    const { Component, siteConfig, pageProps } = this.props

    const { alertBanners, notifications, bannerColor } = siteConfig

    const sampleRate =
      Number(process.env.NEXT_PUBLIC_VERCEL_SPEEDINSIGHTS_SAMPLERATE) ?? 0
    const isDarkMode =
      pageProps?.isDarkMode ||
      pageProps?.page?.fields?.config?.fields?.isDarkMode

    return (
      <>
        <ErrorBoundary
          fallback={() => <Error />}
          beforeCapture={scope =>
            scope.setTag('correlationId', this.props.correlationId)
          }
        >
          <I18nextProvider i18n={this.i18n}>
            <UrqlClientProvider>
              <ApolloProvider client={ApolloSingleton.getInstance().client}>
                <ParallaxProvider>
                  <ThemeProvider theme={theme}>
                    <LightOrDarkThemeProvider
                      enableColorScheme={false}
                      enableSystem={false}
                      attribute="class"
                      value={{ light: 't-light', dark: 't-dark' }}
                      defaultTheme="light"
                      forcedTheme={isDarkMode ? 'dark' : null}
                    >
                      <BreadcrumbManager>
                        <RouterProvider>
                          <SiteConfigContext.Provider value={siteConfig}>
                            <BannerHeightContextProvider>
                              <DynamicShardsProvider>
                                <CurrencyProvider
                                  language={siteConfig.language}
                                >
                                  <ErrorProvider>
                                    <BookingSessionContextProvider>
                                      <GlobalThemeVariables />
                                      <GlobalStyles />
                                      <Layout
                                        alertBanners={alertBanners}
                                        bannerColor={bannerColor}
                                        notifications={notifications}
                                      >
                                        <Component {...pageProps} />
                                      </Layout>
                                    </BookingSessionContextProvider>
                                  </ErrorProvider>
                                </CurrencyProvider>
                              </DynamicShardsProvider>
                            </BannerHeightContextProvider>
                          </SiteConfigContext.Provider>
                        </RouterProvider>
                      </BreadcrumbManager>
                    </LightOrDarkThemeProvider>
                  </ThemeProvider>
                </ParallaxProvider>
              </ApolloProvider>
            </UrqlClientProvider>
          </I18nextProvider>
        </ErrorBoundary>
        <SpeedInsights
          sampleRate={sampleRate}
          beforeSend={data => {
            const urlObj = new URL(data.url)
            const pathname = urlObj.pathname

            const isExactMatch = exactPaths.some(path => pathname === path)
            const isPrefixMatch = prefixPaths.some(path =>
              pathname.startsWith(path)
            )
            if (isExactMatch || isPrefixMatch) {
              return data
            }
            return null
          }}
        />
      </>
    )
  }
}

export default withCorrelationId(
  withNavigationService(withPosthog(withRouter(App)))
)
