/* eslint-disable @typescript-eslint/unbound-method */
import { Component, createContext } from 'react'
import isEmpty from 'lodash/isEmpty'

import { ClientOnlyModal as Modal } from '../../units/ClientOnlyModal'
import { LightBox } from './LightBox'

type LightBoxProviderState = {
  images?: any
  currentImage?: any
  currentIndex: number
  showModal: boolean
}

const LightBoxContext = createContext({
  openLightBox: () => {},
  registerImage: () => {},
})

export class LightBoxProvider extends Component<any, LightBoxProviderState> {
  constructor(props) {
    super(props)
    this.state = {
      currentImage: null,
      images: [],
      showModal: false,
      currentIndex: 0,
    }
    this.openLightBox = this.openLightBox.bind(this)
    this.closeLightBox = this.closeLightBox.bind(this)
    this.registerImage = this.registerImage.bind(this)
  }

  openLightBox(image = undefined) {
    const { images } = this.state
    const currIndex =
      (image &&
        image.sys &&
        images.findIndex(curr => curr.sys.id === image.sys.id)) ||
      0
    this.setState(() => ({
      currentImage: images[currIndex],
      currentIndex: currIndex,
      showModal: true,
    }))
  }

  registerImage(image = undefined) {
    const items = Array.isArray(image) ? image : [image]
    this.setState(prevState => ({
      images: [...prevState.images, ...items],
    }))
  }

  closeLightBox() {
    this.setState(() => ({
      currentImage: null,
      currentIndex: 0,
      showModal: false,
    }))
  }

  render() {
    const { children } = this.props

    return (
      <LightBoxContext.Provider
        value={{
          openLightBox: this.openLightBox,
          registerImage: this.registerImage,
        }}
      >
        <>
          {children}
          <Modal
            show={this.state.showModal}
            onHide={this.closeLightBox}
            noWrapper
          >
            {!isEmpty(this.state.images) && (
              <LightBox
                images={this.state.images}
                initialIndex={this.state.currentIndex}
                onClose={this.closeLightBox}
              />
            )}
          </Modal>
        </>
      </LightBoxContext.Provider>
    )
  }
}

export const LightBoxConsumer = LightBoxContext.Consumer
