import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { initializeApiClient } from 'bl-graphql'

export class ApolloSingleton {
  public client: ApolloClient<NormalizedCacheObject>
  private static instance: ApolloSingleton

  private constructor(apiUrl: string, correlationId?: string) {
    this.client = initializeApiClient(apiUrl, correlationId, true)
  }

  public static createInstance(
    apiUrl: string,
    correlationId?: string
  ): ApolloSingleton {
    ApolloSingleton.instance = new ApolloSingleton(apiUrl, correlationId)
    return ApolloSingleton.instance
  }
  public static getInstance(): ApolloSingleton {
    if (!ApolloSingleton.instance) {
      ApolloSingleton.instance = new ApolloSingleton(
        process.env.NEXT_PUBLIC_GRAPHQL_URL,
        undefined
      )
    }
    return ApolloSingleton.instance
  }
}
