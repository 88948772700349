import { useContext } from 'react'
import dynamic from 'next/dynamic'
import { rgba } from 'polished'
import { createGlobalStyle } from 'styled-components'

import { colors } from '../constants/colors'
import { CurrencyContext } from '../context/Currency/CurrencyProvider'
import { theme } from '../styles/theme'
import { PartialBookingEngine } from '../styles/types'
import { mediaObj } from '../utils/media'

const PhoneNumberInput = dynamic(() => import('react-phone-input-2'), {
  ssr: false,
})

// This has been moved to the global level of each project using bl-common, in the future we might want to remove this package
// TODO fix not being able to import: https://github.com/vercel/next.js/issues/19936
// import 'react-phone-input-2/lib/style.css'

const preferredCountries = ['us', 'gb', 'is']
const DEFAULT_COUNTRY = 'is'

type PhoneInputProps = {
  id?: string
  name?: string
  value?: any
  placeholder?: string
  hasError?: boolean
  readOnly?: boolean
  onChange?: (val?: any) => any
  selectedCode?: any
  themeStyle?: PartialBookingEngine['phoneInputField']['input']
  gap: string
  onEnterKeyPress?: () => void
}

const PhoneNumberInputCSS = createGlobalStyle<{
  themeStyle?: PhoneInputProps['themeStyle']
  gap: string
}>`
  .PhoneNumberInput_container {
    position: relative;
    width: 100%;
    padding-bottom: ${theme.spacing[3]};
  }

  .react-tel-input {
    font-family: inherit;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    gap:${props => props.gap};


  .form-control {
    background: ${props => props?.themeStyle?.backgroundColor ?? colors.white};
    border: 1px solid ${props =>
      props?.themeStyle?.borderColor ?? colors.formGrey};
    border-radius: ${props => props?.themeStyle?.borderRadius ?? 0}px;
    color: ${props => props?.themeStyle?.textColor ?? colors.formDark};
    font-size: 16px;
    height: 56px;
    outline: none;
    padding: ${theme.spacing[0.5]} ${theme.spacing[1]};
    flex: 1 1 80%;
    -webkit-appearance: none;
    transition: box-shadow 150ms, border 150ms;


    ::placeholder {
      opacity: 0.5;
    }

    :hover {
      border: 1px solid ${props =>
        props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue};
      box-shadow: 0 0 12px
      ${props =>
        rgba(props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};

    }

    :focus {
      border: 1px solid ${props =>
        props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue};
      box-shadow: 0 0 12px
      ${props =>
        rgba(props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};
      background: ${props =>
        props?.themeStyle?.backgroundColor ?? colors.white};
    }

    &[aria-invalid="true"]{
      background: ${rgba(colors.errorRed, 0.15)};
      border: 1px solid ${colors.errorRed};

      &:hover,
      &:focus {
        box-shadow: 0 0 12px
      ${props =>
        rgba(props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};
      }
    }

    :read-only {
      color: transparent;
      text-shadow: 0 0 0 ${colors.formDark};
      background: ${colors.lightGrey} !important;
    }
  }

  .flag-dropdown {
    background: ${props => props?.themeStyle?.backgroundColor ?? colors.white};
    border: 1px solid ${props =>
      props?.themeStyle?.borderColor ?? colors.formGrey};
    position: relative;
    order: -1;
    flex: 0 0 ${theme.spacing[7]};

    border-radius: ${props => props?.themeStyle?.borderRadius ?? 0}px;

    ${mediaObj.bmd} {
      flex: 0 0 ${theme.spacing[5]};
    }

    &:hover {
      border: 1px solid ${props =>
        props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue};
      box-shadow: 0 0 12px
      ${props =>
        rgba(props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};
        }

    .selected-flag {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0;
        width: 100%;
        border-radius: ${props => props?.themeStyle?.borderRadius ?? 0}px;
        background: ${props =>
          props?.themeStyle?.backgroundColor ?? colors.white};



      .flag {
          margin-left: -10px;
        }

      .arrow, .arrow.up {
          margin-left: 5px;
          border-top-color: ${props => props?.themeStyle?.arrowColor ?? 'auto'};
          border-bottom-color: ${props => props?.themeStyle?.arrowColor};
        }
    }
    }

     .flag-dropdown.open {
      border-radius: 0px;
      background: ${props =>
        props?.themeStyle?.backgroundColor ?? colors.white};

      .selected-flag {
        background: ${props =>
          props?.themeStyle?.backgroundColor ?? colors.white};
      }
     }

      .PhoneNumberInputDisabled .flag-dropdown {
        text-shadow: 0 0 0 ${colors.formDark};
        background: ${colors.lightGrey};
      }

      .country-list {
        background-color: ${props => props?.themeStyle?.backgroundColor};

        .country:hover {
          background-color: ${props => props?.themeStyle?.hoverCountryColor};
        }

        .country.highlight {
          background-color: ${props => props?.themeStyle?.hoverCountryColor};
        }
      }
 }
`

export const PhoneInput = ({
  id,
  name,
  value = '',
  placeholder = 'phone number',
  hasError = false,
  readOnly = false,
  onChange = () => {},
  selectedCode = null,
  themeStyle = undefined,
  gap = theme.spacing[2],
  ...rest
}: PhoneInputProps) => {
  const { countryCode } = useContext(CurrencyContext)
  const handleChange = value => {
    if (value.substr(0, 1) !== '+') {
      value = '+' + value
    }
    onChange(name)(value)
  }

  const country = selectedCode
    ? selectedCode.toLowerCase()
    : countryCode
      ? countryCode.toLowerCase()
      : DEFAULT_COUNTRY

  return (
    <>
      <PhoneNumberInputCSS themeStyle={themeStyle} gap={gap} />

      <div className={readOnly ? 'PhoneNumberInputDisabled' : undefined}>
        <PhoneNumberInput
          onlyCountries={readOnly ? [country] : undefined}
          inputProps={{
            id,
            name,
            'aria-invalid': hasError,
            readOnly,
          }}
          onChange={handleChange}
          value={value}
          country={country}
          placeholder={placeholder}
          preferredCountries={[country, ...preferredCountries]}
          disabled={readOnly}
          {...rest}
        />
      </div>
    </>
  )
}
