import { css } from 'styled-components'

// we can't use colors reference from bl-common, for some reason it returns ',#3a7e8d,' which doesn't work
const link = css`
  color: var(--color-link);
  font-weight: 600;
  .t-dark & {
    color: #91d6e5;
  }
`

export const formatHtmlText = (
  string: string,
  linkStyle = link,
  linkTarget = '_blank'
) =>
  string &&
  String(string)
    .replace(/\*([^*]+)\*/g, '<b>$1</b>')
    .replace(/_([^_]+)_/g, '<i>$1</i>')
    .replace(
      /\[([^[]+)\]\(([^)]+)\)/g,
      `<a target="${linkTarget}" style="${linkStyle}" rel="noopener noreferrer" href="$2">$1</a>`
    )
