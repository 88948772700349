import { Component, createContext } from 'react'
import head from 'lodash/head'
import tail from 'lodash/tail'

type EscapeStackItem = (event: unknown) => void

type EscapeStackContextInterface = {
  push: (item: EscapeStackItem) => void
  pop: () => void
}

export const EscapeStackContext = createContext<EscapeStackContextInterface>({
  push: () => {},
  pop: () => {},
})

export class EscapeStackProvider extends Component<
  { children?: React.ReactNode },
  { stack: Array<EscapeStackItem> }
> {
  constructor(props) {
    super(props)
    this.state = {
      stack: [],
    }
  }

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyUp)
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyUp)
  }

  onKeyUp = event => {
    if (event.key === 'Escape') {
      const popped = head(this.state.stack)
      if (popped) {
        popped(event)
      }
    }
  }

  push = item =>
    this.setState(prevState => ({ stack: [item, ...prevState.stack] }))

  pop = () => {
    this.setState({ stack: tail(this.state.stack) })
  }

  render() {
    const { children } = this.props
    return (
      <EscapeStackContext.Provider value={{ push: this.push, pop: this.pop }}>
        {children}
      </EscapeStackContext.Provider>
    )
  }
}
