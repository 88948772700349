import { gql } from '@apollo/client'

import { HOTEL_ROOM_CART_ITEM_FRAGMENT } from './fragments/cart'

export const UPDATE_HOTEL_ROOM_CART_ITEM = gql`
  ${HOTEL_ROOM_CART_ITEM_FRAGMENT}
  mutation UpdateHotelRoomCartItem($input: UpdateHotelRoomCartItemInput!) {
    updateHotelRoomCartItem(input: $input) {
      ...HotelRoomCartItem
    }
  }
`
