import { Fragment, useContext, useState } from 'react'
import { FocusOn } from 'react-focus-on'

import { MobileNav } from 'bl-common/src/units/Navigation/MobileNav'
import { Navigation as DesktopNavigation } from 'bl-common/src/units/Navigation/Navigation'

import { useDynamicShards } from 'utils/DynamicShardsContext'

import { BannerHeightContext } from './BannerHeight'
import { SiteConfig } from './SiteConfig'

interface BaseProps {
  isTransparent: boolean
  isWhiteColored: boolean
  hasSubnavigation: boolean
  hideNavigation?: boolean
}

// The props that are provided to the exported Component.
interface Props extends BaseProps {
  navigation?: any
}

// The props that are provided to the internal Nav Component.
interface NavProps extends BaseProps {
  navigation?: any
  bannerHeight: number
}

const Nav = ({
  isTransparent,
  isWhiteColored,
  navigation,
  bannerHeight,
  hasSubnavigation,
  hideNavigation,
}: NavProps) => {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState<boolean>(false)
  const dynamicShards = useDynamicShards()

  if (!navigation) {
    return null
  }

  return (
    <Fragment>
      <DesktopNavigation
        isTransparent={isTransparent}
        isWhiteColored={isWhiteColored}
        navigationData={navigation.fields.menu}
        homeUrl={navigation.fields.iconUrl}
        openMobileNav={() => setMobileNavIsOpen(prevState => !prevState)}
        bannerHeight={bannerHeight}
        hasSubnavigation={hasSubnavigation}
        hideNavigation={hideNavigation}
        dynamicShards={dynamicShards}
      />

      <FocusOn enabled={mobileNavIsOpen} returnFocus shards={dynamicShards}>
        <MobileNav
          isWhiteColored={isWhiteColored}
          navigationData={navigation.fields.menu}
          isOpen={mobileNavIsOpen}
          onClose={() => setMobileNavIsOpen(false)}
          homeUrl={navigation.fields.iconUrl}
        />
      </FocusOn>
    </Fragment>
  )
}

export const Navigation = ({
  navigation: customNavigation,
  ...props
}: Omit<Props, 'bannerHeight'>) => {
  const { bannerHeight } = useContext(BannerHeightContext)
  return (
    <SiteConfig>
      {({ navigation: navigation }) => (
        <Nav
          {...props}
          navigation={customNavigation || navigation}
          bannerHeight={bannerHeight}
        />
      )}
    </SiteConfig>
  )
}
