import { gql } from '@apollo/client'

export const PRODUCTS = gql`
  query products {
    products {
      productId
      productType
      productSubType
      fullName
      displayName
      company
      property
      location
      price
      fixedDiscountPercentage
      discountedPrice
      availabilityBasedPricing
    }
  }
`
