import { Component } from 'react'
import { findDOMNode } from 'react-dom'

import { Observer as BaseObserver } from './lib/Observer'

interface Props<T> {
  defaultValue: T
  value: T
  debug?: boolean
  onChange?: (value: boolean) => void
  children: JSX.Element | ((value: T) => React.ReactNode)
}

interface State<T> {
  value: T
}

/**
 * @deprecated Use react-ui-observer
 */
export class Observer<T> extends Component<Props<T>, State<T>> {
  static defaultProps = {
    defaultValue: false,
  }
  state = {
    value: this.props.defaultValue,
  }

  private observer: BaseObserver

  componentDidMount() {
    this.observer = new BaseObserver(this.props.value, {
      debug: this.props.debug,
      onChange: this.onChange,
      context: {
        // eslint-disable-next-line react/no-find-dom-node
        rootElement: findDOMNode(this),
      },
    })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.observer.update(newProps.value)
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.dispose()
    }
  }

  onChange = value => {
    this.setState({ value })
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  render() {
    const { children } = this.props
    if (typeof children === 'function') {
      return children(this.state.value)
    }
    return children
  }
}
