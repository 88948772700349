import type { Entry, EntryCollection } from 'contentful'
import { createClient } from 'contentful'

import { sentryLogging } from 'bl-utils/src/sentryUtils'

const config = {
  spaceId: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE || 'w65k7w0nsb8q',
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT || 'master',
  accessToken:
    process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN ||
    'b061d6add27f9259b4adfcdde338e0f3fcb18e75eff1b0e4454415a103884a85',
  host: process.env.NEXT_PUBLIC_CONTENTFUL_HOST || 'cdn.contentful.com',
}

const client = createClient({
  space: config.spaceId,
  accessToken: config.accessToken,
  host: config.host,
  removeUnresolved: true,
  environment: config.environment,
})

// In search we specifically want unresolved link models, for data optimization.
export const searchClient = createClient({
  space: config.spaceId,
  accessToken: config.accessToken,
  host: config.host,
  environment: config.environment,
})

// Check if configuration is valid.
client.getSpace().catch(() => {
  // eslint-disable-next-line no-console
  console.error('Invalid configuration for Contentful. Could not get space.')
})

export const getEntriesWrapper = async <T>(
  query?: any
): Promise<EntryCollection<T> | null> => {
  try {
    return await client.getEntries<T>(query)
  } catch (error) {
    const contentType = query?.content_type || 'unknown'
    sentryLogging({
      error: new Error(
        `Contentful: Error when fetching entries: ${contentType}`
      ),
      extras: { error, query },
    })
    return null
  }
}

export const getEntryWrapper = async <T>(
  id: string,
  query?: any
): Promise<Entry<T> | null> => {
  try {
    return await client.getEntry<T>(id, query)
  } catch {
    sentryLogging({
      error: new Error(`Contentful: Error when fetching entry ${id}`),
      extras: { query },
    })
    return null
  }
}

export default client
