import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  JSON: any
}

export type AcsFormItem = {
  __typename?: 'AcsFormItem'
  name: Scalars['String']
  value?: Maybe<Scalars['String']>
}

export type AcsResponse = {
  __typename?: 'AcsResponse'
  acsForm: Scalars['String']
  acsFormData?: Maybe<Array<Maybe<AcsFormItem>>>
}

export type ActivityExtraInfo = {
  __typename?: 'ActivityExtraInfo'
  adultPrice?: Maybe<Scalars['Int']>
  departureDate?: Maybe<Scalars['DateTime']>
  departureTime?: Maybe<Scalars['String']>
  dropoffLocationID?: Maybe<Scalars['String']>
  dropoffLocationName?: Maybe<Scalars['String']>
  pickupLocationID?: Maybe<Scalars['String']>
  pickupLocationName?: Maybe<Scalars['String']>
  pickupTime?: Maybe<Scalars['String']>
}

export type ActivityItem = {
  __typename?: 'ActivityItem'
  discountedPrice: Scalars['Int']
  displayName?: Maybe<Scalars['String']>
  fields?: Maybe<Scalars['JSON']>
  fullName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  linePrice: Scalars['Int']
  meta?: Maybe<ActivityMeta>
  price: Scalars['Int']
  productId: Scalars['ID']
  qty: Scalars['Int']
  totalLinePrice: Scalars['Int']
  type: CartItemType
}

export type ActivityLine = {
  __typename?: 'ActivityLine'
  bookingRef: Scalars['String']
  comment: Scalars['String']
  data?: Maybe<ActivityExtraInfo>
  lineNo: Scalars['String']
  numberOfPersons: Scalars['Int']
  productNo: Scalars['String']
  quantity: Scalars['Int']
  reservationDate: Scalars['DateTime']
  status: Scalars['String']
  subStatus: Scalars['String']
}

export type ActivityMeta = {
  __typename?: 'ActivityMeta'
  arrivalTime?: Maybe<Scalars['DateTime']>
  comment?: Maybe<Scalars['String']>
  noOfPersons?: Maybe<Scalars['Int']>
}

export type ActivityProductAvailabilityInput = {
  from: Scalars['String']
  isMembership?: InputMaybe<Scalars['Boolean']>
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  to: Scalars['String']
  type: CartType
}

export type AddGiftCardInput = {
  cartId: Scalars['ID']
  giftCard: Scalars['String']
  type: CartType
}

export type AddHotelRoomToCartInput = {
  cartId: Scalars['ID']
  hotelBookingRef?: InputMaybe<Scalars['ID']>
  locale?: InputMaybe<Scalars['String']>
  meta?: InputMaybe<HotelMetaInput>
  productId: Scalars['ID']
  qty?: Scalars['Int']
  type?: InputMaybe<CartType>
}

export type AddMembershipTokenInput = {
  cartId: Scalars['ID']
  membershipToken: Scalars['String']
  type: CartType
}

export type AddProductToCartInput = {
  cartId: Scalars['ID']
  hotelBookingRef?: InputMaybe<Scalars['ID']>
  locale?: InputMaybe<Scalars['String']>
  meta?: InputMaybe<UpdateHotelMetaInput>
  productId: Scalars['ID']
  qty?: Scalars['Int']
  type?: InputMaybe<CartType>
}

export type AddPromoCodeInput = {
  cartId: Scalars['ID']
  promoCode: Scalars['String']
  type: CartType
}

export type AdditionalInfo = {
  __typename?: 'AdditionalInfo'
  activityId?: Maybe<Scalars['Int']>
  activityName?: Maybe<Scalars['String']>
  adultPrice?: Maybe<Scalars['Int']>
  childPrice?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  teenagerPrice?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
}

export type AdmissionItem = {
  __typename?: 'AdmissionItem'
  discountedPrice: Scalars['Int']
  displayName?: Maybe<Scalars['String']>
  fields?: Maybe<Scalars['JSON']>
  fullName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  linePrice: Scalars['Int']
  meta?: Maybe<AdmissionItemMeta>
  price: Scalars['Int']
  productId: Scalars['ID']
  productType?: Maybe<Scalars['String']>
  qty: Scalars['Int']
  totalLinePrice: Scalars['Int']
  type: CartItemType
}

export type AdmissionItemMeta = {
  __typename?: 'AdmissionItemMeta'
  arrivalTime?: Maybe<Scalars['DateTime']>
  noOfPersons?: Maybe<Scalars['Int']>
}

export type AdmissionMetaInput = {
  arrivalTime: Scalars['String']
  hotelBookingRef?: InputMaybe<Scalars['String']>
}

export type AvailabilityDetails = {
  __typename?: 'AvailabilityDetails'
  availability?: Maybe<Scalars['Int']>
  time?: Maybe<Scalars['String']>
}

export type Booking = {
  __typename?: 'Booking'
  acsInfo?: Maybe<AcsResponse>
  booking?: Maybe<UpdatedBooking>
  bookingId: Scalars['String']
  tdsmInfo?: Maybe<TdsmInfo>
}

export type BookingInformation = {
  adultPrice: Scalars['Int']
  arrivalDate: Scalars['DateTime']
  arrivalTime: Scalars['String']
  bookingId?: InputMaybe<Scalars['String']>
  busTransfer?: InputMaybe<Transportation>
  correlationId: Scalars['String']
  customerInformation?: InputMaybe<CustomerInformation>
  giftCardNo?: InputMaybe<Scalars['String']>
  guests: GuestCount
  massage?: InputMaybe<Array<InputMaybe<Massage>>>
  membershipValidationToken?: InputMaybe<Scalars['String']>
  packageType: Scalars['String']
  products?: InputMaybe<Array<InputMaybe<ProductInput>>>
  promoCode?: InputMaybe<Scalars['String']>
  reservation?: InputMaybe<RestaurantReservation>
}

export type BookingMembership = {
  __typename?: 'BookingMembership'
  membershipRef: Scalars['String']
}

export type BusStop = {
  __typename?: 'BusStop'
  address?: Maybe<Scalars['String']>
  id: Scalars['String']
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  name: Scalars['String']
  number?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
}

export type CardHolder = {
  __typename?: 'CardHolder'
  email: Scalars['String']
  firstName: Scalars['String']
  id?: Maybe<Scalars['String']>
  lastName: Scalars['String']
  personalId: Scalars['String']
  phoneNumber: Scalars['String']
}

export type CardHolderInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  id?: InputMaybe<Scalars['String']>
  lastName: Scalars['String']
  personalId: Scalars['String']
  phoneNumber: Scalars['String']
}

export type Cart = {
  __typename?: 'Cart'
  currency: Scalars['String']
  customer?: Maybe<CustomerInformationType>
  discountedAmount: Scalars['Int']
  giftCardAmount: Scalars['Int']
  giftCards?: Maybe<Array<Maybe<GiftCardItem>>>
  id: Scalars['ID']
  isValid?: Maybe<Scalars['Boolean']>
  items: Array<CartItem>
  membership?: Maybe<Membership>
  paymentAmount?: Maybe<Scalars['Int']>
  paymentRequired: Scalars['Boolean']
  promoCode?: Maybe<Scalars['String']>
  refundAmount: Scalars['Int']
  totalAmount: Scalars['Int']
}

export type CartItem =
  | ActivityItem
  | AdmissionItem
  | HotelRoomItem
  | PrivateTransferItem
  | ProductItem
  | TransportationItem

export enum CartItemType {
  Activity = 'Activity',
  Admission = 'Admission',
  Hotel = 'Hotel',
  Item = 'Item',
  Massage = 'Massage',
  PrivateTransfer = 'PrivateTransfer',
  Restaurant = 'Restaurant',
  Transfer = 'Transfer',
  Unknown = 'Unknown',
}

export enum CartType {
  Dayspa = 'dayspa',
  Hotel = 'hotel',
}

export type CheckMembershipTdsmInfo = {
  correlationId: Scalars['String']
  membershipRef: Scalars['String']
}

export type CheckMembershipTdsmResponse = {
  __typename?: 'CheckMembershipTdsmResponse'
  acsInfo?: Maybe<AcsResponse>
  membershipRef: Scalars['String']
}

export type CheckoutInput = {
  cartId: Scalars['ID']
  children?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  comments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  otherGuests?: InputMaybe<Array<InputMaybe<Guest>>>
  payment: PaymentDetailsInput
  primaryGuest: PrimaryGuest
  type: CartType
}

export type CheckoutPaymentDetail = {
  __typename?: 'CheckoutPaymentDetail'
  bookingNumber?: Maybe<Scalars['String']>
}

export type CheckoutPaymentInput = {
  cartId: Scalars['ID']
  customer: CustomerInformation
  paymentId?: InputMaybe<Scalars['Int']>
  transactionId?: InputMaybe<Scalars['String']>
  type: CartType
}

export type CheckoutStatus = {
  __typename?: 'CheckoutStatus'
  bookingRef?: Maybe<Scalars['String']>
  busTransferSuccessful: Scalars['Boolean']
  confirmSuccessful: Scalars['Boolean']
  error?: Maybe<Scalars['String']>
  finished?: Maybe<Scalars['String']>
  inProgress: Scalars['Boolean']
  orderSuccessful: Scalars['Boolean']
  paymentSuccessful: Scalars['Boolean']
}

export type CheckoutStatusInput = {
  cartId: Scalars['String']
  type: CartType
}

export type ConfirmedBooking = {
  bookingInformation: BookingInformation
  paymentInformation?: InputMaybe<PaymentInformation>
  requestOrigin?: InputMaybe<RequestOrigin>
}

export type CreateCartInput = {
  type: CartType
}

export type CreateGiftCardResponse = {
  __typename?: 'CreateGiftCardResponse'
  giftCardNo: Scalars['String']
}

export type CustomerInfo = {
  __typename?: 'CustomerInfo'
  country?: Maybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  newsletter?: Maybe<Scalars['Boolean']>
  phone?: Maybe<Scalars['String']>
}

export type CustomerInformation = {
  country?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  nationality?: InputMaybe<Scalars['String']>
  newsletter?: InputMaybe<Scalars['Boolean']>
  phone?: InputMaybe<Scalars['String']>
}

export type CustomerInformationInput = {
  cartId: Scalars['ID']
  customer: CustomerInformation
}

export type CustomerInformationType = {
  __typename?: 'CustomerInformationType'
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  nationality?: Maybe<Scalars['String']>
  newsletter?: Maybe<Scalars['Boolean']>
  phone?: Maybe<Scalars['String']>
}

export type DayAvailability = {
  __typename?: 'DayAvailability'
  available: Scalars['Int']
  date: Scalars['String']
  lowestPrice: Scalars['Int']
  soldOut: Scalars['Boolean']
}

export type Directions = {
  __typename?: 'Directions'
  coordinates?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>
}

export type EmailValidationInformation = {
  correlationId: Scalars['String']
  email: Scalars['String']
  productId: Scalars['String']
}

export type Extra = {
  __typename?: 'Extra'
  bookingRef: Scalars['String']
  description: Scalars['String']
  discountPct: Scalars['Int']
  itemNo: Scalars['String']
  lineNo: Scalars['Int']
  noOfPersons: Scalars['Int']
  perGuest: Scalars['Boolean']
  postingPattern: Scalars['Int']
  quantity: Scalars['Int']
  unitOfMeasure: Scalars['String']
  unitPrice: Scalars['Int']
}

export type FinalizeGiftCardInfo = {
  giftCardNo: Scalars['String']
  transactionId: Scalars['String']
}

export type FinalizeGiftCardResponse = {
  __typename?: 'FinalizeGiftCardResponse'
  giftCard?: Maybe<Scalars['Boolean']>
}

export type FindGiftCardPayload = {
  giftCardNo: Scalars['String']
  locale: Scalars['String']
}

export type FindGiftCardResponse = {
  __typename?: 'FindGiftCardResponse'
  Message?: Maybe<Scalars['String']>
  amount?: Maybe<Scalars['Int']>
  customerEmail?: Maybe<Scalars['String']>
  customerName?: Maybe<Scalars['String']>
  externalDocumentNo?: Maybe<Scalars['String']>
  giftCardName?: Maybe<Scalars['String']>
  isExperience?: Maybe<Scalars['Boolean']>
  language?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  reasonBlocked?: Maybe<Scalars['String']>
  recipientAddress?: Maybe<Scalars['String']>
  recipientAddress2?: Maybe<Scalars['String']>
  recipientCountryCode?: Maybe<Scalars['String']>
  recipientEmail?: Maybe<Scalars['String']>
  recipientName?: Maybe<Scalars['String']>
  recipientPhoneNumber?: Maybe<Scalars['String']>
  recipientPostCode?: Maybe<Scalars['String']>
  shippingAmount?: Maybe<Scalars['Int']>
  shippingMethod?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  validTo?: Maybe<Scalars['String']>
}

export type GetCartInput = {
  cartId: Scalars['ID']
  locale: Scalars['String']
  type: CartType
}

export type GiftCardInfo = {
  amount: Scalars['Int']
  caseAmount?: InputMaybe<Scalars['Int']>
  customerEmail?: InputMaybe<Scalars['String']>
  customerName?: InputMaybe<Scalars['String']>
  externalDocNo?: InputMaybe<Scalars['String']>
  giftCardName?: InputMaybe<Scalars['String']>
  language?: InputMaybe<Scalars['String']>
  message?: InputMaybe<Scalars['String']>
  recipientAddress?: InputMaybe<Scalars['String']>
  recipientAddress2?: InputMaybe<Scalars['String']>
  recipientCountryCode?: InputMaybe<Scalars['String']>
  recipientEmail?: InputMaybe<Scalars['String']>
  recipientName?: InputMaybe<Scalars['String']>
  recipientPhoneNumber?: InputMaybe<Scalars['String']>
  recipientPostCode?: InputMaybe<Scalars['String']>
  shippingAmount?: InputMaybe<Scalars['Int']>
  shippingMethod?: InputMaybe<Scalars['String']>
}

export type GiftCardInfoResponse = {
  __typename?: 'GiftCardInfoResponse'
  amount: Scalars['Int']
  caseAmount?: Maybe<Scalars['Int']>
  customerEmail?: Maybe<Scalars['String']>
  customerName?: Maybe<Scalars['String']>
  externalDocNo?: Maybe<Scalars['String']>
  giftCardName?: Maybe<Scalars['String']>
  language?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  recipientAddress?: Maybe<Scalars['String']>
  recipientAddress2?: Maybe<Scalars['String']>
  recipientCountryCode?: Maybe<Scalars['String']>
  recipientEmail?: Maybe<Scalars['String']>
  recipientName?: Maybe<Scalars['String']>
  recipientPhoneNumber?: Maybe<Scalars['String']>
  recipientPostCode?: Maybe<Scalars['String']>
  shippingAmount?: Maybe<Scalars['Int']>
  shippingMethod?: Maybe<Scalars['String']>
}

export type GiftCardItem = {
  __typename?: 'GiftCardItem'
  balance: Scalars['Int']
  chargeAmount: Scalars['Int']
  no: Scalars['String']
}

export type Guest = {
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  middleName?: InputMaybe<Scalars['String']>
}

export type GuestCount = {
  adults: Scalars['Int']
  children?: InputMaybe<Scalars['Int']>
}

export type HotelConfirmation = {
  __typename?: 'HotelConfirmation'
  hotelConfirmationCodes: Array<Maybe<Scalars['String']>>
  hotelGroupConfirmationCode?: Maybe<Scalars['String']>
  reservations: Array<Maybe<HotelReservation>>
}

export type HotelMeta = {
  __typename?: 'HotelMeta'
  arrivalDate: Scalars['DateTime']
  departureDate: Scalars['DateTime']
  groupCode?: Maybe<Scalars['String']>
  numberOfAdults?: Maybe<Scalars['Int']>
  numberOfChildren?: Maybe<Scalars['Int']>
  numberOfInfants?: Maybe<Scalars['Int']>
  numberOfTeenagers?: Maybe<Scalars['Int']>
  offers: Array<Maybe<OfferMeta>>
  rateCode?: Maybe<Scalars['String']>
}

export type HotelMetaInput = {
  arrivalDate: Scalars['DateTime']
  departureDate: Scalars['DateTime']
  groupCode?: InputMaybe<Scalars['String']>
  numberOfAdults: Scalars['Int']
  numberOfChildren: Scalars['Int']
  numberOfInfants: Scalars['Int']
  numberOfTeenagers?: InputMaybe<Scalars['Int']>
  offers: Array<InputMaybe<OfferInput>>
  rateCode: Scalars['String']
}

export type HotelReservation = {
  __typename?: 'HotelReservation'
  activities: Array<Maybe<HotelReservationActivity>>
  actualCheckInTime?: Maybe<Scalars['String']>
  actualCheckOutTime?: Maybe<Scalars['String']>
  adults: Scalars['Int']
  arrivalDate: Scalars['DateTime']
  bookedRoomType: Scalars['String']
  cancellationDateTime?: Maybe<Scalars['String']>
  cancellationNo?: Maybe<Scalars['String']>
  checkInTime: Scalars['String']
  checkOutTime: Scalars['String']
  children: Scalars['Int']
  comments?: Maybe<Array<Maybe<Scalars['String']>>>
  contactName?: Maybe<Scalars['String']>
  country: Scalars['String']
  cribs: Scalars['Int']
  customerName?: Maybe<Scalars['String']>
  departureDate: Scalars['DateTime']
  email: Scalars['String']
  externalReservationNo: Scalars['String']
  extraBeds: Scalars['Int']
  extras: Array<Maybe<Extra>>
  groupReservationNo?: Maybe<Scalars['String']>
  guestName: Scalars['String']
  guestNo: Scalars['String']
  mobilePhoneNo: Scalars['String']
  nights: Scalars['Int']
  numChildren: Scalars['Int']
  numInfants: Scalars['Int']
  payingRoomType: Scalars['String']
  phoneNo: Scalars['String']
  property: Scalars['String']
  rateCode: Scalars['String']
  reservationNo: Scalars['String']
  roomType: Scalars['String']
  status: Scalars['String']
}

export type HotelReservationActivity = {
  __typename?: 'HotelReservationActivity'
  bookingRef: Scalars['String']
  comment: Scalars['String']
  data?: Maybe<ActivityExtraInfo>
  lineNo: Scalars['String']
  numberOfPersons: Scalars['Int']
  productNo: Scalars['String']
  quantity: Scalars['Int']
  reservationDate: Scalars['DateTime']
  status: Scalars['String']
  subStatus: Scalars['String']
}

export type HotelRoomItem = {
  __typename?: 'HotelRoomItem'
  discountedPrice: Scalars['Int']
  displayName?: Maybe<Scalars['String']>
  fields?: Maybe<Scalars['JSON']>
  fullName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  linePrice: Scalars['Int']
  meta?: Maybe<HotelMeta>
  offer?: Maybe<RoomOffer>
  packageTitle?: Maybe<Scalars['String']>
  price: Scalars['Int']
  productId: Scalars['ID']
  qty: Scalars['Int']
  totalLinePrice: Scalars['Int']
  type: CartItemType
}

export type McpInput = {
  currency: Scalars['String']
  retrievalReferenceNumber: Scalars['String']
}

export type Massage = {
  available?: InputMaybe<Scalars['Int']>
  discountedPrice?: InputMaybe<Scalars['Int']>
  duration?: InputMaybe<Scalars['Int']>
  guests: Scalars['Int']
  id: Scalars['String']
  isFloat?: InputMaybe<Scalars['Boolean']>
  massageName?: InputMaybe<Scalars['String']>
  price: Scalars['Int']
  productId: Scalars['String']
  time: Scalars['String']
  type?: InputMaybe<Scalars['String']>
  validForCount?: InputMaybe<Scalars['Int']>
}

export type MassageAndFloatAvailability = {
  __typename?: 'MassageAndFloatAvailability'
  floats?: Maybe<Array<Maybe<MassageTypeInfo>>>
  massages?: Maybe<Array<Maybe<MassageTypeInfo>>>
}

export type MassageInformation = {
  __typename?: 'MassageInformation'
  discountedPrice: Scalars['Int']
  guests: Scalars['Int']
  price: Scalars['Int']
  productID: Scalars['String']
  time: Scalars['String']
}

export type MassageMetaInput = {
  arrivalTime: Scalars['String']
  hotelBookingRef?: InputMaybe<Scalars['String']>
}

export type MassageSlot = {
  __typename?: 'MassageSlot'
  available?: Maybe<Scalars['Int']>
  time?: Maybe<Scalars['String']>
}

export type MassageTypeInfo = {
  __typename?: 'MassageTypeInfo'
  available?: Maybe<Scalars['Int']>
  duration?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  slots?: Maybe<Array<Maybe<MassageSlot>>>
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  validForCount?: Maybe<Scalars['Int']>
}

export type Membership = {
  __typename?: 'Membership'
  membershipToken?: Maybe<Scalars['String']>
  subType?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type MembershipPaymentValidationInformation = {
  correlationId: Scalars['String']
  cres?: InputMaybe<Scalars['String']>
  membershipRef: Scalars['String']
  pares?: InputMaybe<Scalars['String']>
}

export enum MembershipType {
  Summer = 'SUMMER',
  Winter = 'WINTER',
}

export type Mutation = {
  __typename?: 'Mutation'
  addGiftCard: Cart
  addHotelRoomToCart: HotelRoomItem
  addMembershipToken: Cart
  addProductToCart: Cart
  addPromoCode: Cart
  addSpaCustomerInfo: Cart
  checkMembershipTdsm?: Maybe<CheckMembershipTdsmResponse>
  checkout: HotelConfirmation
  checkoutPayment: CheckoutPaymentDetail
  confirmBooking?: Maybe<Booking>
  createCart: Cart
  createGiftCard: CreateGiftCardResponse
  createPaymentTransaction: TransactionDetail
  finalizeGiftCard: FinalizeGiftCardResponse
  initializePayment: PaymentInitializeResponse
  orderGiftCard: OrderGiftCardResponse
  orderMembership?: Maybe<Order>
  removeGiftCard: Cart
  removeItem: Cart
  removeMembershipToken: Cart
  resetCart: Cart
  root?: Maybe<Scalars['String']>
  sendMembershipLoginCode?: Maybe<SendMembershipLoginCodeResponse>
  spaCheckout: HotelConfirmation
  updateBooking?: Maybe<Booking>
  updateHotelRoomCartItem: HotelRoomItem
  updateProductCartItem: Cart
  validateEmail?: Maybe<Validation>
  validateGiftCardACS?: Maybe<ValidateGiftCardAcsResponse>
  validateGiftCardTDSM?: Maybe<ValidateGiftCardTdsmResponse>
  validateMembership?: Maybe<ValidatedMembership>
  validateMembershipPayment?: Maybe<BookingMembership>
  validatePayment?: Maybe<Booking>
  validatePhone?: Maybe<Validation>
  validateTdsm?: Maybe<ValidateTdsmResponse>
  verifyMembership?: Maybe<Validation>
  verifyMembershipLoginCode?: Maybe<VerifyMembershipLoginCodeResponse>
}

export type MutationAddGiftCardArgs = {
  input: AddGiftCardInput
}

export type MutationAddHotelRoomToCartArgs = {
  input: AddHotelRoomToCartInput
}

export type MutationAddMembershipTokenArgs = {
  input: AddMembershipTokenInput
}

export type MutationAddProductToCartArgs = {
  input: AddProductToCartInput
}

export type MutationAddPromoCodeArgs = {
  input: AddPromoCodeInput
}

export type MutationAddSpaCustomerInfoArgs = {
  input: CustomerInformationInput
}

export type MutationCheckMembershipTdsmArgs = {
  info?: InputMaybe<CheckMembershipTdsmInfo>
}

export type MutationCheckoutArgs = {
  input: CheckoutInput
}

export type MutationCheckoutPaymentArgs = {
  input: CheckoutPaymentInput
}

export type MutationConfirmBookingArgs = {
  booking?: InputMaybe<ConfirmedBooking>
}

export type MutationCreateCartArgs = {
  input: CreateCartInput
}

export type MutationCreateGiftCardArgs = {
  info: GiftCardInfo
}

export type MutationCreatePaymentTransactionArgs = {
  input: TransactionInput
}

export type MutationFinalizeGiftCardArgs = {
  info: FinalizeGiftCardInfo
}

export type MutationInitializePaymentArgs = {
  body: PaymentInitialize
}

export type MutationOrderGiftCardArgs = {
  info: OrderGiftCardInfo
}

export type MutationOrderMembershipArgs = {
  info?: InputMaybe<OrderMembershipInformation>
}

export type MutationRemoveGiftCardArgs = {
  input: RemoveGiftCardInput
}

export type MutationRemoveItemArgs = {
  input: RemoveCartItemInput
}

export type MutationRemoveMembershipTokenArgs = {
  input: RemoveMembershipTokenInput
}

export type MutationResetCartArgs = {
  input: ResetCartInput
}

export type MutationSendMembershipLoginCodeArgs = {
  info?: InputMaybe<SendMembershipLoginCodeInfo>
}

export type MutationSpaCheckoutArgs = {
  input: SpaCheckoutInput
}

export type MutationUpdateBookingArgs = {
  info?: InputMaybe<BookingInformation>
}

export type MutationUpdateHotelRoomCartItemArgs = {
  input: UpdateHotelRoomCartItemInput
}

export type MutationUpdateProductCartItemArgs = {
  input: UpdateProductCartItemInput
}

export type MutationValidateEmailArgs = {
  info?: InputMaybe<EmailValidationInformation>
}

export type MutationValidateGiftCardAcsArgs = {
  info: ValidateGiftCardAcsInfo
}

export type MutationValidateGiftCardTdsmArgs = {
  info: ValidateGiftCardTdsmInfo
}

export type MutationValidateMembershipArgs = {
  info?: InputMaybe<ValidateMembership>
}

export type MutationValidateMembershipPaymentArgs = {
  payment?: InputMaybe<MembershipPaymentValidationInformation>
}

export type MutationValidatePaymentArgs = {
  payment?: InputMaybe<PaymentValidationInformation>
}

export type MutationValidatePhoneArgs = {
  info?: InputMaybe<PhoneValidationInformation>
}

export type MutationValidateTdsmArgs = {
  info?: InputMaybe<ValidateTdsmInput>
}

export type MutationVerifyMembershipArgs = {
  info?: InputMaybe<VerifyMembership>
}

export type MutationVerifyMembershipLoginCodeArgs = {
  info?: InputMaybe<VerifyMembershipLoginCodeInfo>
}

export type OfferInput = {
  date: Scalars['DateTime']
  offerId: Scalars['Int']
}

export type OfferMeta = {
  __typename?: 'OfferMeta'
  date: Scalars['String']
  offerId: Scalars['Int']
}

export type Order = {
  __typename?: 'Order'
  acsInfo?: Maybe<AcsResponse>
  bookingId: Scalars['ID']
  cartId: Scalars['ID']
  membershipRef: Scalars['String']
  tdsmInfo?: Maybe<TdsmInfo>
}

export type OrderGiftCardInfo = {
  correlationId: Scalars['String']
  giftCard: GiftCardInfo
  paymentInfo: PaymentInfo
}

export type OrderGiftCardResponse = {
  __typename?: 'OrderGiftCardResponse'
  acsInfo?: Maybe<AcsResponse>
  giftCardNo: Scalars['String']
  tdsmInfo?: Maybe<TdsmInfo>
}

export type OrderMembershipInformation = {
  cardHolders: Array<CardHolderInput>
  correlationId: Scalars['String']
  newsletter: Scalars['Boolean']
  paymentInfo: PaymentInformation
  productId: Scalars['String']
}

export type Package = {
  __typename?: 'Package'
  available?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['Int']>
  slots?: Maybe<Array<Maybe<RetreatSlots>>>
  type?: Maybe<Scalars['String']>
}

export type PackageAvailability = {
  __typename?: 'PackageAvailability'
  date?: Maybe<Scalars['DateTime']>
  slots?: Maybe<Array<Maybe<PackageSlot>>>
}

export type PackageSlot = {
  __typename?: 'PackageSlot'
  packages?: Maybe<Array<Maybe<Package>>>
  time?: Maybe<Scalars['String']>
}

export type PaymentDetailsInput = {
  cardNumber?: InputMaybe<Scalars['String']>
  cvc?: InputMaybe<Scalars['String']>
  expireMonth?: InputMaybe<Scalars['String']>
  expireYear?: InputMaybe<Scalars['String']>
  isPlanet?: InputMaybe<Scalars['Boolean']>
  paymentId?: InputMaybe<Scalars['Int']>
  transactionId?: InputMaybe<Scalars['String']>
}

export type PaymentInfo = {
  amount: Scalars['Int']
  cardNumber: Scalars['String']
  cvc: Scalars['String']
  expireMonth: Scalars['String']
  expireYear: Scalars['String']
  returnUrl: Scalars['String']
}

export type PaymentInformation = {
  amount: Scalars['Int']
  cardNumber: Scalars['String']
  cvc: Scalars['String']
  expireMonth: Scalars['String']
  expireYear: Scalars['String']
  paymentSessionData?: InputMaybe<Scalars['String']>
  returnUrl: Scalars['String']
}

export type PaymentInitialize = {
  amount: Scalars['Int']
  bookingRef: Scalars['String']
  businessProcess: Scalars['String']
  mcp?: InputMaybe<McpInput>
  origin: Scalars['String']
  redirectOption: RedirectOption
  themeOption?: InputMaybe<ThemeOption>
}

export type PaymentInitializeResponse = {
  __typename?: 'PaymentInitializeResponse'
  transactionId: Scalars['String']
}

export type PaymentValidationInformation = {
  bookingId: Scalars['String']
  correlationId: Scalars['String']
  cres?: InputMaybe<Scalars['String']>
  pares?: InputMaybe<Scalars['String']>
}

export type PhoneValidationInformation = {
  correlationId: Scalars['String']
  phoneNumber: Scalars['String']
  productId: Scalars['String']
}

export type PrimaryGuest = {
  address: PrimaryGuestAddress
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  middleName?: InputMaybe<Scalars['String']>
  namePrefix?: InputMaybe<Scalars['String']>
  nameSuffix?: InputMaybe<Scalars['String']>
  phoneNo?: InputMaybe<Scalars['String']>
}

export type PrimaryGuestAddress = {
  address?: InputMaybe<Scalars['String']>
  address2?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  country: Scalars['String']
  postCode?: InputMaybe<Scalars['String']>
}

export type PrivateTransferItem = {
  __typename?: 'PrivateTransferItem'
  discountedPrice: Scalars['Int']
  displayName?: Maybe<Scalars['String']>
  fields?: Maybe<Scalars['JSON']>
  fullName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  linePrice: Scalars['Int']
  meta?: Maybe<PrivateTransferMeta>
  price: Scalars['Int']
  productId: Scalars['ID']
  qty: Scalars['Int']
  totalLinePrice: Scalars['Int']
  type: CartItemType
}

export type PrivateTransferMeta = {
  __typename?: 'PrivateTransferMeta'
  arrivalTime?: Maybe<Scalars['DateTime']>
  comment?: Maybe<Scalars['String']>
}

export type Product = {
  __typename?: 'Product'
  availabilityBasedPricing: Scalars['Boolean']
  company: Scalars['String']
  discountedPrice?: Maybe<Scalars['Float']>
  displayName: Scalars['String']
  fixedDiscountPercentage?: Maybe<Scalars['Float']>
  fullName: Scalars['String']
  location?: Maybe<Scalars['String']>
  price: Scalars['Int']
  productId: Scalars['String']
  productSubType?: Maybe<Scalars['String']>
  productType?: Maybe<Scalars['String']>
  property?: Maybe<Scalars['String']>
  roomType?: Maybe<Scalars['String']>
}

export type ProductAvailabilitiesInput = {
  adults?: InputMaybe<Scalars['Int']>
  children?: InputMaybe<Scalars['Int']>
  from: Scalars['String']
  isMembership?: InputMaybe<Scalars['Boolean']>
  locale?: InputMaybe<Scalars['String']>
  numberOfRooms?: InputMaybe<Scalars['Int']>
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  to: Scalars['String']
  type: CartType
  where?: InputMaybe<ProductAvailabilitiesInputFilters>
}

export type ProductAvailabilitiesInputFilters = {
  hotels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rateCodeKey?: InputMaybe<Scalars['String']>
  roomFeatures?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  roomFeaturesHighland?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  roomFeaturesRetreat?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  roomTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  roomTypesHighland?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  roomView?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ProductAvailability = {
  __typename?: 'ProductAvailability'
  availabilityBasedPricing?: Maybe<Scalars['Boolean']>
  company?: Maybe<Scalars['String']>
  discountedPrice?: Maybe<Scalars['Float']>
  displayName?: Maybe<Scalars['String']>
  fixedDiscountPercentage?: Maybe<Scalars['Float']>
  fullName?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  productId?: Maybe<Scalars['String']>
  productSubType?: Maybe<Scalars['String']>
  productType?: Maybe<Scalars['String']>
  property?: Maybe<Scalars['String']>
}

export type ProductInformation = {
  __typename?: 'ProductInformation'
  price?: Maybe<Scalars['Int']>
  productId?: Maybe<Scalars['String']>
  qty?: Maybe<Scalars['Int']>
  totalAmount?: Maybe<Scalars['Int']>
}

export type ProductInput = {
  productId?: InputMaybe<Scalars['String']>
  qty?: InputMaybe<Scalars['Int']>
}

export type ProductItem = {
  __typename?: 'ProductItem'
  discountedPrice: Scalars['Int']
  displayName?: Maybe<Scalars['String']>
  fields?: Maybe<Scalars['JSON']>
  fullName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  linePrice: Scalars['Int']
  meta?: Maybe<ProductMeta>
  price: Scalars['Int']
  productId: Scalars['ID']
  qty: Scalars['Int']
  totalLinePrice: Scalars['Int']
  type: CartItemType
}

export type ProductMeta = {
  __typename?: 'ProductMeta'
  bookingRef?: Maybe<Scalars['String']>
}

export type ProductsCatalogInput = {
  type: CartType
}

export type ProductsOffer = {
  __typename?: 'ProductsOffer'
  available: Scalars['Int']
  componentId?: Maybe<Scalars['String']>
  componentType?: Maybe<Scalars['String']>
  fields?: Maybe<Scalars['JSON']>
  lengthOfStay?: Maybe<Scalars['Int']>
  offerId?: Maybe<Scalars['Int']>
  price: Scalars['Int']
  productId: Scalars['String']
  rateCode?: Maybe<Scalars['String']>
  subOffers?: Maybe<Array<Maybe<SubOffer>>>
  time?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
}

export enum Property {
  Kerlingarfjoll = 'KERLINGARFJOLL',
  Retreat = 'RETREAT',
  Silica = 'SILICA',
}

export type Query = {
  __typename?: 'Query'
  activityProductAvailability: Array<Maybe<ProductsOffer>>
  dayAvailability?: Maybe<Array<Maybe<DayAvailability>>>
  departureDetails?: Maybe<TransportationDetails>
  directions?: Maybe<Directions>
  dropoffLocations?: Maybe<Array<Maybe<TransportationLocation>>>
  findGiftCard: FindGiftCardResponse
  getCart?: Maybe<Cart>
  getCheckoutStatus: CheckoutStatus
  getTransactionStatus: TransactionStatusResponse
  massageAndFloatAvailability?: Maybe<MassageAndFloatAvailability>
  packageAvailability?: Maybe<PackageAvailability>
  pickupDetails?: Maybe<TransportationDetails>
  pickupLocations?: Maybe<Array<Maybe<TransportationLocation>>>
  productAvailabilities: Array<Maybe<ProductsOffer>>
  products?: Maybe<Array<Maybe<ProductAvailability>>>
  productsCatalog: Array<Maybe<Product>>
  rateCodeRestrictions: Array<Maybe<Restriction>>
  restaurantAvailability?: Maybe<RestaurantAvailability>
  restrictions?: Maybe<Restrictions>
  roomAvailability: Array<Maybe<RoomOffer>>
  roomRangeAvailability: Array<Maybe<RoomAvailability>>
  roomRangeAvailabilityRestrictions: Array<Maybe<RoomAvailabilityRestrictions>>
  roomTypeRestrictions: Array<Maybe<Restriction>>
  root?: Maybe<Scalars['String']>
  transportationData?: Maybe<TransportationData>
  transportationPrices?: Maybe<TransportationPrice>
}

export type QueryActivityProductAvailabilityArgs = {
  input: ActivityProductAvailabilityInput
}

export type QueryDayAvailabilityArgs = {
  endDate: Scalars['String']
  startDate: Scalars['String']
}

export type QueryDepartureDetailsArgs = {
  arrivalTime?: InputMaybe<Scalars['String']>
  date: Scalars['String']
}

export type QueryDirectionsArgs = {
  endLatitude: Scalars['Float']
  endLongitude: Scalars['Float']
  startLatitude: Scalars['Float']
  startLongitude: Scalars['Float']
}

export type QueryFindGiftCardArgs = {
  where?: InputMaybe<FindGiftCardPayload>
}

export type QueryGetCartArgs = {
  input: GetCartInput
}

export type QueryGetCheckoutStatusArgs = {
  input: CheckoutStatusInput
}

export type QueryGetTransactionStatusArgs = {
  where: TransactionStatusQuery
}

export type QueryMassageAndFloatAvailabilityArgs = {
  arrivalTime: Scalars['String']
  date: Scalars['String']
  locale: Scalars['String']
}

export type QueryPackageAvailabilityArgs = {
  date: Scalars['String']
  subscription?: InputMaybe<Scalars['String']>
}

export type QueryPickupDetailsArgs = {
  arrivalTime?: InputMaybe<Scalars['String']>
  date: Scalars['String']
}

export type QueryProductAvailabilitiesArgs = {
  input: ProductAvailabilitiesInput
}

export type QueryProductsCatalogArgs = {
  input: ProductsCatalogInput
}

export type QueryRateCodeRestrictionsArgs = {
  input?: InputMaybe<RateCodeRestrictionsInput>
}

export type QueryRestaurantAvailabilityArgs = {
  date: Scalars['String']
}

export type QueryRestrictionsArgs = {
  input?: InputMaybe<RestrictionsInput>
}

export type QueryRoomAvailabilityArgs = {
  input: RoomAvailabilityInput
}

export type QueryRoomRangeAvailabilityArgs = {
  input: RoomRangeAvailabilityInput
}

export type QueryRoomRangeAvailabilityRestrictionsArgs = {
  input: RoomRangeAvailabilityInput
}

export type QueryRoomTypeRestrictionsArgs = {
  input?: InputMaybe<RoomTypeRestrictionsInput>
}

export type RateCode = {
  __typename?: 'RateCode'
  price: Scalars['Int']
}

export type RateCodeRestrictionsInput = {
  id: Scalars['String']
  type: CartType
}

export type RedirectOption = {
  cancelUrl: Scalars['String']
  errorUrl: Scalars['String']
  method: Scalars['String']
  successUrl: Scalars['String']
}

export type RemoveCartItemInput = {
  cartId: Scalars['ID']
  itemId: Scalars['ID']
  type: CartType
}

export type RemoveGiftCardInput = {
  cartId: Scalars['ID']
  giftCard: Scalars['String']
  type: CartType
}

export type RemoveMembershipTokenInput = {
  cartId: Scalars['ID']
  type: CartType
}

export enum RequestOrigin {
  Domestic = 'Domestic',
  Foreign = 'Foreign',
}

export type ResetCartInput = {
  cartId: Scalars['ID']
  type: CartType
}

export type RestaurantAvailability = {
  __typename?: 'RestaurantAvailability'
  date?: Maybe<Scalars['DateTime']>
  slots?: Maybe<Array<Maybe<RestaurantSlot>>>
}

export type RestaurantMetaInput = {
  arrivalTime: Scalars['String']
  hotelBookingRef?: InputMaybe<Scalars['String']>
  noOfPersons: Scalars['Int']
}

export type RestaurantReservation = {
  arrivalTime?: InputMaybe<Scalars['String']>
  comment?: InputMaybe<Scalars['String']>
  guests?: InputMaybe<Scalars['Int']>
}

export type RestaurantSlot = {
  __typename?: 'RestaurantSlot'
  available?: Maybe<Scalars['Int']>
  time?: Maybe<Scalars['String']>
}

export type Restriction = {
  __typename?: 'Restriction'
  allDays?: Maybe<Scalars['Boolean']>
  dateFrom?: Maybe<Scalars['DateTime']>
  dateTo?: Maybe<Scalars['DateTime']>
  description?: Maybe<Scalars['String']>
  friday?: Maybe<Scalars['Boolean']>
  maximumLeadDays?: Maybe<Scalars['Int']>
  maximumLengthOfStay?: Maybe<Scalars['Int']>
  minimumLeadDays?: Maybe<Scalars['Int']>
  minimumLengthOfStay?: Maybe<Scalars['Int']>
  monday?: Maybe<Scalars['Boolean']>
  property?: Maybe<Scalars['String']>
  rateCode?: Maybe<Scalars['String']>
  roomType?: Maybe<Scalars['String']>
  saturday?: Maybe<Scalars['Boolean']>
  sellLimit?: Maybe<Scalars['Int']>
  sunday?: Maybe<Scalars['Boolean']>
  thursday?: Maybe<Scalars['Boolean']>
  tuesday?: Maybe<Scalars['Boolean']>
  type?: Maybe<Scalars['String']>
  wednesday?: Maybe<Scalars['Boolean']>
}

export type Restrictions = {
  __typename?: 'Restrictions'
  propertyRestriction?: Maybe<Scalars['JSON']>
  rateCodeRestriction?: Maybe<Scalars['JSON']>
  roomTypeRestriction?: Maybe<Scalars['JSON']>
}

export type RestrictionsInput = {
  type: CartType
}

export type RetreatSlots = {
  __typename?: 'RetreatSlots'
  available?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['Int']>
  time?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type RoomAvailability = {
  __typename?: 'RoomAvailability'
  availableRooms: Scalars['Int']
  blockedRooms: Scalars['Int']
  channelMgtOffset: Scalars['Int']
  date: Scalars['DateTime']
  property: Scalars['String']
  roomType: Scalars['String']
  sellThreshold: Scalars['Int']
  totalRooms: Scalars['Int']
  validArrivalDate?: Maybe<Scalars['Boolean']>
  validDepartureDate?: Maybe<Scalars['Boolean']>
}

export type RoomAvailabilityInput = {
  enforceRestrictions?: InputMaybe<Scalars['Boolean']>
  from: Scalars['String']
  locale: Scalars['String']
  to: Scalars['String']
  type: CartType
  where?: InputMaybe<ProductAvailabilitiesInputFilters>
}

export type RoomAvailabilityRestrictions = {
  __typename?: 'RoomAvailabilityRestrictions'
  availableRooms: Scalars['Int']
  blockedRooms: Scalars['Int']
  channelMgtOffset: Scalars['Int']
  date: Scalars['DateTime']
  property: Scalars['String']
  roomType: Scalars['String']
  sellThreshold: Scalars['Int']
  totalRooms: Scalars['Int']
  validArrivalDate?: Maybe<Scalars['Boolean']>
  validDepartureDate?: Maybe<Scalars['Boolean']>
}

export type RoomOffer = {
  __typename?: 'RoomOffer'
  allowedAdults: Scalars['Int']
  allowedChildren: Scalars['Int']
  allowedGuests: Scalars['Int']
  allowedInfants: Scalars['Int']
  available: Scalars['Int']
  fields?: Maybe<Scalars['JSON']>
  lengthOfStay: Scalars['Int']
  price: Scalars['Int']
  productId: Scalars['String']
  rateCodes: Scalars['JSON']
}

export type RoomRangeAvailabilityInput = {
  availableOnly?: InputMaybe<Scalars['Boolean']>
  enforceRestrictions?: InputMaybe<Scalars['Boolean']>
  from?: InputMaybe<Scalars['String']>
  numberOfNights?: InputMaybe<Scalars['Int']>
  numberOfRooms?: InputMaybe<Scalars['Int']>
  property?: InputMaybe<Scalars['String']>
  rateCode?: InputMaybe<Scalars['String']>
  roomType?: InputMaybe<Scalars['String']>
  to?: InputMaybe<Scalars['String']>
}

export type RoomTypeRestrictionsInput = {
  id: Scalars['String']
  type: CartType
}

export type SendMembershipLoginCodeInfo = {
  correlationId: Scalars['String']
  phoneNumber: Scalars['String']
}

export type SendMembershipLoginCodeResponse = {
  __typename?: 'SendMembershipLoginCodeResponse'
  phoneNumber: Scalars['String']
}

export type SpaCheckoutInput = {
  cartId: Scalars['ID']
  customer: CustomerInformation
  payment: PaymentInformation
  type: CartType
}

export type SubOffer = {
  __typename?: 'SubOffer'
  additionalInfo?: Maybe<AdditionalInfo>
  date?: Maybe<Scalars['DateTime']>
  offerId: Scalars['Int']
  price: Scalars['Int']
}

export type Subscription = {
  __typename?: 'Subscription'
  packageAvailabilityChanged?: Maybe<PackageAvailability>
  root?: Maybe<Scalars['String']>
}

export type SubscriptionPackageAvailabilityChangedArgs = {
  date?: InputMaybe<Scalars['String']>
}

export type TdsmInfo = {
  __typename?: 'TdsmInfo'
  tdsmContent: Scalars['String']
}

export type ThemeOption = {
  brandButton?: InputMaybe<Scalars['String']>
  brandColor?: InputMaybe<Scalars['String']>
  initialView?: InputMaybe<Scalars['String']>
  logoBorderColor?: InputMaybe<Scalars['String']>
  logoSrc?: InputMaybe<Scalars['String']>
  logoType?: InputMaybe<Scalars['String']>
  payButtonTextColor?: InputMaybe<Scalars['String']>
}

export type TransactionDetail = {
  __typename?: 'TransactionDetail'
  authorize?: Maybe<TransactionDetailAuthorize>
  cart?: Maybe<Cart>
  fail?: Maybe<TransactionDetailFail>
  paymentId?: Maybe<Scalars['Int']>
  transactionId?: Maybe<Scalars['String']>
}

export type TransactionDetailAuthorize = {
  __typename?: 'TransactionDetailAuthorize'
  amount: Scalars['Int']
}

export type TransactionDetailFail = {
  __typename?: 'TransactionDetailFail'
  message: Scalars['String']
  reason: Scalars['String']
}

export type TransactionInput = {
  cartId: Scalars['ID']
  mcp?: InputMaybe<McpInput>
  returnUrl: Scalars['String']
  type: CartType
}

export type TransactionStatusData = {
  __typename?: 'TransactionStatusData'
  data: TransactionStatusInfo
  refNo: Scalars['String']
}

export type TransactionStatusInfo = {
  __typename?: 'TransactionStatusInfo'
  detail?: Maybe<TransactionDetail>
  language: Scalars['String']
  merchantId: Scalars['String']
  paymentMethod: Scalars['String']
  refNo: Scalars['String']
  status: Scalars['String']
  transactionId: Scalars['String']
  type: Scalars['String']
}

export type TransactionStatusQuery = {
  transactionId: Scalars['String']
}

export type TransactionStatusResponse = {
  __typename?: 'TransactionStatusResponse'
  data: Array<TransactionStatusData>
}

export type TransferMetaInput = {
  departureTime: Scalars['String']
  dropoffLocationId: Scalars['String']
  pickupLocationId: Scalars['String']
  pickupTime: Scalars['String']
}

export type Transportation = {
  adultPrice: Scalars['Int']
  departureDate: Scalars['DateTime']
  departureTime: Scalars['String']
  dropoffLocationId: Scalars['String']
  pickupLocationId: Scalars['String']
  pickupTime: Scalars['String']
}

export type TransportationData = {
  __typename?: 'TransportationData'
  airport?: Maybe<TransportationLocation>
  prices?: Maybe<TransportationPrice>
}

export type TransportationDetails = {
  __typename?: 'TransportationDetails'
  airport?: Maybe<Array<Maybe<AvailabilityDetails>>>
  city?: Maybe<Array<Maybe<AvailabilityDetails>>>
  error?: Maybe<Scalars['String']>
}

export type TransportationInformation = {
  __typename?: 'TransportationInformation'
  adultPrice: Scalars['Int']
  departureDate: Scalars['String']
  departureTime: Scalars['String']
  discountedAdultPrice: Scalars['Int']
  dropoffLocationId: Scalars['String']
  pickupLocationId: Scalars['String']
  pickupTime: Scalars['String']
}

export type TransportationItem = {
  __typename?: 'TransportationItem'
  discountedPrice: Scalars['Int']
  displayName?: Maybe<Scalars['String']>
  fields?: Maybe<Scalars['JSON']>
  fullName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  linePrice: Scalars['Int']
  meta?: Maybe<TransportationMeta>
  price: Scalars['Int']
  productId: Scalars['ID']
  qty: Scalars['Int']
  reservationStatus?: Maybe<Scalars['String']>
  totalLinePrice: Scalars['Int']
  type: CartItemType
}

export type TransportationLocation = {
  __typename?: 'TransportationLocation'
  address?: Maybe<Scalars['String']>
  bus?: Maybe<Scalars['Int']>
  busStop?: Maybe<BusStop>
  id?: Maybe<Scalars['String']>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
}

export type TransportationMeta = {
  __typename?: 'TransportationMeta'
  inboundComponentId?: Maybe<Scalars['String']>
  inboundDepartureTime?: Maybe<Scalars['DateTime']>
  inboundPickupLocationId?: Maybe<Scalars['String']>
  outboundComponentId?: Maybe<Scalars['String']>
  outboundDepartureTime?: Maybe<Scalars['DateTime']>
  outboundDropoffLocationId?: Maybe<Scalars['String']>
}

export type TransportationPrice = {
  __typename?: 'TransportationPrice'
  adultPrice?: Maybe<Scalars['Int']>
  beforeDiscountAdultPrice?: Maybe<Scalars['Int']>
  childPrice?: Maybe<Scalars['Int']>
  discountPercentage?: Maybe<Scalars['Int']>
  teenagerPrice?: Maybe<Scalars['Int']>
}

export type UpdateHotelMetaInput = {
  arrivalDate?: InputMaybe<Scalars['DateTime']>
  arrivalTime?: InputMaybe<Scalars['DateTime']>
  comment?: InputMaybe<Scalars['String']>
  departureDate?: InputMaybe<Scalars['DateTime']>
  groupCode?: InputMaybe<Scalars['String']>
  inboundComponentId?: InputMaybe<Scalars['String']>
  inboundDepartureTime?: InputMaybe<Scalars['DateTime']>
  inboundPickupLocationId?: InputMaybe<Scalars['String']>
  noOfPersons?: InputMaybe<Scalars['Int']>
  numberOfAdults?: InputMaybe<Scalars['Int']>
  numberOfChildren?: InputMaybe<Scalars['Int']>
  numberOfInfants?: InputMaybe<Scalars['Int']>
  numberOfTeenagers?: InputMaybe<Scalars['Int']>
  offerId?: InputMaybe<Scalars['Int']>
  offers?: InputMaybe<Array<InputMaybe<OfferInput>>>
  outboundComponentId?: InputMaybe<Scalars['String']>
  outboundDepartureTime?: InputMaybe<Scalars['DateTime']>
  outboundDropoffLocationId?: InputMaybe<Scalars['String']>
  rateCode?: InputMaybe<Scalars['String']>
}

export type UpdateHotelRoomCartItemInput = {
  cartId: Scalars['ID']
  itemId: Scalars['ID']
  locale?: InputMaybe<Scalars['String']>
  meta?: InputMaybe<UpdateHotelMetaInput>
  productId: Scalars['ID']
  qty?: Scalars['Int']
}

export type UpdateProductCartItemInput = {
  cartId: Scalars['ID']
  hotelBookingRef?: InputMaybe<Scalars['ID']>
  itemId: Scalars['ID']
  locale?: InputMaybe<Scalars['String']>
  productId: Scalars['ID']
  qty?: Scalars['Int']
}

export type UpdatedBooking = {
  __typename?: 'UpdatedBooking'
  adultCount: Scalars['Int']
  adultPrice: Scalars['Int']
  arrivalDate: Scalars['String']
  arrivalTime: Scalars['String']
  bookingId?: Maybe<Scalars['String']>
  busTransfer?: Maybe<TransportationInformation>
  childrenCount?: Maybe<Scalars['Int']>
  childrenPrice?: Maybe<Scalars['Int']>
  comment?: Maybe<Scalars['String']>
  customer?: Maybe<CustomerInfo>
  discountedAdultPrice: Scalars['Int']
  discountedChildrenPrice: Scalars['Int']
  giftCardAmount?: Maybe<Scalars['Int']>
  giftCardPaymentAmount?: Maybe<Scalars['Int']>
  massage?: Maybe<Array<Maybe<MassageInformation>>>
  packageType: Scalars['String']
  paymentAmount?: Maybe<Scalars['Int']>
  products?: Maybe<Array<Maybe<ProductInformation>>>
  promoCode?: Maybe<Scalars['String']>
  totalAmount?: Maybe<Scalars['Int']>
}

export type ValidateGiftCardAcsInfo = {
  correlationId: Scalars['String']
  cres?: InputMaybe<Scalars['String']>
  giftCardNo: Scalars['String']
  pares?: InputMaybe<Scalars['String']>
}

export type ValidateGiftCardAcsResponse = {
  __typename?: 'ValidateGiftCardACSResponse'
  giftCardNo: Scalars['String']
}

export type ValidateGiftCardTdsmInfo = {
  correlationId: Scalars['String']
  giftCardNo: Scalars['String']
}

export type ValidateGiftCardTdsmResponse = {
  __typename?: 'ValidateGiftCardTDSMResponse'
  acsInfo?: Maybe<AcsResponse>
  giftCardNo: Scalars['String']
}

export type ValidateMembership = {
  code: Scalars['String']
  membershipType: MembershipType
  phoneNumber: Scalars['String']
}

export type ValidatedMembership = {
  __typename?: 'ValidatedMembership'
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  membershipValidationToken: Scalars['String']
  name: Scalars['String']
  numberOfCardHolders: Scalars['Int']
  phone: Scalars['String']
  price: Scalars['Int']
  type: Scalars['String']
  userName: Scalars['String']
  validFrom: Scalars['String']
  validTo: Scalars['String']
}

export type Validation = {
  __typename?: 'Validation'
  valid: Scalars['Boolean']
}

export type VerifyMembership = {
  membershipType: MembershipType
  phoneNumber: Scalars['String']
}

export type VerifyMembershipLoginCodeInfo = {
  code: Scalars['String']
  correlationId: Scalars['String']
  phoneNumber: Scalars['String']
}

export type VerifyMembershipLoginCodeResponse = {
  __typename?: 'VerifyMembershipLoginCodeResponse'
  mainContact?: Maybe<CardHolder>
  secondaryContact?: Maybe<CardHolder>
}

export type ValidateTdsmInput = {
  bookingId: Scalars['String']
  correlationId: Scalars['String']
}

export type ValidateTdsmResponse = {
  __typename?: 'validateTDSMResponse'
  acsInfo?: Maybe<AcsResponse>
  bookingId: Scalars['String']
}

export type CheckMembershipTdsmMutationVariables = Exact<{
  info: CheckMembershipTdsmInfo
}>

export type CheckMembershipTdsmMutation = {
  __typename?: 'Mutation'
  checkMembershipTdsm?: {
    __typename?: 'CheckMembershipTdsmResponse'
    membershipRef: string
    acsInfo?: {
      __typename?: 'AcsResponse'
      acsForm: string
      acsFormData?: Array<{
        __typename?: 'AcsFormItem'
        name: string
        value?: string | null
      } | null> | null
    } | null
  } | null
}

export type ConfirmBookingMutationVariables = Exact<{
  booking: ConfirmedBooking
}>

export type ConfirmBookingMutation = {
  __typename?: 'Mutation'
  confirmBooking?: {
    __typename?: 'Booking'
    bookingId: string
    acsInfo?: {
      __typename?: 'AcsResponse'
      acsForm: string
      acsFormData?: Array<{
        __typename?: 'AcsFormItem'
        name: string
        value?: string | null
      } | null> | null
    } | null
    tdsmInfo?: { __typename?: 'TdsmInfo'; tdsmContent: string } | null
  } | null
}

export type OrderMembershipMutationVariables = Exact<{
  info: OrderMembershipInformation
}>

export type OrderMembershipMutation = {
  __typename?: 'Mutation'
  orderMembership?: {
    __typename?: 'Order'
    membershipRef: string
    acsInfo?: {
      __typename?: 'AcsResponse'
      acsForm: string
      acsFormData?: Array<{
        __typename?: 'AcsFormItem'
        name: string
        value?: string | null
      } | null> | null
    } | null
    tdsmInfo?: { __typename?: 'TdsmInfo'; tdsmContent: string } | null
  } | null
}

export type ProductsQueryVariables = Exact<{ [key: string]: never }>

export type ProductsQuery = {
  __typename?: 'Query'
  products?: Array<{
    __typename?: 'ProductAvailability'
    productId?: string | null
    productType?: string | null
    productSubType?: string | null
    fullName?: string | null
    displayName?: string | null
    company?: string | null
    property?: string | null
    location?: string | null
    price?: number | null
    fixedDiscountPercentage?: number | null
    discountedPrice?: number | null
    availabilityBasedPricing?: boolean | null
  } | null> | null
}

export type SendMembershipLoginCodeMutationVariables = Exact<{
  info: SendMembershipLoginCodeInfo
}>

export type SendMembershipLoginCodeMutation = {
  __typename?: 'Mutation'
  sendMembershipLoginCode?: {
    __typename?: 'SendMembershipLoginCodeResponse'
    phoneNumber: string
  } | null
}

export type UpdateBookingMutationVariables = Exact<{
  info: BookingInformation
}>

export type UpdateBookingMutation = {
  __typename?: 'Mutation'
  updateBooking?: {
    __typename?: 'Booking'
    bookingId: string
    booking?: {
      __typename?: 'UpdatedBooking'
      adultCount: number
      adultPrice: number
      discountedAdultPrice: number
      childrenCount?: number | null
      childrenPrice?: number | null
      discountedChildrenPrice: number
      totalAmount?: number | null
      giftCardAmount?: number | null
      giftCardPaymentAmount?: number | null
      paymentAmount?: number | null
      comment?: string | null
      promoCode?: string | null
      busTransfer?: {
        __typename?: 'TransportationInformation'
        discountedAdultPrice: number
        adultPrice: number
      } | null
      massage?: Array<{
        __typename?: 'MassageInformation'
        discountedPrice: number
        price: number
        guests: number
        productID: string
        time: string
      } | null> | null
      products?: Array<{
        __typename?: 'ProductInformation'
        price?: number | null
        totalAmount?: number | null
        productId?: string | null
        qty?: number | null
      } | null> | null
    } | null
  } | null
}

export type ValidatePaymentMutationVariables = Exact<{
  payment: PaymentValidationInformation
}>

export type ValidatePaymentMutation = {
  __typename?: 'Mutation'
  validatePayment?: { __typename?: 'Booking'; bookingId: string } | null
}

export type ValidateEmailMutationVariables = Exact<{
  info: EmailValidationInformation
}>

export type ValidateEmailMutation = {
  __typename?: 'Mutation'
  validateEmail?: { __typename?: 'Validation'; valid: boolean } | null
}

export type ValidateMembershipMutationVariables = Exact<{
  info: ValidateMembership
}>

export type ValidateMembershipMutation = {
  __typename?: 'Mutation'
  validateMembership?: {
    __typename?: 'ValidatedMembership'
    type: string
    name: string
    userName: string
    validFrom: string
    validTo: string
    firstName: string
    lastName: string
    email: string
    price: number
    phone: string
    numberOfCardHolders: number
    membershipValidationToken: string
  } | null
}

export type ValidateMembershipPaymentMutationVariables = Exact<{
  payment: MembershipPaymentValidationInformation
}>

export type ValidateMembershipPaymentMutation = {
  __typename?: 'Mutation'
  validateMembershipPayment?: {
    __typename?: 'BookingMembership'
    membershipRef: string
  } | null
}

export type ValidatePhoneMutationVariables = Exact<{
  info: PhoneValidationInformation
}>

export type ValidatePhoneMutation = {
  __typename?: 'Mutation'
  validatePhone?: { __typename?: 'Validation'; valid: boolean } | null
}

export type ValidateTdsmMutationVariables = Exact<{
  info: ValidateTdsmInput
}>

export type ValidateTdsmMutation = {
  __typename?: 'Mutation'
  validateTdsm?: {
    __typename?: 'validateTDSMResponse'
    bookingId: string
    acsInfo?: {
      __typename?: 'AcsResponse'
      acsForm: string
      acsFormData?: Array<{
        __typename?: 'AcsFormItem'
        name: string
        value?: string | null
      } | null> | null
    } | null
  } | null
}

export type VerifyMembershipMutationVariables = Exact<{
  info: VerifyMembership
}>

export type VerifyMembershipMutation = {
  __typename?: 'Mutation'
  verifyMembership?: { __typename?: 'Validation'; valid: boolean } | null
}

export type VerifyMembershipLoginCodeMutationVariables = Exact<{
  info: VerifyMembershipLoginCodeInfo
}>

export type VerifyMembershipLoginCodeMutation = {
  __typename?: 'Mutation'
  verifyMembershipLoginCode?: {
    __typename?: 'VerifyMembershipLoginCodeResponse'
    mainContact?: {
      __typename?: 'CardHolder'
      id?: string | null
      firstName: string
      lastName: string
      personalId: string
      email: string
      phoneNumber: string
    } | null
    secondaryContact?: {
      __typename?: 'CardHolder'
      id?: string | null
      firstName: string
      lastName: string
      personalId: string
      email: string
      phoneNumber: string
    } | null
  } | null
}

export type ActivityProductAvailabilityQueryVariables = Exact<{
  input: ActivityProductAvailabilityInput
}>

export type ActivityProductAvailabilityQuery = {
  __typename?: 'Query'
  activityProductAvailability: Array<{
    __typename?: 'ProductsOffer'
    productId: string
    offerId?: number | null
    available: number
    price: number
    time?: any | null
    componentType?: string | null
    componentId?: string | null
    title?: string | null
    subOffers?: Array<{
      __typename?: 'SubOffer'
      offerId: number
      date?: any | null
      price: number
      additionalInfo?: {
        __typename?: 'AdditionalInfo'
        id?: number | null
        title?: string | null
        adultPrice?: number | null
        childPrice?: number | null
        teenagerPrice?: number | null
      } | null
    } | null> | null
  } | null>
}

export type AddGiftCardToCartMutationVariables = Exact<{
  input: AddGiftCardInput
}>

export type AddGiftCardToCartMutation = {
  __typename?: 'Mutation'
  addGiftCard: {
    __typename?: 'Cart'
    id: string
    totalAmount: number
    discountedAmount: number
    giftCardAmount: number
    refundAmount: number
    paymentRequired: boolean
    paymentAmount?: number | null
    currency: string
    promoCode?: string | null
    items: Array<
      | {
          __typename: 'ActivityItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ActivityMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'AdmissionItem'
          id: string
          productId: string
          productType?: string | null
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'AdmissionItemMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
          } | null
        }
      | {
          __typename: 'HotelRoomItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          packageTitle?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          offer?: {
            __typename?: 'RoomOffer'
            productId: string
            rateCodes: any
            fields?: any | null
          } | null
          meta?: {
            __typename?: 'HotelMeta'
            arrivalDate: any
            departureDate: any
            groupCode?: string | null
            numberOfAdults?: number | null
            numberOfChildren?: number | null
            numberOfInfants?: number | null
            rateCode?: string | null
            offers: Array<{
              __typename?: 'OfferMeta'
              offerId: number
              date: string
            } | null>
          } | null
        }
      | {
          __typename: 'PrivateTransferItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'PrivateTransferMeta'
            arrivalTime?: any | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'ProductItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ProductMeta'
            bookingRef?: string | null
          } | null
        }
      | {
          __typename: 'TransportationItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          reservationStatus?: string | null
          meta?: {
            __typename?: 'TransportationMeta'
            inboundPickupLocationId?: string | null
            inboundDepartureTime?: any | null
            inboundComponentId?: string | null
            outboundDropoffLocationId?: string | null
            outboundComponentId?: string | null
            outboundDepartureTime?: any | null
          } | null
        }
    >
    membership?: {
      __typename?: 'Membership'
      membershipToken?: string | null
      type?: string | null
      subType?: string | null
    } | null
    giftCards?: Array<{
      __typename?: 'GiftCardItem'
      no: string
      balance: number
      chargeAmount: number
    } | null> | null
    customer?: {
      __typename?: 'CustomerInformationType'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      nationality?: string | null
      newsletter?: boolean | null
    } | null
  }
}

export type AddHotelProductToCartMutationVariables = Exact<{
  input: AddProductToCartInput
}>

export type AddHotelProductToCartMutation = {
  __typename?: 'Mutation'
  addProductToCart: {
    __typename?: 'Cart'
    id: string
    totalAmount: number
    discountedAmount: number
    giftCardAmount: number
    refundAmount: number
    paymentRequired: boolean
    paymentAmount?: number | null
    currency: string
    promoCode?: string | null
    items: Array<
      | {
          __typename: 'ActivityItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ActivityMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'AdmissionItem'
          id: string
          productId: string
          productType?: string | null
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'AdmissionItemMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
          } | null
        }
      | {
          __typename: 'HotelRoomItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          packageTitle?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          offer?: {
            __typename?: 'RoomOffer'
            productId: string
            rateCodes: any
            fields?: any | null
          } | null
          meta?: {
            __typename?: 'HotelMeta'
            arrivalDate: any
            departureDate: any
            groupCode?: string | null
            numberOfAdults?: number | null
            numberOfChildren?: number | null
            numberOfInfants?: number | null
            rateCode?: string | null
            offers: Array<{
              __typename?: 'OfferMeta'
              offerId: number
              date: string
            } | null>
          } | null
        }
      | {
          __typename: 'PrivateTransferItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'PrivateTransferMeta'
            arrivalTime?: any | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'ProductItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ProductMeta'
            bookingRef?: string | null
          } | null
        }
      | {
          __typename: 'TransportationItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          reservationStatus?: string | null
          meta?: {
            __typename?: 'TransportationMeta'
            inboundPickupLocationId?: string | null
            inboundDepartureTime?: any | null
            inboundComponentId?: string | null
            outboundDropoffLocationId?: string | null
            outboundComponentId?: string | null
            outboundDepartureTime?: any | null
          } | null
        }
    >
    membership?: {
      __typename?: 'Membership'
      membershipToken?: string | null
      type?: string | null
      subType?: string | null
    } | null
    giftCards?: Array<{
      __typename?: 'GiftCardItem'
      no: string
      balance: number
      chargeAmount: number
    } | null> | null
    customer?: {
      __typename?: 'CustomerInformationType'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      nationality?: string | null
      newsletter?: boolean | null
    } | null
  }
}

export type AddHotelRoomToCartMutationVariables = Exact<{
  input: AddHotelRoomToCartInput
}>

export type AddHotelRoomToCartMutation = {
  __typename?: 'Mutation'
  addHotelRoomToCart: {
    __typename?: 'HotelRoomItem'
    id: string
    productId: string
    qty: number
    displayName?: string | null
    packageTitle?: string | null
    fullName?: string | null
    price: number
    discountedPrice: number
    linePrice: number
    totalLinePrice: number
    fields?: any | null
    type: CartItemType
    offer?: {
      __typename?: 'RoomOffer'
      productId: string
      rateCodes: any
      fields?: any | null
    } | null
    meta?: {
      __typename?: 'HotelMeta'
      arrivalDate: any
      departureDate: any
      groupCode?: string | null
      numberOfAdults?: number | null
      numberOfChildren?: number | null
      numberOfInfants?: number | null
      rateCode?: string | null
      offers: Array<{
        __typename?: 'OfferMeta'
        offerId: number
        date: string
      } | null>
    } | null
  }
}

export type AddMembershipTokenToCartMutationVariables = Exact<{
  input: AddMembershipTokenInput
}>

export type AddMembershipTokenToCartMutation = {
  __typename?: 'Mutation'
  addMembershipToken: {
    __typename?: 'Cart'
    id: string
    totalAmount: number
    discountedAmount: number
    giftCardAmount: number
    refundAmount: number
    paymentRequired: boolean
    paymentAmount?: number | null
    currency: string
    promoCode?: string | null
    items: Array<
      | {
          __typename: 'ActivityItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ActivityMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'AdmissionItem'
          id: string
          productId: string
          productType?: string | null
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'AdmissionItemMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
          } | null
        }
      | {
          __typename: 'HotelRoomItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          packageTitle?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          offer?: {
            __typename?: 'RoomOffer'
            productId: string
            rateCodes: any
            fields?: any | null
          } | null
          meta?: {
            __typename?: 'HotelMeta'
            arrivalDate: any
            departureDate: any
            groupCode?: string | null
            numberOfAdults?: number | null
            numberOfChildren?: number | null
            numberOfInfants?: number | null
            rateCode?: string | null
            offers: Array<{
              __typename?: 'OfferMeta'
              offerId: number
              date: string
            } | null>
          } | null
        }
      | {
          __typename: 'PrivateTransferItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'PrivateTransferMeta'
            arrivalTime?: any | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'ProductItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ProductMeta'
            bookingRef?: string | null
          } | null
        }
      | {
          __typename: 'TransportationItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          reservationStatus?: string | null
          meta?: {
            __typename?: 'TransportationMeta'
            inboundPickupLocationId?: string | null
            inboundDepartureTime?: any | null
            inboundComponentId?: string | null
            outboundDropoffLocationId?: string | null
            outboundComponentId?: string | null
            outboundDepartureTime?: any | null
          } | null
        }
    >
    membership?: {
      __typename?: 'Membership'
      membershipToken?: string | null
      type?: string | null
      subType?: string | null
    } | null
    giftCards?: Array<{
      __typename?: 'GiftCardItem'
      no: string
      balance: number
      chargeAmount: number
    } | null> | null
    customer?: {
      __typename?: 'CustomerInformationType'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      nationality?: string | null
      newsletter?: boolean | null
    } | null
  }
}

export type AddProductToCartMutationVariables = Exact<{
  input: AddProductToCartInput
}>

export type AddProductToCartMutation = {
  __typename?: 'Mutation'
  addProductToCart: {
    __typename?: 'Cart'
    id: string
    totalAmount: number
    discountedAmount: number
    giftCardAmount: number
    refundAmount: number
    paymentRequired: boolean
    paymentAmount?: number | null
    currency: string
    promoCode?: string | null
    items: Array<
      | {
          __typename: 'ActivityItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ActivityMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'AdmissionItem'
          id: string
          productId: string
          productType?: string | null
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'AdmissionItemMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
          } | null
        }
      | {
          __typename: 'HotelRoomItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          packageTitle?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          offer?: {
            __typename?: 'RoomOffer'
            productId: string
            rateCodes: any
            fields?: any | null
          } | null
          meta?: {
            __typename?: 'HotelMeta'
            arrivalDate: any
            departureDate: any
            groupCode?: string | null
            numberOfAdults?: number | null
            numberOfChildren?: number | null
            numberOfInfants?: number | null
            rateCode?: string | null
            offers: Array<{
              __typename?: 'OfferMeta'
              offerId: number
              date: string
            } | null>
          } | null
        }
      | {
          __typename: 'PrivateTransferItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'PrivateTransferMeta'
            arrivalTime?: any | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'ProductItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ProductMeta'
            bookingRef?: string | null
          } | null
        }
      | {
          __typename: 'TransportationItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          reservationStatus?: string | null
          meta?: {
            __typename?: 'TransportationMeta'
            inboundPickupLocationId?: string | null
            inboundDepartureTime?: any | null
            inboundComponentId?: string | null
            outboundDropoffLocationId?: string | null
            outboundComponentId?: string | null
            outboundDepartureTime?: any | null
          } | null
        }
    >
    membership?: {
      __typename?: 'Membership'
      membershipToken?: string | null
      type?: string | null
      subType?: string | null
    } | null
    giftCards?: Array<{
      __typename?: 'GiftCardItem'
      no: string
      balance: number
      chargeAmount: number
    } | null> | null
    customer?: {
      __typename?: 'CustomerInformationType'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      nationality?: string | null
      newsletter?: boolean | null
    } | null
  }
}

export type AddPromoCodeToCartMutationVariables = Exact<{
  input: AddPromoCodeInput
}>

export type AddPromoCodeToCartMutation = {
  __typename?: 'Mutation'
  addPromoCode: {
    __typename?: 'Cart'
    id: string
    totalAmount: number
    discountedAmount: number
    giftCardAmount: number
    refundAmount: number
    paymentRequired: boolean
    paymentAmount?: number | null
    currency: string
    promoCode?: string | null
    items: Array<
      | {
          __typename: 'ActivityItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ActivityMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'AdmissionItem'
          id: string
          productId: string
          productType?: string | null
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'AdmissionItemMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
          } | null
        }
      | {
          __typename: 'HotelRoomItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          packageTitle?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          offer?: {
            __typename?: 'RoomOffer'
            productId: string
            rateCodes: any
            fields?: any | null
          } | null
          meta?: {
            __typename?: 'HotelMeta'
            arrivalDate: any
            departureDate: any
            groupCode?: string | null
            numberOfAdults?: number | null
            numberOfChildren?: number | null
            numberOfInfants?: number | null
            rateCode?: string | null
            offers: Array<{
              __typename?: 'OfferMeta'
              offerId: number
              date: string
            } | null>
          } | null
        }
      | {
          __typename: 'PrivateTransferItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'PrivateTransferMeta'
            arrivalTime?: any | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'ProductItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ProductMeta'
            bookingRef?: string | null
          } | null
        }
      | {
          __typename: 'TransportationItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          reservationStatus?: string | null
          meta?: {
            __typename?: 'TransportationMeta'
            inboundPickupLocationId?: string | null
            inboundDepartureTime?: any | null
            inboundComponentId?: string | null
            outboundDropoffLocationId?: string | null
            outboundComponentId?: string | null
            outboundDepartureTime?: any | null
          } | null
        }
    >
    membership?: {
      __typename?: 'Membership'
      membershipToken?: string | null
      type?: string | null
      subType?: string | null
    } | null
    giftCards?: Array<{
      __typename?: 'GiftCardItem'
      no: string
      balance: number
      chargeAmount: number
    } | null> | null
    customer?: {
      __typename?: 'CustomerInformationType'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      nationality?: string | null
      newsletter?: boolean | null
    } | null
  }
}

export type AddSpaCustomerInfoMutationVariables = Exact<{
  input: CustomerInformationInput
}>

export type AddSpaCustomerInfoMutation = {
  __typename?: 'Mutation'
  addSpaCustomerInfo: {
    __typename?: 'Cart'
    id: string
    totalAmount: number
    discountedAmount: number
    giftCardAmount: number
    refundAmount: number
    paymentRequired: boolean
    paymentAmount?: number | null
    currency: string
    promoCode?: string | null
    items: Array<
      | {
          __typename: 'ActivityItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ActivityMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'AdmissionItem'
          id: string
          productId: string
          productType?: string | null
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'AdmissionItemMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
          } | null
        }
      | {
          __typename: 'HotelRoomItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          packageTitle?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          offer?: {
            __typename?: 'RoomOffer'
            productId: string
            rateCodes: any
            fields?: any | null
          } | null
          meta?: {
            __typename?: 'HotelMeta'
            arrivalDate: any
            departureDate: any
            groupCode?: string | null
            numberOfAdults?: number | null
            numberOfChildren?: number | null
            numberOfInfants?: number | null
            rateCode?: string | null
            offers: Array<{
              __typename?: 'OfferMeta'
              offerId: number
              date: string
            } | null>
          } | null
        }
      | {
          __typename: 'PrivateTransferItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'PrivateTransferMeta'
            arrivalTime?: any | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'ProductItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ProductMeta'
            bookingRef?: string | null
          } | null
        }
      | {
          __typename: 'TransportationItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          reservationStatus?: string | null
          meta?: {
            __typename?: 'TransportationMeta'
            inboundPickupLocationId?: string | null
            inboundDepartureTime?: any | null
            inboundComponentId?: string | null
            outboundDropoffLocationId?: string | null
            outboundComponentId?: string | null
            outboundDepartureTime?: any | null
          } | null
        }
    >
    membership?: {
      __typename?: 'Membership'
      membershipToken?: string | null
      type?: string | null
      subType?: string | null
    } | null
    giftCards?: Array<{
      __typename?: 'GiftCardItem'
      no: string
      balance: number
      chargeAmount: number
    } | null> | null
    customer?: {
      __typename?: 'CustomerInformationType'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      nationality?: string | null
      newsletter?: boolean | null
    } | null
  }
}

export type CheckoutMutationVariables = Exact<{
  input: CheckoutInput
}>

export type CheckoutMutation = {
  __typename?: 'Mutation'
  checkout: {
    __typename?: 'HotelConfirmation'
    hotelGroupConfirmationCode?: string | null
    hotelConfirmationCodes: Array<string | null>
  }
}

export type CheckoutPaymentMutationVariables = Exact<{
  input: CheckoutPaymentInput
}>

export type CheckoutPaymentMutation = {
  __typename?: 'Mutation'
  checkoutPayment: {
    __typename?: 'CheckoutPaymentDetail'
    bookingNumber?: string | null
  }
}

export type CreateCartMutationVariables = Exact<{
  input: CreateCartInput
}>

export type CreateCartMutation = {
  __typename?: 'Mutation'
  createCart: {
    __typename?: 'Cart'
    id: string
    totalAmount: number
    discountedAmount: number
    giftCardAmount: number
    refundAmount: number
    paymentRequired: boolean
    paymentAmount?: number | null
    currency: string
    promoCode?: string | null
    items: Array<
      | {
          __typename: 'ActivityItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ActivityMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'AdmissionItem'
          id: string
          productId: string
          productType?: string | null
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'AdmissionItemMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
          } | null
        }
      | {
          __typename: 'HotelRoomItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          packageTitle?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          offer?: {
            __typename?: 'RoomOffer'
            productId: string
            rateCodes: any
            fields?: any | null
          } | null
          meta?: {
            __typename?: 'HotelMeta'
            arrivalDate: any
            departureDate: any
            groupCode?: string | null
            numberOfAdults?: number | null
            numberOfChildren?: number | null
            numberOfInfants?: number | null
            rateCode?: string | null
            offers: Array<{
              __typename?: 'OfferMeta'
              offerId: number
              date: string
            } | null>
          } | null
        }
      | {
          __typename: 'PrivateTransferItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'PrivateTransferMeta'
            arrivalTime?: any | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'ProductItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ProductMeta'
            bookingRef?: string | null
          } | null
        }
      | {
          __typename: 'TransportationItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          reservationStatus?: string | null
          meta?: {
            __typename?: 'TransportationMeta'
            inboundPickupLocationId?: string | null
            inboundDepartureTime?: any | null
            inboundComponentId?: string | null
            outboundDropoffLocationId?: string | null
            outboundComponentId?: string | null
            outboundDepartureTime?: any | null
          } | null
        }
    >
    membership?: {
      __typename?: 'Membership'
      membershipToken?: string | null
      type?: string | null
      subType?: string | null
    } | null
    giftCards?: Array<{
      __typename?: 'GiftCardItem'
      no: string
      balance: number
      chargeAmount: number
    } | null> | null
    customer?: {
      __typename?: 'CustomerInformationType'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      nationality?: string | null
      newsletter?: boolean | null
    } | null
  }
}

export type CreatePaymentTransactionMutationVariables = Exact<{
  input: TransactionInput
}>

export type CreatePaymentTransactionMutation = {
  __typename?: 'Mutation'
  createPaymentTransaction: {
    __typename?: 'TransactionDetail'
    transactionId?: string | null
    paymentId?: number | null
    cart?: {
      __typename?: 'Cart'
      id: string
      totalAmount: number
      discountedAmount: number
      giftCardAmount: number
      refundAmount: number
      paymentRequired: boolean
      paymentAmount?: number | null
      currency: string
      promoCode?: string | null
      items: Array<
        | {
            __typename: 'ActivityItem'
            id: string
            productId: string
            qty: number
            displayName?: string | null
            fullName?: string | null
            price: number
            discountedPrice: number
            linePrice: number
            totalLinePrice: number
            fields?: any | null
            type: CartItemType
            meta?: {
              __typename?: 'ActivityMeta'
              arrivalTime?: any | null
              noOfPersons?: number | null
              comment?: string | null
            } | null
          }
        | {
            __typename: 'AdmissionItem'
            id: string
            productId: string
            productType?: string | null
            qty: number
            displayName?: string | null
            fullName?: string | null
            price: number
            discountedPrice: number
            linePrice: number
            totalLinePrice: number
            fields?: any | null
            type: CartItemType
            meta?: {
              __typename?: 'AdmissionItemMeta'
              arrivalTime?: any | null
              noOfPersons?: number | null
            } | null
          }
        | {
            __typename: 'HotelRoomItem'
            id: string
            productId: string
            qty: number
            displayName?: string | null
            packageTitle?: string | null
            fullName?: string | null
            price: number
            discountedPrice: number
            linePrice: number
            totalLinePrice: number
            fields?: any | null
            type: CartItemType
            offer?: {
              __typename?: 'RoomOffer'
              productId: string
              rateCodes: any
              fields?: any | null
            } | null
            meta?: {
              __typename?: 'HotelMeta'
              arrivalDate: any
              departureDate: any
              groupCode?: string | null
              numberOfAdults?: number | null
              numberOfChildren?: number | null
              numberOfInfants?: number | null
              rateCode?: string | null
              offers: Array<{
                __typename?: 'OfferMeta'
                offerId: number
                date: string
              } | null>
            } | null
          }
        | {
            __typename: 'PrivateTransferItem'
            id: string
            productId: string
            qty: number
            displayName?: string | null
            fullName?: string | null
            price: number
            discountedPrice: number
            linePrice: number
            totalLinePrice: number
            fields?: any | null
            type: CartItemType
            meta?: {
              __typename?: 'PrivateTransferMeta'
              arrivalTime?: any | null
              comment?: string | null
            } | null
          }
        | {
            __typename: 'ProductItem'
            id: string
            productId: string
            qty: number
            displayName?: string | null
            fullName?: string | null
            price: number
            discountedPrice: number
            linePrice: number
            totalLinePrice: number
            fields?: any | null
            type: CartItemType
            meta?: {
              __typename?: 'ProductMeta'
              bookingRef?: string | null
            } | null
          }
        | {
            __typename: 'TransportationItem'
            id: string
            productId: string
            qty: number
            displayName?: string | null
            fullName?: string | null
            price: number
            discountedPrice: number
            linePrice: number
            totalLinePrice: number
            fields?: any | null
            type: CartItemType
            reservationStatus?: string | null
            meta?: {
              __typename?: 'TransportationMeta'
              inboundPickupLocationId?: string | null
              inboundDepartureTime?: any | null
              inboundComponentId?: string | null
              outboundDropoffLocationId?: string | null
              outboundComponentId?: string | null
              outboundDepartureTime?: any | null
            } | null
          }
      >
      membership?: {
        __typename?: 'Membership'
        membershipToken?: string | null
        type?: string | null
        subType?: string | null
      } | null
      giftCards?: Array<{
        __typename?: 'GiftCardItem'
        no: string
        balance: number
        chargeAmount: number
      } | null> | null
      customer?: {
        __typename?: 'CustomerInformationType'
        firstName?: string | null
        lastName?: string | null
        email?: string | null
        phone?: string | null
        nationality?: string | null
        newsletter?: boolean | null
      } | null
    } | null
  }
}

export type HotelRoomCartItemFragment = {
  __typename?: 'HotelRoomItem'
  id: string
  productId: string
  qty: number
  displayName?: string | null
  packageTitle?: string | null
  fullName?: string | null
  price: number
  discountedPrice: number
  linePrice: number
  totalLinePrice: number
  fields?: any | null
  type: CartItemType
  offer?: {
    __typename?: 'RoomOffer'
    productId: string
    rateCodes: any
    fields?: any | null
  } | null
  meta?: {
    __typename?: 'HotelMeta'
    arrivalDate: any
    departureDate: any
    groupCode?: string | null
    numberOfAdults?: number | null
    numberOfChildren?: number | null
    numberOfInfants?: number | null
    rateCode?: string | null
    offers: Array<{
      __typename?: 'OfferMeta'
      offerId: number
      date: string
    } | null>
  } | null
}

export type ProductCartItemFragment = {
  __typename?: 'ProductItem'
  id: string
  productId: string
  qty: number
  displayName?: string | null
  fullName?: string | null
  price: number
  discountedPrice: number
  linePrice: number
  totalLinePrice: number
  fields?: any | null
  type: CartItemType
  meta?: { __typename?: 'ProductMeta'; bookingRef?: string | null } | null
}

export type ActivityCartItemFragment = {
  __typename?: 'ActivityItem'
  id: string
  productId: string
  qty: number
  displayName?: string | null
  fullName?: string | null
  price: number
  discountedPrice: number
  linePrice: number
  totalLinePrice: number
  fields?: any | null
  type: CartItemType
  meta?: {
    __typename?: 'ActivityMeta'
    arrivalTime?: any | null
    noOfPersons?: number | null
    comment?: string | null
  } | null
}

export type PrivateTransferCartItemFragment = {
  __typename?: 'PrivateTransferItem'
  id: string
  productId: string
  qty: number
  displayName?: string | null
  fullName?: string | null
  price: number
  discountedPrice: number
  linePrice: number
  totalLinePrice: number
  fields?: any | null
  type: CartItemType
  meta?: {
    __typename?: 'PrivateTransferMeta'
    arrivalTime?: any | null
    comment?: string | null
  } | null
}

export type TransportationCartItemFragment = {
  __typename?: 'TransportationItem'
  id: string
  productId: string
  qty: number
  displayName?: string | null
  fullName?: string | null
  price: number
  discountedPrice: number
  linePrice: number
  totalLinePrice: number
  fields?: any | null
  type: CartItemType
  reservationStatus?: string | null
  meta?: {
    __typename?: 'TransportationMeta'
    inboundPickupLocationId?: string | null
    inboundDepartureTime?: any | null
    inboundComponentId?: string | null
    outboundDropoffLocationId?: string | null
    outboundComponentId?: string | null
    outboundDepartureTime?: any | null
  } | null
}

export type AdmissionCartItemFragment = {
  __typename?: 'AdmissionItem'
  id: string
  productId: string
  productType?: string | null
  qty: number
  displayName?: string | null
  fullName?: string | null
  price: number
  discountedPrice: number
  linePrice: number
  totalLinePrice: number
  fields?: any | null
  type: CartItemType
  meta?: {
    __typename?: 'AdmissionItemMeta'
    arrivalTime?: any | null
    noOfPersons?: number | null
  } | null
}

export type CartFragmentFragment = {
  __typename?: 'Cart'
  id: string
  totalAmount: number
  discountedAmount: number
  giftCardAmount: number
  refundAmount: number
  paymentRequired: boolean
  paymentAmount?: number | null
  currency: string
  promoCode?: string | null
  items: Array<
    | {
        __typename: 'ActivityItem'
        id: string
        productId: string
        qty: number
        displayName?: string | null
        fullName?: string | null
        price: number
        discountedPrice: number
        linePrice: number
        totalLinePrice: number
        fields?: any | null
        type: CartItemType
        meta?: {
          __typename?: 'ActivityMeta'
          arrivalTime?: any | null
          noOfPersons?: number | null
          comment?: string | null
        } | null
      }
    | {
        __typename: 'AdmissionItem'
        id: string
        productId: string
        productType?: string | null
        qty: number
        displayName?: string | null
        fullName?: string | null
        price: number
        discountedPrice: number
        linePrice: number
        totalLinePrice: number
        fields?: any | null
        type: CartItemType
        meta?: {
          __typename?: 'AdmissionItemMeta'
          arrivalTime?: any | null
          noOfPersons?: number | null
        } | null
      }
    | {
        __typename: 'HotelRoomItem'
        id: string
        productId: string
        qty: number
        displayName?: string | null
        packageTitle?: string | null
        fullName?: string | null
        price: number
        discountedPrice: number
        linePrice: number
        totalLinePrice: number
        fields?: any | null
        type: CartItemType
        offer?: {
          __typename?: 'RoomOffer'
          productId: string
          rateCodes: any
          fields?: any | null
        } | null
        meta?: {
          __typename?: 'HotelMeta'
          arrivalDate: any
          departureDate: any
          groupCode?: string | null
          numberOfAdults?: number | null
          numberOfChildren?: number | null
          numberOfInfants?: number | null
          rateCode?: string | null
          offers: Array<{
            __typename?: 'OfferMeta'
            offerId: number
            date: string
          } | null>
        } | null
      }
    | {
        __typename: 'PrivateTransferItem'
        id: string
        productId: string
        qty: number
        displayName?: string | null
        fullName?: string | null
        price: number
        discountedPrice: number
        linePrice: number
        totalLinePrice: number
        fields?: any | null
        type: CartItemType
        meta?: {
          __typename?: 'PrivateTransferMeta'
          arrivalTime?: any | null
          comment?: string | null
        } | null
      }
    | {
        __typename: 'ProductItem'
        id: string
        productId: string
        qty: number
        displayName?: string | null
        fullName?: string | null
        price: number
        discountedPrice: number
        linePrice: number
        totalLinePrice: number
        fields?: any | null
        type: CartItemType
        meta?: { __typename?: 'ProductMeta'; bookingRef?: string | null } | null
      }
    | {
        __typename: 'TransportationItem'
        id: string
        productId: string
        qty: number
        displayName?: string | null
        fullName?: string | null
        price: number
        discountedPrice: number
        linePrice: number
        totalLinePrice: number
        fields?: any | null
        type: CartItemType
        reservationStatus?: string | null
        meta?: {
          __typename?: 'TransportationMeta'
          inboundPickupLocationId?: string | null
          inboundDepartureTime?: any | null
          inboundComponentId?: string | null
          outboundDropoffLocationId?: string | null
          outboundComponentId?: string | null
          outboundDepartureTime?: any | null
        } | null
      }
  >
  membership?: {
    __typename?: 'Membership'
    membershipToken?: string | null
    type?: string | null
    subType?: string | null
  } | null
  giftCards?: Array<{
    __typename?: 'GiftCardItem'
    no: string
    balance: number
    chargeAmount: number
  } | null> | null
  customer?: {
    __typename?: 'CustomerInformationType'
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phone?: string | null
    nationality?: string | null
    newsletter?: boolean | null
  } | null
}

export type HotelReservationFragmentFragment = {
  __typename?: 'HotelReservation'
  reservationNo: string
  property: string
  roomType: string
  rateCode: string
  adults: number
  children: number
  externalReservationNo: string
  arrivalDate: any
  departureDate: any
  status: string
  groupReservationNo?: string | null
  guestName: string
  email: string
  country: string
  guestNo: string
  customerName?: string | null
  phoneNo: string
  mobilePhoneNo: string
  contactName?: string | null
  nights: number
  checkInTime: string
  checkOutTime: string
  actualCheckInTime?: string | null
  actualCheckOutTime?: string | null
  bookedRoomType: string
  payingRoomType: string
  extraBeds: number
  cribs: number
  cancellationNo?: string | null
  cancellationDateTime?: string | null
  activities: Array<{
    __typename?: 'HotelReservationActivity'
    bookingRef: string
    lineNo: string
    numberOfPersons: number
    quantity: number
    productNo: string
    reservationDate: any
    comment: string
    status: string
    data?: {
      __typename?: 'ActivityExtraInfo'
      adultPrice?: number | null
      pickupTime?: string | null
      departureDate?: any | null
      departureTime?: string | null
      pickupLocationID?: string | null
      pickupLocationName?: string | null
      dropoffLocationID?: string | null
      dropoffLocationName?: string | null
    } | null
  } | null>
}

export type HotelReservationActivityFragmentFragment = {
  __typename?: 'HotelReservationActivity'
  bookingRef: string
  lineNo: string
  numberOfPersons: number
  quantity: number
  productNo: string
  reservationDate: any
  comment: string
  status: string
  data?: {
    __typename?: 'ActivityExtraInfo'
    adultPrice?: number | null
    pickupTime?: string | null
    departureDate?: any | null
    departureTime?: string | null
    pickupLocationID?: string | null
    pickupLocationName?: string | null
    dropoffLocationID?: string | null
    dropoffLocationName?: string | null
  } | null
}

export type RestrictionFragmentFragment = {
  __typename?: 'Restriction'
  dateFrom?: any | null
  dateTo?: any | null
  allDays?: boolean | null
  monday?: boolean | null
  tuesday?: boolean | null
  wednesday?: boolean | null
  thursday?: boolean | null
  friday?: boolean | null
  saturday?: boolean | null
  sunday?: boolean | null
  minimumLeadDays?: number | null
  minimumLengthOfStay?: number | null
  maximumLeadDays?: number | null
  maximumLengthOfStay?: number | null
  sellLimit?: number | null
  property?: string | null
  description?: string | null
  type?: string | null
  rateCode?: string | null
  roomType?: string | null
}

export type GetCartQueryVariables = Exact<{
  input: GetCartInput
}>

export type GetCartQuery = {
  __typename?: 'Query'
  getCart?: {
    __typename?: 'Cart'
    id: string
    totalAmount: number
    discountedAmount: number
    giftCardAmount: number
    refundAmount: number
    paymentRequired: boolean
    paymentAmount?: number | null
    currency: string
    promoCode?: string | null
    items: Array<
      | {
          __typename: 'ActivityItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ActivityMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'AdmissionItem'
          id: string
          productId: string
          productType?: string | null
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'AdmissionItemMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
          } | null
        }
      | {
          __typename: 'HotelRoomItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          packageTitle?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          offer?: {
            __typename?: 'RoomOffer'
            productId: string
            rateCodes: any
            fields?: any | null
          } | null
          meta?: {
            __typename?: 'HotelMeta'
            arrivalDate: any
            departureDate: any
            groupCode?: string | null
            numberOfAdults?: number | null
            numberOfChildren?: number | null
            numberOfInfants?: number | null
            rateCode?: string | null
            offers: Array<{
              __typename?: 'OfferMeta'
              offerId: number
              date: string
            } | null>
          } | null
        }
      | {
          __typename: 'PrivateTransferItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'PrivateTransferMeta'
            arrivalTime?: any | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'ProductItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ProductMeta'
            bookingRef?: string | null
          } | null
        }
      | {
          __typename: 'TransportationItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          reservationStatus?: string | null
          meta?: {
            __typename?: 'TransportationMeta'
            inboundPickupLocationId?: string | null
            inboundDepartureTime?: any | null
            inboundComponentId?: string | null
            outboundDropoffLocationId?: string | null
            outboundComponentId?: string | null
            outboundDepartureTime?: any | null
          } | null
        }
    >
    membership?: {
      __typename?: 'Membership'
      membershipToken?: string | null
      type?: string | null
      subType?: string | null
    } | null
    giftCards?: Array<{
      __typename?: 'GiftCardItem'
      no: string
      balance: number
      chargeAmount: number
    } | null> | null
    customer?: {
      __typename?: 'CustomerInformationType'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      nationality?: string | null
      newsletter?: boolean | null
    } | null
  } | null
}

export type GetCheckoutStatusQueryVariables = Exact<{
  input: CheckoutStatusInput
}>

export type GetCheckoutStatusQuery = {
  __typename?: 'Query'
  getCheckoutStatus: {
    __typename?: 'CheckoutStatus'
    bookingRef?: string | null
    paymentSuccessful: boolean
    confirmSuccessful: boolean
    orderSuccessful: boolean
    busTransferSuccessful: boolean
    finished?: string | null
    error?: string | null
    inProgress: boolean
  }
}

export type ProductsCatalogQueryVariables = Exact<{
  input: ProductsCatalogInput
}>

export type ProductsCatalogQuery = {
  __typename?: 'Query'
  productsCatalog: Array<{
    __typename?: 'Product'
    productId: string
    productType?: string | null
    productSubType?: string | null
    fullName: string
    displayName: string
    company: string
    property?: string | null
    location?: string | null
    price: number
    fixedDiscountPercentage?: number | null
    discountedPrice?: number | null
    availabilityBasedPricing: boolean
    roomType?: string | null
  } | null>
}

export type ProductsAvailabilityQueryVariables = Exact<{
  input: ProductAvailabilitiesInput
}>

export type ProductsAvailabilityQuery = {
  __typename?: 'Query'
  productAvailabilities: Array<{
    __typename?: 'ProductsOffer'
    productId: string
    available: number
    lengthOfStay?: number | null
    price: number
    fields?: any | null
    time?: any | null
    offerId?: number | null
    subOffers?: Array<{
      __typename?: 'SubOffer'
      offerId: number
      date?: any | null
      price: number
    } | null> | null
  } | null>
}

export type RemoveGiftCardFromCartMutationVariables = Exact<{
  input: RemoveGiftCardInput
}>

export type RemoveGiftCardFromCartMutation = {
  __typename?: 'Mutation'
  removeGiftCard: {
    __typename?: 'Cart'
    id: string
    totalAmount: number
    discountedAmount: number
    giftCardAmount: number
    refundAmount: number
    paymentRequired: boolean
    paymentAmount?: number | null
    currency: string
    promoCode?: string | null
    items: Array<
      | {
          __typename: 'ActivityItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ActivityMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'AdmissionItem'
          id: string
          productId: string
          productType?: string | null
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'AdmissionItemMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
          } | null
        }
      | {
          __typename: 'HotelRoomItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          packageTitle?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          offer?: {
            __typename?: 'RoomOffer'
            productId: string
            rateCodes: any
            fields?: any | null
          } | null
          meta?: {
            __typename?: 'HotelMeta'
            arrivalDate: any
            departureDate: any
            groupCode?: string | null
            numberOfAdults?: number | null
            numberOfChildren?: number | null
            numberOfInfants?: number | null
            rateCode?: string | null
            offers: Array<{
              __typename?: 'OfferMeta'
              offerId: number
              date: string
            } | null>
          } | null
        }
      | {
          __typename: 'PrivateTransferItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'PrivateTransferMeta'
            arrivalTime?: any | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'ProductItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ProductMeta'
            bookingRef?: string | null
          } | null
        }
      | {
          __typename: 'TransportationItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          reservationStatus?: string | null
          meta?: {
            __typename?: 'TransportationMeta'
            inboundPickupLocationId?: string | null
            inboundDepartureTime?: any | null
            inboundComponentId?: string | null
            outboundDropoffLocationId?: string | null
            outboundComponentId?: string | null
            outboundDepartureTime?: any | null
          } | null
        }
    >
    membership?: {
      __typename?: 'Membership'
      membershipToken?: string | null
      type?: string | null
      subType?: string | null
    } | null
    giftCards?: Array<{
      __typename?: 'GiftCardItem'
      no: string
      balance: number
      chargeAmount: number
    } | null> | null
    customer?: {
      __typename?: 'CustomerInformationType'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      nationality?: string | null
      newsletter?: boolean | null
    } | null
  }
}

export type RemoveItemFromCartMutationVariables = Exact<{
  input: RemoveCartItemInput
}>

export type RemoveItemFromCartMutation = {
  __typename?: 'Mutation'
  removeItem: {
    __typename?: 'Cart'
    id: string
    totalAmount: number
    discountedAmount: number
    giftCardAmount: number
    refundAmount: number
    paymentRequired: boolean
    paymentAmount?: number | null
    currency: string
    promoCode?: string | null
    items: Array<
      | {
          __typename: 'ActivityItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ActivityMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'AdmissionItem'
          id: string
          productId: string
          productType?: string | null
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'AdmissionItemMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
          } | null
        }
      | {
          __typename: 'HotelRoomItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          packageTitle?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          offer?: {
            __typename?: 'RoomOffer'
            productId: string
            rateCodes: any
            fields?: any | null
          } | null
          meta?: {
            __typename?: 'HotelMeta'
            arrivalDate: any
            departureDate: any
            groupCode?: string | null
            numberOfAdults?: number | null
            numberOfChildren?: number | null
            numberOfInfants?: number | null
            rateCode?: string | null
            offers: Array<{
              __typename?: 'OfferMeta'
              offerId: number
              date: string
            } | null>
          } | null
        }
      | {
          __typename: 'PrivateTransferItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'PrivateTransferMeta'
            arrivalTime?: any | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'ProductItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ProductMeta'
            bookingRef?: string | null
          } | null
        }
      | {
          __typename: 'TransportationItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          reservationStatus?: string | null
          meta?: {
            __typename?: 'TransportationMeta'
            inboundPickupLocationId?: string | null
            inboundDepartureTime?: any | null
            inboundComponentId?: string | null
            outboundDropoffLocationId?: string | null
            outboundComponentId?: string | null
            outboundDepartureTime?: any | null
          } | null
        }
    >
    membership?: {
      __typename?: 'Membership'
      membershipToken?: string | null
      type?: string | null
      subType?: string | null
    } | null
    giftCards?: Array<{
      __typename?: 'GiftCardItem'
      no: string
      balance: number
      chargeAmount: number
    } | null> | null
    customer?: {
      __typename?: 'CustomerInformationType'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      nationality?: string | null
      newsletter?: boolean | null
    } | null
  }
}

export type RemoveMembershipTokenFromCartMutationVariables = Exact<{
  input: RemoveMembershipTokenInput
}>

export type RemoveMembershipTokenFromCartMutation = {
  __typename?: 'Mutation'
  removeMembershipToken: {
    __typename?: 'Cart'
    id: string
    totalAmount: number
    discountedAmount: number
    giftCardAmount: number
    refundAmount: number
    paymentRequired: boolean
    paymentAmount?: number | null
    currency: string
    promoCode?: string | null
    items: Array<
      | {
          __typename: 'ActivityItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ActivityMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'AdmissionItem'
          id: string
          productId: string
          productType?: string | null
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'AdmissionItemMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
          } | null
        }
      | {
          __typename: 'HotelRoomItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          packageTitle?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          offer?: {
            __typename?: 'RoomOffer'
            productId: string
            rateCodes: any
            fields?: any | null
          } | null
          meta?: {
            __typename?: 'HotelMeta'
            arrivalDate: any
            departureDate: any
            groupCode?: string | null
            numberOfAdults?: number | null
            numberOfChildren?: number | null
            numberOfInfants?: number | null
            rateCode?: string | null
            offers: Array<{
              __typename?: 'OfferMeta'
              offerId: number
              date: string
            } | null>
          } | null
        }
      | {
          __typename: 'PrivateTransferItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'PrivateTransferMeta'
            arrivalTime?: any | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'ProductItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ProductMeta'
            bookingRef?: string | null
          } | null
        }
      | {
          __typename: 'TransportationItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          reservationStatus?: string | null
          meta?: {
            __typename?: 'TransportationMeta'
            inboundPickupLocationId?: string | null
            inboundDepartureTime?: any | null
            inboundComponentId?: string | null
            outboundDropoffLocationId?: string | null
            outboundComponentId?: string | null
            outboundDepartureTime?: any | null
          } | null
        }
    >
    membership?: {
      __typename?: 'Membership'
      membershipToken?: string | null
      type?: string | null
      subType?: string | null
    } | null
    giftCards?: Array<{
      __typename?: 'GiftCardItem'
      no: string
      balance: number
      chargeAmount: number
    } | null> | null
    customer?: {
      __typename?: 'CustomerInformationType'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      nationality?: string | null
      newsletter?: boolean | null
    } | null
  }
}

export type ResetCartMutationVariables = Exact<{
  input: ResetCartInput
}>

export type ResetCartMutation = {
  __typename?: 'Mutation'
  resetCart: {
    __typename?: 'Cart'
    id: string
    totalAmount: number
    discountedAmount: number
    giftCardAmount: number
    refundAmount: number
    paymentRequired: boolean
    paymentAmount?: number | null
    currency: string
    promoCode?: string | null
    items: Array<
      | {
          __typename: 'ActivityItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ActivityMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'AdmissionItem'
          id: string
          productId: string
          productType?: string | null
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'AdmissionItemMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
          } | null
        }
      | {
          __typename: 'HotelRoomItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          packageTitle?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          offer?: {
            __typename?: 'RoomOffer'
            productId: string
            rateCodes: any
            fields?: any | null
          } | null
          meta?: {
            __typename?: 'HotelMeta'
            arrivalDate: any
            departureDate: any
            groupCode?: string | null
            numberOfAdults?: number | null
            numberOfChildren?: number | null
            numberOfInfants?: number | null
            rateCode?: string | null
            offers: Array<{
              __typename?: 'OfferMeta'
              offerId: number
              date: string
            } | null>
          } | null
        }
      | {
          __typename: 'PrivateTransferItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'PrivateTransferMeta'
            arrivalTime?: any | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'ProductItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ProductMeta'
            bookingRef?: string | null
          } | null
        }
      | {
          __typename: 'TransportationItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          reservationStatus?: string | null
          meta?: {
            __typename?: 'TransportationMeta'
            inboundPickupLocationId?: string | null
            inboundDepartureTime?: any | null
            inboundComponentId?: string | null
            outboundDropoffLocationId?: string | null
            outboundComponentId?: string | null
            outboundDepartureTime?: any | null
          } | null
        }
    >
    membership?: {
      __typename?: 'Membership'
      membershipToken?: string | null
      type?: string | null
      subType?: string | null
    } | null
    giftCards?: Array<{
      __typename?: 'GiftCardItem'
      no: string
      balance: number
      chargeAmount: number
    } | null> | null
    customer?: {
      __typename?: 'CustomerInformationType'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      nationality?: string | null
      newsletter?: boolean | null
    } | null
  }
}

export type RoomTypeRestrictionsQueryVariables = Exact<{
  input: RoomTypeRestrictionsInput
}>

export type RoomTypeRestrictionsQuery = {
  __typename?: 'Query'
  roomTypeRestrictions: Array<{
    __typename?: 'Restriction'
    dateFrom?: any | null
    dateTo?: any | null
    allDays?: boolean | null
    monday?: boolean | null
    tuesday?: boolean | null
    wednesday?: boolean | null
    thursday?: boolean | null
    friday?: boolean | null
    saturday?: boolean | null
    sunday?: boolean | null
    minimumLeadDays?: number | null
    minimumLengthOfStay?: number | null
    maximumLeadDays?: number | null
    maximumLengthOfStay?: number | null
    sellLimit?: number | null
    property?: string | null
    description?: string | null
    type?: string | null
    rateCode?: string | null
    roomType?: string | null
  } | null>
}

export type RateCodeRestrictionsQueryVariables = Exact<{
  input: RateCodeRestrictionsInput
}>

export type RateCodeRestrictionsQuery = {
  __typename?: 'Query'
  rateCodeRestrictions: Array<{
    __typename?: 'Restriction'
    dateFrom?: any | null
    dateTo?: any | null
    allDays?: boolean | null
    monday?: boolean | null
    tuesday?: boolean | null
    wednesday?: boolean | null
    thursday?: boolean | null
    friday?: boolean | null
    saturday?: boolean | null
    sunday?: boolean | null
    minimumLeadDays?: number | null
    minimumLengthOfStay?: number | null
    maximumLeadDays?: number | null
    maximumLengthOfStay?: number | null
    sellLimit?: number | null
    property?: string | null
    description?: string | null
    type?: string | null
    rateCode?: string | null
    roomType?: string | null
  } | null>
}

export type RestrictionsQueryVariables = Exact<{
  input: RestrictionsInput
}>

export type RestrictionsQuery = {
  __typename?: 'Query'
  restrictions?: {
    __typename?: 'Restrictions'
    rateCodeRestriction?: any | null
    roomTypeRestriction?: any | null
    propertyRestriction?: any | null
  } | null
}

export type RoomAvailabilityQueryVariables = Exact<{
  input: RoomAvailabilityInput
}>

export type RoomAvailabilityQuery = {
  __typename?: 'Query'
  roomAvailability: Array<{
    __typename?: 'RoomOffer'
    productId: string
    rateCodes: any
    available: number
    lengthOfStay: number
    allowedGuests: number
    allowedAdults: number
    allowedChildren: number
    allowedInfants: number
    price: number
    fields?: any | null
  } | null>
}

export type RoomRangeAvailabilityQueryVariables = Exact<{
  input: RoomRangeAvailabilityInput
}>

export type RoomRangeAvailabilityQuery = {
  __typename?: 'Query'
  roomRangeAvailability: Array<{
    __typename?: 'RoomAvailability'
    date: any
    roomType: string
    property: string
    totalRooms: number
    availableRooms: number
    validArrivalDate?: boolean | null
    validDepartureDate?: boolean | null
  } | null>
}

export type RoomRangeAvailabilityRestrictionsQueryVariables = Exact<{
  input: RoomRangeAvailabilityInput
}>

export type RoomRangeAvailabilityRestrictionsQuery = {
  __typename?: 'Query'
  roomRangeAvailabilityRestrictions: Array<{
    __typename?: 'RoomAvailabilityRestrictions'
    date: any
    roomType: string
    property: string
    totalRooms: number
    availableRooms: number
    validArrivalDate?: boolean | null
    validDepartureDate?: boolean | null
  } | null>
}

export type UpdateHotelRoomCartItemMutationVariables = Exact<{
  input: UpdateHotelRoomCartItemInput
}>

export type UpdateHotelRoomCartItemMutation = {
  __typename?: 'Mutation'
  updateHotelRoomCartItem: {
    __typename?: 'HotelRoomItem'
    id: string
    productId: string
    qty: number
    displayName?: string | null
    packageTitle?: string | null
    fullName?: string | null
    price: number
    discountedPrice: number
    linePrice: number
    totalLinePrice: number
    fields?: any | null
    type: CartItemType
    offer?: {
      __typename?: 'RoomOffer'
      productId: string
      rateCodes: any
      fields?: any | null
    } | null
    meta?: {
      __typename?: 'HotelMeta'
      arrivalDate: any
      departureDate: any
      groupCode?: string | null
      numberOfAdults?: number | null
      numberOfChildren?: number | null
      numberOfInfants?: number | null
      rateCode?: string | null
      offers: Array<{
        __typename?: 'OfferMeta'
        offerId: number
        date: string
      } | null>
    } | null
  }
}

export type UpdateProductCartItemMutationVariables = Exact<{
  input: UpdateProductCartItemInput
}>

export type UpdateProductCartItemMutation = {
  __typename?: 'Mutation'
  updateProductCartItem: {
    __typename?: 'Cart'
    id: string
    totalAmount: number
    discountedAmount: number
    giftCardAmount: number
    refundAmount: number
    paymentRequired: boolean
    paymentAmount?: number | null
    currency: string
    promoCode?: string | null
    items: Array<
      | {
          __typename: 'ActivityItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ActivityMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'AdmissionItem'
          id: string
          productId: string
          productType?: string | null
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'AdmissionItemMeta'
            arrivalTime?: any | null
            noOfPersons?: number | null
          } | null
        }
      | {
          __typename: 'HotelRoomItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          packageTitle?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          offer?: {
            __typename?: 'RoomOffer'
            productId: string
            rateCodes: any
            fields?: any | null
          } | null
          meta?: {
            __typename?: 'HotelMeta'
            arrivalDate: any
            departureDate: any
            groupCode?: string | null
            numberOfAdults?: number | null
            numberOfChildren?: number | null
            numberOfInfants?: number | null
            rateCode?: string | null
            offers: Array<{
              __typename?: 'OfferMeta'
              offerId: number
              date: string
            } | null>
          } | null
        }
      | {
          __typename: 'PrivateTransferItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'PrivateTransferMeta'
            arrivalTime?: any | null
            comment?: string | null
          } | null
        }
      | {
          __typename: 'ProductItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          meta?: {
            __typename?: 'ProductMeta'
            bookingRef?: string | null
          } | null
        }
      | {
          __typename: 'TransportationItem'
          id: string
          productId: string
          qty: number
          displayName?: string | null
          fullName?: string | null
          price: number
          discountedPrice: number
          linePrice: number
          totalLinePrice: number
          fields?: any | null
          type: CartItemType
          reservationStatus?: string | null
          meta?: {
            __typename?: 'TransportationMeta'
            inboundPickupLocationId?: string | null
            inboundDepartureTime?: any | null
            inboundComponentId?: string | null
            outboundDropoffLocationId?: string | null
            outboundComponentId?: string | null
            outboundDepartureTime?: any | null
          } | null
        }
    >
    membership?: {
      __typename?: 'Membership'
      membershipToken?: string | null
      type?: string | null
      subType?: string | null
    } | null
    giftCards?: Array<{
      __typename?: 'GiftCardItem'
      no: string
      balance: number
      chargeAmount: number
    } | null> | null
    customer?: {
      __typename?: 'CustomerInformationType'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      nationality?: string | null
      newsletter?: boolean | null
    } | null
  }
}

export type CreateGiftCardMutationVariables = Exact<{
  info: GiftCardInfo
}>

export type CreateGiftCardMutation = {
  __typename?: 'Mutation'
  createGiftCard: { __typename?: 'CreateGiftCardResponse'; giftCardNo: string }
}

export type FinalizeGiftCardMutationVariables = Exact<{
  info: FinalizeGiftCardInfo
}>

export type FinalizeGiftCardMutation = {
  __typename?: 'Mutation'
  finalizeGiftCard: {
    __typename?: 'FinalizeGiftCardResponse'
    giftCard?: boolean | null
  }
}

export type FindGiftCardQueryVariables = Exact<{
  where: FindGiftCardPayload
}>

export type FindGiftCardQuery = {
  __typename?: 'Query'
  findGiftCard: {
    __typename?: 'FindGiftCardResponse'
    amount?: number | null
    customerName?: string | null
    customerEmail?: string | null
    number?: string | null
    externalDocumentNo?: string | null
    status?: string | null
    reasonBlocked?: string | null
    validTo?: string | null
    recipientName?: string | null
    Message?: string | null
    recipientEmail?: string | null
    recipientAddress?: string | null
    recipientAddress2?: string | null
    recipientPostCode?: string | null
    recipientCountryCode?: string | null
    recipientPhoneNumber?: string | null
    url?: string | null
    title?: string | null
    isExperience?: boolean | null
    giftCardName?: string | null
    shippingMethod?: string | null
    shippingAmount?: number | null
  }
}

export type OrderGiftCardMutationVariables = Exact<{
  info: OrderGiftCardInfo
}>

export type OrderGiftCardMutation = {
  __typename?: 'Mutation'
  orderGiftCard: {
    __typename?: 'OrderGiftCardResponse'
    giftCardNo: string
    acsInfo?: {
      __typename?: 'AcsResponse'
      acsForm: string
      acsFormData?: Array<{
        __typename?: 'AcsFormItem'
        name: string
        value?: string | null
      } | null> | null
    } | null
    tdsmInfo?: { __typename?: 'TdsmInfo'; tdsmContent: string } | null
  }
}

export type ValidateGiftCardAcsMutationVariables = Exact<{
  info: ValidateGiftCardAcsInfo
}>

export type ValidateGiftCardAcsMutation = {
  __typename?: 'Mutation'
  validateGiftCardACS?: {
    __typename?: 'ValidateGiftCardACSResponse'
    giftCardNo: string
  } | null
}

export type ValidateGiftCardTdsmMutationVariables = Exact<{
  info: ValidateGiftCardTdsmInfo
}>

export type ValidateGiftCardTdsmMutation = {
  __typename?: 'Mutation'
  validateGiftCardTDSM?: {
    __typename?: 'ValidateGiftCardTDSMResponse'
    giftCardNo: string
    acsInfo?: {
      __typename?: 'AcsResponse'
      acsForm: string
      acsFormData?: Array<{
        __typename?: 'AcsFormItem'
        name: string
        value?: string | null
      } | null> | null
    } | null
  } | null
}

export type MassageAndFloatAvailabilityQueryVariables = Exact<{
  date: Scalars['String']
  arrivalTime: Scalars['String']
  locale: Scalars['String']
}>

export type MassageAndFloatAvailabilityQuery = {
  __typename?: 'Query'
  massageAndFloatAvailability?: {
    __typename?: 'MassageAndFloatAvailability'
    massages?: Array<{
      __typename?: 'MassageTypeInfo'
      available?: number | null
      duration?: number | null
      id?: string | null
      price?: number | null
      title?: string | null
      type?: string | null
      validForCount?: number | null
      slots?: Array<{
        __typename?: 'MassageSlot'
        available?: number | null
        time?: string | null
      } | null> | null
    } | null> | null
    floats?: Array<{
      __typename?: 'MassageTypeInfo'
      available?: number | null
      duration?: number | null
      id?: string | null
      price?: number | null
      title?: string | null
      type?: string | null
      validForCount?: number | null
      slots?: Array<{
        __typename?: 'MassageSlot'
        available?: number | null
        time?: string | null
      } | null> | null
    } | null> | null
  } | null
}

export type PackageAvailabilityQueryVariables = Exact<{
  date: Scalars['String']
  subscription?: InputMaybe<Scalars['String']>
}>

export type PackageAvailabilityQuery = {
  __typename?: 'Query'
  packageAvailability?: {
    __typename?: 'PackageAvailability'
    date?: any | null
    slots?: Array<{
      __typename?: 'PackageSlot'
      time?: string | null
      packages?: Array<{
        __typename?: 'Package'
        available?: number | null
        price?: number | null
        type?: string | null
        slots?: Array<{
          __typename?: 'RetreatSlots'
          time?: string | null
          available?: number | null
          price?: number | null
          type?: string | null
        } | null> | null
      } | null> | null
    } | null> | null
  } | null
}

export type DayAvailabilityQueryVariables = Exact<{
  startDate: Scalars['String']
  endDate: Scalars['String']
}>

export type DayAvailabilityQuery = {
  __typename?: 'Query'
  dayAvailability?: Array<{
    __typename?: 'DayAvailability'
    available: number
    date: string
    lowestPrice: number
    soldOut: boolean
  } | null> | null
}

export type GetTransactionStatusQueryVariables = Exact<{
  where: TransactionStatusQuery
}>

export type GetTransactionStatusQuery = {
  __typename?: 'Query'
  getTransactionStatus: {
    __typename?: 'TransactionStatusResponse'
    data: Array<{
      __typename?: 'TransactionStatusData'
      refNo: string
      data: {
        __typename?: 'TransactionStatusInfo'
        transactionId: string
        status: string
        refNo: string
        detail?: {
          __typename?: 'TransactionDetail'
          authorize?: {
            __typename?: 'TransactionDetailAuthorize'
            amount: number
          } | null
          fail?: {
            __typename?: 'TransactionDetailFail'
            reason: string
            message: string
          } | null
        } | null
      }
    }>
  }
}

export type InitializePaymentMutationVariables = Exact<{
  body: PaymentInitialize
}>

export type InitializePaymentMutation = {
  __typename?: 'Mutation'
  initializePayment: {
    __typename?: 'PaymentInitializeResponse'
    transactionId: string
  }
}

export type RestaurantAvailabilityQueryVariables = Exact<{
  date: Scalars['String']
}>

export type RestaurantAvailabilityQuery = {
  __typename?: 'Query'
  restaurantAvailability?: {
    __typename?: 'RestaurantAvailability'
    date?: any | null
    slots?: Array<{
      __typename?: 'RestaurantSlot'
      available?: number | null
      time?: string | null
    } | null> | null
  } | null
}

export type TransportationDataQueryVariables = Exact<{ [key: string]: never }>

export type TransportationDataQuery = {
  __typename?: 'Query'
  transportationData?: {
    __typename?: 'TransportationData'
    airport?: {
      __typename?: 'TransportationLocation'
      id?: string | null
      name?: string | null
    } | null
    prices?: {
      __typename?: 'TransportationPrice'
      adultPrice?: number | null
    } | null
  } | null
}

export type DepartureDetailsQueryVariables = Exact<{
  date: Scalars['String']
  arrivalTime?: InputMaybe<Scalars['String']>
}>

export type DepartureDetailsQuery = {
  __typename?: 'Query'
  departureDetails?: {
    __typename?: 'TransportationDetails'
    error?: string | null
    airport?: Array<{
      __typename?: 'AvailabilityDetails'
      availability?: number | null
      time?: string | null
    } | null> | null
    city?: Array<{
      __typename?: 'AvailabilityDetails'
      availability?: number | null
      time?: string | null
    } | null> | null
  } | null
}

export type DirectionsQueryVariables = Exact<{
  startLatitude: Scalars['Float']
  startLongitude: Scalars['Float']
  endLatitude: Scalars['Float']
  endLongitude: Scalars['Float']
}>

export type DirectionsQuery = {
  __typename?: 'Query'
  directions?: {
    __typename?: 'Directions'
    coordinates?: Array<Array<number | null> | null> | null
  } | null
}

export type DropoffLocationsQueryVariables = Exact<{ [key: string]: never }>

export type DropoffLocationsQuery = {
  __typename?: 'Query'
  dropoffLocations?: Array<{
    __typename?: 'TransportationLocation'
    id?: string | null
    name?: string | null
    latitude?: number | null
    longitude?: number | null
    bus?: number | null
    address?: string | null
    busStop?: {
      __typename?: 'BusStop'
      id: string
      name: string
      number?: number | null
      address?: string | null
      latitude?: number | null
      longitude?: number | null
    } | null
  } | null> | null
}

export type PickupDetailsQueryVariables = Exact<{
  date: Scalars['String']
  arrivalTime?: InputMaybe<Scalars['String']>
}>

export type PickupDetailsQuery = {
  __typename?: 'Query'
  pickupDetails?: {
    __typename?: 'TransportationDetails'
    error?: string | null
    airport?: Array<{
      __typename?: 'AvailabilityDetails'
      availability?: number | null
      time?: string | null
    } | null> | null
    city?: Array<{
      __typename?: 'AvailabilityDetails'
      availability?: number | null
      time?: string | null
    } | null> | null
  } | null
}

export type PickupLocationsQueryVariables = Exact<{ [key: string]: never }>

export type PickupLocationsQuery = {
  __typename?: 'Query'
  pickupLocations?: Array<{
    __typename?: 'TransportationLocation'
    id?: string | null
    name?: string | null
    latitude?: number | null
    longitude?: number | null
    bus?: number | null
    address?: string | null
    busStop?: {
      __typename?: 'BusStop'
      id: string
      name: string
      number?: number | null
      address?: string | null
      latitude?: number | null
      longitude?: number | null
    } | null
  } | null> | null
}

export const HotelRoomCartItemFragmentDoc = gql`
  fragment HotelRoomCartItem on HotelRoomItem {
    id
    productId
    qty
    displayName
    packageTitle
    fullName
    price
    discountedPrice
    linePrice
    totalLinePrice
    fields
    type
    offer {
      productId
      rateCodes
      fields
    }
    meta {
      arrivalDate
      departureDate
      groupCode
      numberOfAdults
      numberOfChildren
      numberOfInfants
      rateCode
      offers {
        offerId
        date
      }
    }
  }
`
export const ProductCartItemFragmentDoc = gql`
  fragment ProductCartItem on ProductItem {
    id
    productId
    qty
    displayName
    fullName
    price
    discountedPrice
    linePrice
    totalLinePrice
    fields
    type
    meta {
      bookingRef
    }
  }
`
export const ActivityCartItemFragmentDoc = gql`
  fragment ActivityCartItem on ActivityItem {
    id
    productId
    qty
    displayName
    fullName
    price
    discountedPrice
    linePrice
    totalLinePrice
    fields
    type
    meta {
      arrivalTime
      noOfPersons
      comment
    }
  }
`
export const PrivateTransferCartItemFragmentDoc = gql`
  fragment PrivateTransferCartItem on PrivateTransferItem {
    id
    productId
    qty
    displayName
    fullName
    price
    discountedPrice
    linePrice
    totalLinePrice
    fields
    type
    meta {
      arrivalTime
      comment
    }
  }
`
export const AdmissionCartItemFragmentDoc = gql`
  fragment AdmissionCartItem on AdmissionItem {
    id
    productId
    productType
    qty
    displayName
    fullName
    price
    discountedPrice
    linePrice
    totalLinePrice
    fields
    type
    meta {
      arrivalTime
      noOfPersons
    }
  }
`
export const TransportationCartItemFragmentDoc = gql`
  fragment TransportationCartItem on TransportationItem {
    id
    productId
    qty
    displayName
    fullName
    price
    discountedPrice
    linePrice
    totalLinePrice
    fields
    type
    reservationStatus
    meta {
      inboundPickupLocationId
      inboundDepartureTime
      inboundComponentId
      outboundDropoffLocationId
      outboundComponentId
      outboundDepartureTime
    }
  }
`
export const CartFragmentFragmentDoc = gql`
  fragment CartFragment on Cart {
    id
    items {
      __typename
      ...HotelRoomCartItem
      ...ProductCartItem
      ...ActivityCartItem
      ...PrivateTransferCartItem
      ...AdmissionCartItem
      ...TransportationCartItem
    }
    totalAmount
    discountedAmount
    giftCardAmount
    refundAmount
    paymentRequired
    paymentAmount
    currency
    membership {
      membershipToken
      type
      subType
    }
    giftCards {
      no
      balance
      chargeAmount
    }
    promoCode
    customer {
      firstName
      lastName
      email
      phone
      nationality
      newsletter
    }
  }
  ${HotelRoomCartItemFragmentDoc}
  ${ProductCartItemFragmentDoc}
  ${ActivityCartItemFragmentDoc}
  ${PrivateTransferCartItemFragmentDoc}
  ${AdmissionCartItemFragmentDoc}
  ${TransportationCartItemFragmentDoc}
`
export const HotelReservationActivityFragmentFragmentDoc = gql`
  fragment HotelReservationActivityFragment on HotelReservationActivity {
    bookingRef
    lineNo
    numberOfPersons
    quantity
    productNo
    reservationDate
    comment
    status
    data {
      adultPrice
      pickupTime
      departureDate
      departureTime
      pickupLocationID
      pickupLocationName
      dropoffLocationID
      dropoffLocationName
    }
  }
`
export const HotelReservationFragmentFragmentDoc = gql`
  fragment HotelReservationFragment on HotelReservation {
    reservationNo
    property
    roomType
    rateCode
    adults
    children
    externalReservationNo
    arrivalDate
    departureDate
    status
    groupReservationNo
    guestName
    email
    country
    guestNo
    customerName
    phoneNo
    mobilePhoneNo
    contactName
    nights
    checkInTime
    checkOutTime
    actualCheckInTime
    actualCheckOutTime
    bookedRoomType
    payingRoomType
    extraBeds
    cribs
    cancellationNo
    cancellationDateTime
    activities {
      ...HotelReservationActivityFragment
    }
  }
  ${HotelReservationActivityFragmentFragmentDoc}
`
export const RestrictionFragmentFragmentDoc = gql`
  fragment RestrictionFragment on Restriction {
    dateFrom
    dateTo
    allDays
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
    minimumLeadDays
    minimumLengthOfStay
    maximumLeadDays
    maximumLengthOfStay
    sellLimit
    property
    description
    type
    rateCode
    roomType
  }
`
export const CheckMembershipTdsmDocument = gql`
  mutation checkMembershipTdsm($info: CheckMembershipTdsmInfo!) {
    checkMembershipTdsm(info: $info) {
      membershipRef
      acsInfo {
        acsForm
        acsFormData {
          name
          value
        }
      }
    }
  }
`
export type CheckMembershipTdsmMutationFn = Apollo.MutationFunction<
  CheckMembershipTdsmMutation,
  CheckMembershipTdsmMutationVariables
>

/**
 * __useCheckMembershipTdsmMutation__
 *
 * To run a mutation, you first call `useCheckMembershipTdsmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckMembershipTdsmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkMembershipTdsmMutation, { data, loading, error }] = useCheckMembershipTdsmMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useCheckMembershipTdsmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckMembershipTdsmMutation,
    CheckMembershipTdsmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CheckMembershipTdsmMutation,
    CheckMembershipTdsmMutationVariables
  >(CheckMembershipTdsmDocument, options)
}
export type CheckMembershipTdsmMutationHookResult = ReturnType<
  typeof useCheckMembershipTdsmMutation
>
export type CheckMembershipTdsmMutationResult =
  Apollo.MutationResult<CheckMembershipTdsmMutation>
export type CheckMembershipTdsmMutationOptions = Apollo.BaseMutationOptions<
  CheckMembershipTdsmMutation,
  CheckMembershipTdsmMutationVariables
>
export const ConfirmBookingDocument = gql`
  mutation confirmBooking($booking: ConfirmedBooking!) {
    confirmBooking(booking: $booking) {
      bookingId
      acsInfo {
        acsForm
        acsFormData {
          name
          value
        }
      }
      tdsmInfo {
        tdsmContent
      }
    }
  }
`
export type ConfirmBookingMutationFn = Apollo.MutationFunction<
  ConfirmBookingMutation,
  ConfirmBookingMutationVariables
>

/**
 * __useConfirmBookingMutation__
 *
 * To run a mutation, you first call `useConfirmBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmBookingMutation, { data, loading, error }] = useConfirmBookingMutation({
 *   variables: {
 *      booking: // value for 'booking'
 *   },
 * });
 */
export function useConfirmBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmBookingMutation,
    ConfirmBookingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConfirmBookingMutation,
    ConfirmBookingMutationVariables
  >(ConfirmBookingDocument, options)
}
export type ConfirmBookingMutationHookResult = ReturnType<
  typeof useConfirmBookingMutation
>
export type ConfirmBookingMutationResult =
  Apollo.MutationResult<ConfirmBookingMutation>
export type ConfirmBookingMutationOptions = Apollo.BaseMutationOptions<
  ConfirmBookingMutation,
  ConfirmBookingMutationVariables
>
export const OrderMembershipDocument = gql`
  mutation orderMembership($info: OrderMembershipInformation!) {
    orderMembership(info: $info) {
      membershipRef
      acsInfo {
        acsForm
        acsFormData {
          name
          value
        }
      }
      tdsmInfo {
        tdsmContent
      }
    }
  }
`
export type OrderMembershipMutationFn = Apollo.MutationFunction<
  OrderMembershipMutation,
  OrderMembershipMutationVariables
>

/**
 * __useOrderMembershipMutation__
 *
 * To run a mutation, you first call `useOrderMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderMembershipMutation, { data, loading, error }] = useOrderMembershipMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useOrderMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderMembershipMutation,
    OrderMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OrderMembershipMutation,
    OrderMembershipMutationVariables
  >(OrderMembershipDocument, options)
}
export type OrderMembershipMutationHookResult = ReturnType<
  typeof useOrderMembershipMutation
>
export type OrderMembershipMutationResult =
  Apollo.MutationResult<OrderMembershipMutation>
export type OrderMembershipMutationOptions = Apollo.BaseMutationOptions<
  OrderMembershipMutation,
  OrderMembershipMutationVariables
>
export const ProductsDocument = gql`
  query products {
    products {
      productId
      productType
      productSubType
      fullName
      displayName
      company
      property
      location
      price
      fixedDiscountPercentage
      discountedPrice
      availabilityBasedPricing
    }
  }
`

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    options
  )
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsQuery,
    ProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    options
  )
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>
export type ProductsLazyQueryHookResult = ReturnType<
  typeof useProductsLazyQuery
>
export type ProductsQueryResult = Apollo.QueryResult<
  ProductsQuery,
  ProductsQueryVariables
>
export const SendMembershipLoginCodeDocument = gql`
  mutation sendMembershipLoginCode($info: SendMembershipLoginCodeInfo!) {
    sendMembershipLoginCode(info: $info) {
      phoneNumber
    }
  }
`
export type SendMembershipLoginCodeMutationFn = Apollo.MutationFunction<
  SendMembershipLoginCodeMutation,
  SendMembershipLoginCodeMutationVariables
>

/**
 * __useSendMembershipLoginCodeMutation__
 *
 * To run a mutation, you first call `useSendMembershipLoginCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMembershipLoginCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMembershipLoginCodeMutation, { data, loading, error }] = useSendMembershipLoginCodeMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useSendMembershipLoginCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMembershipLoginCodeMutation,
    SendMembershipLoginCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendMembershipLoginCodeMutation,
    SendMembershipLoginCodeMutationVariables
  >(SendMembershipLoginCodeDocument, options)
}
export type SendMembershipLoginCodeMutationHookResult = ReturnType<
  typeof useSendMembershipLoginCodeMutation
>
export type SendMembershipLoginCodeMutationResult =
  Apollo.MutationResult<SendMembershipLoginCodeMutation>
export type SendMembershipLoginCodeMutationOptions = Apollo.BaseMutationOptions<
  SendMembershipLoginCodeMutation,
  SendMembershipLoginCodeMutationVariables
>
export const UpdateBookingDocument = gql`
  mutation updateBooking($info: BookingInformation!) {
    updateBooking(info: $info) {
      bookingId
      booking {
        adultCount
        adultPrice
        discountedAdultPrice
        childrenCount
        childrenPrice
        discountedChildrenPrice
        totalAmount
        giftCardAmount
        giftCardPaymentAmount
        paymentAmount
        comment
        promoCode
        busTransfer {
          discountedAdultPrice
          adultPrice
        }
        massage {
          discountedPrice
          price
          guests
          productID
          time
        }
        products {
          price
          totalAmount
          productId
          qty
        }
      }
    }
  }
`
export type UpdateBookingMutationFn = Apollo.MutationFunction<
  UpdateBookingMutation,
  UpdateBookingMutationVariables
>

/**
 * __useUpdateBookingMutation__
 *
 * To run a mutation, you first call `useUpdateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingMutation, { data, loading, error }] = useUpdateBookingMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useUpdateBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBookingMutation,
    UpdateBookingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateBookingMutation,
    UpdateBookingMutationVariables
  >(UpdateBookingDocument, options)
}
export type UpdateBookingMutationHookResult = ReturnType<
  typeof useUpdateBookingMutation
>
export type UpdateBookingMutationResult =
  Apollo.MutationResult<UpdateBookingMutation>
export type UpdateBookingMutationOptions = Apollo.BaseMutationOptions<
  UpdateBookingMutation,
  UpdateBookingMutationVariables
>
export const ValidatePaymentDocument = gql`
  mutation validatePayment($payment: PaymentValidationInformation!) {
    validatePayment(payment: $payment) {
      bookingId
    }
  }
`
export type ValidatePaymentMutationFn = Apollo.MutationFunction<
  ValidatePaymentMutation,
  ValidatePaymentMutationVariables
>

/**
 * __useValidatePaymentMutation__
 *
 * To run a mutation, you first call `useValidatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validatePaymentMutation, { data, loading, error }] = useValidatePaymentMutation({
 *   variables: {
 *      payment: // value for 'payment'
 *   },
 * });
 */
export function useValidatePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidatePaymentMutation,
    ValidatePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ValidatePaymentMutation,
    ValidatePaymentMutationVariables
  >(ValidatePaymentDocument, options)
}
export type ValidatePaymentMutationHookResult = ReturnType<
  typeof useValidatePaymentMutation
>
export type ValidatePaymentMutationResult =
  Apollo.MutationResult<ValidatePaymentMutation>
export type ValidatePaymentMutationOptions = Apollo.BaseMutationOptions<
  ValidatePaymentMutation,
  ValidatePaymentMutationVariables
>
export const ValidateEmailDocument = gql`
  mutation validateEmail($info: EmailValidationInformation!) {
    validateEmail(info: $info) {
      valid
    }
  }
`
export type ValidateEmailMutationFn = Apollo.MutationFunction<
  ValidateEmailMutation,
  ValidateEmailMutationVariables
>

/**
 * __useValidateEmailMutation__
 *
 * To run a mutation, you first call `useValidateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateEmailMutation, { data, loading, error }] = useValidateEmailMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useValidateEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateEmailMutation,
    ValidateEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ValidateEmailMutation,
    ValidateEmailMutationVariables
  >(ValidateEmailDocument, options)
}
export type ValidateEmailMutationHookResult = ReturnType<
  typeof useValidateEmailMutation
>
export type ValidateEmailMutationResult =
  Apollo.MutationResult<ValidateEmailMutation>
export type ValidateEmailMutationOptions = Apollo.BaseMutationOptions<
  ValidateEmailMutation,
  ValidateEmailMutationVariables
>
export const ValidateMembershipDocument = gql`
  mutation validateMembership($info: ValidateMembership!) {
    validateMembership(info: $info) {
      type
      name
      userName
      validFrom
      validTo
      firstName
      lastName
      email
      price
      phone
      numberOfCardHolders
      membershipValidationToken
    }
  }
`
export type ValidateMembershipMutationFn = Apollo.MutationFunction<
  ValidateMembershipMutation,
  ValidateMembershipMutationVariables
>

/**
 * __useValidateMembershipMutation__
 *
 * To run a mutation, you first call `useValidateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateMembershipMutation, { data, loading, error }] = useValidateMembershipMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useValidateMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateMembershipMutation,
    ValidateMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ValidateMembershipMutation,
    ValidateMembershipMutationVariables
  >(ValidateMembershipDocument, options)
}
export type ValidateMembershipMutationHookResult = ReturnType<
  typeof useValidateMembershipMutation
>
export type ValidateMembershipMutationResult =
  Apollo.MutationResult<ValidateMembershipMutation>
export type ValidateMembershipMutationOptions = Apollo.BaseMutationOptions<
  ValidateMembershipMutation,
  ValidateMembershipMutationVariables
>
export const ValidateMembershipPaymentDocument = gql`
  mutation validateMembershipPayment(
    $payment: MembershipPaymentValidationInformation!
  ) {
    validateMembershipPayment(payment: $payment) {
      membershipRef
    }
  }
`
export type ValidateMembershipPaymentMutationFn = Apollo.MutationFunction<
  ValidateMembershipPaymentMutation,
  ValidateMembershipPaymentMutationVariables
>

/**
 * __useValidateMembershipPaymentMutation__
 *
 * To run a mutation, you first call `useValidateMembershipPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateMembershipPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateMembershipPaymentMutation, { data, loading, error }] = useValidateMembershipPaymentMutation({
 *   variables: {
 *      payment: // value for 'payment'
 *   },
 * });
 */
export function useValidateMembershipPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateMembershipPaymentMutation,
    ValidateMembershipPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ValidateMembershipPaymentMutation,
    ValidateMembershipPaymentMutationVariables
  >(ValidateMembershipPaymentDocument, options)
}
export type ValidateMembershipPaymentMutationHookResult = ReturnType<
  typeof useValidateMembershipPaymentMutation
>
export type ValidateMembershipPaymentMutationResult =
  Apollo.MutationResult<ValidateMembershipPaymentMutation>
export type ValidateMembershipPaymentMutationOptions =
  Apollo.BaseMutationOptions<
    ValidateMembershipPaymentMutation,
    ValidateMembershipPaymentMutationVariables
  >
export const ValidatePhoneDocument = gql`
  mutation validatePhone($info: PhoneValidationInformation!) {
    validatePhone(info: $info) {
      valid
    }
  }
`
export type ValidatePhoneMutationFn = Apollo.MutationFunction<
  ValidatePhoneMutation,
  ValidatePhoneMutationVariables
>

/**
 * __useValidatePhoneMutation__
 *
 * To run a mutation, you first call `useValidatePhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidatePhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validatePhoneMutation, { data, loading, error }] = useValidatePhoneMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useValidatePhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidatePhoneMutation,
    ValidatePhoneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ValidatePhoneMutation,
    ValidatePhoneMutationVariables
  >(ValidatePhoneDocument, options)
}
export type ValidatePhoneMutationHookResult = ReturnType<
  typeof useValidatePhoneMutation
>
export type ValidatePhoneMutationResult =
  Apollo.MutationResult<ValidatePhoneMutation>
export type ValidatePhoneMutationOptions = Apollo.BaseMutationOptions<
  ValidatePhoneMutation,
  ValidatePhoneMutationVariables
>
export const ValidateTdsmDocument = gql`
  mutation validateTdsm($info: validateTDSMInput!) {
    validateTdsm(info: $info) {
      bookingId
      acsInfo {
        acsForm
        acsFormData {
          name
          value
        }
      }
    }
  }
`
export type ValidateTdsmMutationFn = Apollo.MutationFunction<
  ValidateTdsmMutation,
  ValidateTdsmMutationVariables
>

/**
 * __useValidateTdsmMutation__
 *
 * To run a mutation, you first call `useValidateTdsmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateTdsmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateTdsmMutation, { data, loading, error }] = useValidateTdsmMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useValidateTdsmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateTdsmMutation,
    ValidateTdsmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ValidateTdsmMutation,
    ValidateTdsmMutationVariables
  >(ValidateTdsmDocument, options)
}
export type ValidateTdsmMutationHookResult = ReturnType<
  typeof useValidateTdsmMutation
>
export type ValidateTdsmMutationResult =
  Apollo.MutationResult<ValidateTdsmMutation>
export type ValidateTdsmMutationOptions = Apollo.BaseMutationOptions<
  ValidateTdsmMutation,
  ValidateTdsmMutationVariables
>
export const VerifyMembershipDocument = gql`
  mutation verifyMembership($info: VerifyMembership!) {
    verifyMembership(info: $info) {
      valid
    }
  }
`
export type VerifyMembershipMutationFn = Apollo.MutationFunction<
  VerifyMembershipMutation,
  VerifyMembershipMutationVariables
>

/**
 * __useVerifyMembershipMutation__
 *
 * To run a mutation, you first call `useVerifyMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMembershipMutation, { data, loading, error }] = useVerifyMembershipMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useVerifyMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyMembershipMutation,
    VerifyMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    VerifyMembershipMutation,
    VerifyMembershipMutationVariables
  >(VerifyMembershipDocument, options)
}
export type VerifyMembershipMutationHookResult = ReturnType<
  typeof useVerifyMembershipMutation
>
export type VerifyMembershipMutationResult =
  Apollo.MutationResult<VerifyMembershipMutation>
export type VerifyMembershipMutationOptions = Apollo.BaseMutationOptions<
  VerifyMembershipMutation,
  VerifyMembershipMutationVariables
>
export const VerifyMembershipLoginCodeDocument = gql`
  mutation verifyMembershipLoginCode($info: VerifyMembershipLoginCodeInfo!) {
    verifyMembershipLoginCode(info: $info) {
      mainContact {
        id
        firstName
        lastName
        personalId
        email
        phoneNumber
      }
      secondaryContact {
        id
        firstName
        lastName
        personalId
        email
        phoneNumber
      }
    }
  }
`
export type VerifyMembershipLoginCodeMutationFn = Apollo.MutationFunction<
  VerifyMembershipLoginCodeMutation,
  VerifyMembershipLoginCodeMutationVariables
>

/**
 * __useVerifyMembershipLoginCodeMutation__
 *
 * To run a mutation, you first call `useVerifyMembershipLoginCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMembershipLoginCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMembershipLoginCodeMutation, { data, loading, error }] = useVerifyMembershipLoginCodeMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useVerifyMembershipLoginCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyMembershipLoginCodeMutation,
    VerifyMembershipLoginCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    VerifyMembershipLoginCodeMutation,
    VerifyMembershipLoginCodeMutationVariables
  >(VerifyMembershipLoginCodeDocument, options)
}
export type VerifyMembershipLoginCodeMutationHookResult = ReturnType<
  typeof useVerifyMembershipLoginCodeMutation
>
export type VerifyMembershipLoginCodeMutationResult =
  Apollo.MutationResult<VerifyMembershipLoginCodeMutation>
export type VerifyMembershipLoginCodeMutationOptions =
  Apollo.BaseMutationOptions<
    VerifyMembershipLoginCodeMutation,
    VerifyMembershipLoginCodeMutationVariables
  >
export const ActivityProductAvailabilityDocument = gql`
  query ActivityProductAvailability($input: ActivityProductAvailabilityInput!) {
    activityProductAvailability(input: $input) {
      productId
      offerId
      available
      price
      time
      componentType
      componentId
      title
      subOffers {
        offerId
        date
        price
        additionalInfo {
          id
          title
          adultPrice
          childPrice
          teenagerPrice
        }
      }
    }
  }
`

/**
 * __useActivityProductAvailabilityQuery__
 *
 * To run a query within a React component, call `useActivityProductAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityProductAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityProductAvailabilityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivityProductAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActivityProductAvailabilityQuery,
    ActivityProductAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ActivityProductAvailabilityQuery,
    ActivityProductAvailabilityQueryVariables
  >(ActivityProductAvailabilityDocument, options)
}
export function useActivityProductAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityProductAvailabilityQuery,
    ActivityProductAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ActivityProductAvailabilityQuery,
    ActivityProductAvailabilityQueryVariables
  >(ActivityProductAvailabilityDocument, options)
}
export type ActivityProductAvailabilityQueryHookResult = ReturnType<
  typeof useActivityProductAvailabilityQuery
>
export type ActivityProductAvailabilityLazyQueryHookResult = ReturnType<
  typeof useActivityProductAvailabilityLazyQuery
>
export type ActivityProductAvailabilityQueryResult = Apollo.QueryResult<
  ActivityProductAvailabilityQuery,
  ActivityProductAvailabilityQueryVariables
>
export const AddGiftCardToCartDocument = gql`
  mutation AddGiftCardToCart($input: AddGiftCardInput!) {
    addGiftCard(input: $input) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export type AddGiftCardToCartMutationFn = Apollo.MutationFunction<
  AddGiftCardToCartMutation,
  AddGiftCardToCartMutationVariables
>

/**
 * __useAddGiftCardToCartMutation__
 *
 * To run a mutation, you first call `useAddGiftCardToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGiftCardToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGiftCardToCartMutation, { data, loading, error }] = useAddGiftCardToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGiftCardToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGiftCardToCartMutation,
    AddGiftCardToCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddGiftCardToCartMutation,
    AddGiftCardToCartMutationVariables
  >(AddGiftCardToCartDocument, options)
}
export type AddGiftCardToCartMutationHookResult = ReturnType<
  typeof useAddGiftCardToCartMutation
>
export type AddGiftCardToCartMutationResult =
  Apollo.MutationResult<AddGiftCardToCartMutation>
export type AddGiftCardToCartMutationOptions = Apollo.BaseMutationOptions<
  AddGiftCardToCartMutation,
  AddGiftCardToCartMutationVariables
>
export const AddHotelProductToCartDocument = gql`
  mutation AddHotelProductToCart($input: AddProductToCartInput!) {
    addProductToCart(input: $input) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export type AddHotelProductToCartMutationFn = Apollo.MutationFunction<
  AddHotelProductToCartMutation,
  AddHotelProductToCartMutationVariables
>

/**
 * __useAddHotelProductToCartMutation__
 *
 * To run a mutation, you first call `useAddHotelProductToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHotelProductToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHotelProductToCartMutation, { data, loading, error }] = useAddHotelProductToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddHotelProductToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddHotelProductToCartMutation,
    AddHotelProductToCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddHotelProductToCartMutation,
    AddHotelProductToCartMutationVariables
  >(AddHotelProductToCartDocument, options)
}
export type AddHotelProductToCartMutationHookResult = ReturnType<
  typeof useAddHotelProductToCartMutation
>
export type AddHotelProductToCartMutationResult =
  Apollo.MutationResult<AddHotelProductToCartMutation>
export type AddHotelProductToCartMutationOptions = Apollo.BaseMutationOptions<
  AddHotelProductToCartMutation,
  AddHotelProductToCartMutationVariables
>
export const AddHotelRoomToCartDocument = gql`
  mutation AddHotelRoomToCart($input: AddHotelRoomToCartInput!) {
    addHotelRoomToCart(input: $input) {
      ...HotelRoomCartItem
    }
  }
  ${HotelRoomCartItemFragmentDoc}
`
export type AddHotelRoomToCartMutationFn = Apollo.MutationFunction<
  AddHotelRoomToCartMutation,
  AddHotelRoomToCartMutationVariables
>

/**
 * __useAddHotelRoomToCartMutation__
 *
 * To run a mutation, you first call `useAddHotelRoomToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHotelRoomToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHotelRoomToCartMutation, { data, loading, error }] = useAddHotelRoomToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddHotelRoomToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddHotelRoomToCartMutation,
    AddHotelRoomToCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddHotelRoomToCartMutation,
    AddHotelRoomToCartMutationVariables
  >(AddHotelRoomToCartDocument, options)
}
export type AddHotelRoomToCartMutationHookResult = ReturnType<
  typeof useAddHotelRoomToCartMutation
>
export type AddHotelRoomToCartMutationResult =
  Apollo.MutationResult<AddHotelRoomToCartMutation>
export type AddHotelRoomToCartMutationOptions = Apollo.BaseMutationOptions<
  AddHotelRoomToCartMutation,
  AddHotelRoomToCartMutationVariables
>
export const AddMembershipTokenToCartDocument = gql`
  mutation AddMembershipTokenToCart($input: AddMembershipTokenInput!) {
    addMembershipToken(input: $input) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export type AddMembershipTokenToCartMutationFn = Apollo.MutationFunction<
  AddMembershipTokenToCartMutation,
  AddMembershipTokenToCartMutationVariables
>

/**
 * __useAddMembershipTokenToCartMutation__
 *
 * To run a mutation, you first call `useAddMembershipTokenToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembershipTokenToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembershipTokenToCartMutation, { data, loading, error }] = useAddMembershipTokenToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMembershipTokenToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMembershipTokenToCartMutation,
    AddMembershipTokenToCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddMembershipTokenToCartMutation,
    AddMembershipTokenToCartMutationVariables
  >(AddMembershipTokenToCartDocument, options)
}
export type AddMembershipTokenToCartMutationHookResult = ReturnType<
  typeof useAddMembershipTokenToCartMutation
>
export type AddMembershipTokenToCartMutationResult =
  Apollo.MutationResult<AddMembershipTokenToCartMutation>
export type AddMembershipTokenToCartMutationOptions =
  Apollo.BaseMutationOptions<
    AddMembershipTokenToCartMutation,
    AddMembershipTokenToCartMutationVariables
  >
export const AddProductToCartDocument = gql`
  mutation AddProductToCart($input: AddProductToCartInput!) {
    addProductToCart(input: $input) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export type AddProductToCartMutationFn = Apollo.MutationFunction<
  AddProductToCartMutation,
  AddProductToCartMutationVariables
>

/**
 * __useAddProductToCartMutation__
 *
 * To run a mutation, you first call `useAddProductToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductToCartMutation, { data, loading, error }] = useAddProductToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProductToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProductToCartMutation,
    AddProductToCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddProductToCartMutation,
    AddProductToCartMutationVariables
  >(AddProductToCartDocument, options)
}
export type AddProductToCartMutationHookResult = ReturnType<
  typeof useAddProductToCartMutation
>
export type AddProductToCartMutationResult =
  Apollo.MutationResult<AddProductToCartMutation>
export type AddProductToCartMutationOptions = Apollo.BaseMutationOptions<
  AddProductToCartMutation,
  AddProductToCartMutationVariables
>
export const AddPromoCodeToCartDocument = gql`
  mutation AddPromoCodeToCart($input: AddPromoCodeInput!) {
    addPromoCode(input: $input) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export type AddPromoCodeToCartMutationFn = Apollo.MutationFunction<
  AddPromoCodeToCartMutation,
  AddPromoCodeToCartMutationVariables
>

/**
 * __useAddPromoCodeToCartMutation__
 *
 * To run a mutation, you first call `useAddPromoCodeToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPromoCodeToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPromoCodeToCartMutation, { data, loading, error }] = useAddPromoCodeToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPromoCodeToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPromoCodeToCartMutation,
    AddPromoCodeToCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddPromoCodeToCartMutation,
    AddPromoCodeToCartMutationVariables
  >(AddPromoCodeToCartDocument, options)
}
export type AddPromoCodeToCartMutationHookResult = ReturnType<
  typeof useAddPromoCodeToCartMutation
>
export type AddPromoCodeToCartMutationResult =
  Apollo.MutationResult<AddPromoCodeToCartMutation>
export type AddPromoCodeToCartMutationOptions = Apollo.BaseMutationOptions<
  AddPromoCodeToCartMutation,
  AddPromoCodeToCartMutationVariables
>
export const AddSpaCustomerInfoDocument = gql`
  mutation AddSpaCustomerInfo($input: CustomerInformationInput!) {
    addSpaCustomerInfo(input: $input) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export type AddSpaCustomerInfoMutationFn = Apollo.MutationFunction<
  AddSpaCustomerInfoMutation,
  AddSpaCustomerInfoMutationVariables
>

/**
 * __useAddSpaCustomerInfoMutation__
 *
 * To run a mutation, you first call `useAddSpaCustomerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSpaCustomerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSpaCustomerInfoMutation, { data, loading, error }] = useAddSpaCustomerInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSpaCustomerInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSpaCustomerInfoMutation,
    AddSpaCustomerInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddSpaCustomerInfoMutation,
    AddSpaCustomerInfoMutationVariables
  >(AddSpaCustomerInfoDocument, options)
}
export type AddSpaCustomerInfoMutationHookResult = ReturnType<
  typeof useAddSpaCustomerInfoMutation
>
export type AddSpaCustomerInfoMutationResult =
  Apollo.MutationResult<AddSpaCustomerInfoMutation>
export type AddSpaCustomerInfoMutationOptions = Apollo.BaseMutationOptions<
  AddSpaCustomerInfoMutation,
  AddSpaCustomerInfoMutationVariables
>
export const CheckoutDocument = gql`
  mutation Checkout($input: CheckoutInput!) {
    checkout(input: $input) {
      hotelGroupConfirmationCode
      hotelConfirmationCodes
    }
  }
`
export type CheckoutMutationFn = Apollo.MutationFunction<
  CheckoutMutation,
  CheckoutMutationVariables
>

/**
 * __useCheckoutMutation__
 *
 * To run a mutation, you first call `useCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutMutation, { data, loading, error }] = useCheckoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckoutMutation,
    CheckoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CheckoutMutation, CheckoutMutationVariables>(
    CheckoutDocument,
    options
  )
}
export type CheckoutMutationHookResult = ReturnType<typeof useCheckoutMutation>
export type CheckoutMutationResult = Apollo.MutationResult<CheckoutMutation>
export type CheckoutMutationOptions = Apollo.BaseMutationOptions<
  CheckoutMutation,
  CheckoutMutationVariables
>
export const CheckoutPaymentDocument = gql`
  mutation CheckoutPayment($input: CheckoutPaymentInput!) {
    checkoutPayment(input: $input) {
      bookingNumber
    }
  }
`
export type CheckoutPaymentMutationFn = Apollo.MutationFunction<
  CheckoutPaymentMutation,
  CheckoutPaymentMutationVariables
>

/**
 * __useCheckoutPaymentMutation__
 *
 * To run a mutation, you first call `useCheckoutPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutPaymentMutation, { data, loading, error }] = useCheckoutPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckoutPaymentMutation,
    CheckoutPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CheckoutPaymentMutation,
    CheckoutPaymentMutationVariables
  >(CheckoutPaymentDocument, options)
}
export type CheckoutPaymentMutationHookResult = ReturnType<
  typeof useCheckoutPaymentMutation
>
export type CheckoutPaymentMutationResult =
  Apollo.MutationResult<CheckoutPaymentMutation>
export type CheckoutPaymentMutationOptions = Apollo.BaseMutationOptions<
  CheckoutPaymentMutation,
  CheckoutPaymentMutationVariables
>
export const CreateCartDocument = gql`
  mutation CreateCart($input: CreateCartInput!) {
    createCart(input: $input) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export type CreateCartMutationFn = Apollo.MutationFunction<
  CreateCartMutation,
  CreateCartMutationVariables
>

/**
 * __useCreateCartMutation__
 *
 * To run a mutation, you first call `useCreateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCartMutation, { data, loading, error }] = useCreateCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCartMutation,
    CreateCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCartMutation, CreateCartMutationVariables>(
    CreateCartDocument,
    options
  )
}
export type CreateCartMutationHookResult = ReturnType<
  typeof useCreateCartMutation
>
export type CreateCartMutationResult = Apollo.MutationResult<CreateCartMutation>
export type CreateCartMutationOptions = Apollo.BaseMutationOptions<
  CreateCartMutation,
  CreateCartMutationVariables
>
export const CreatePaymentTransactionDocument = gql`
  mutation CreatePaymentTransaction($input: TransactionInput!) {
    createPaymentTransaction(input: $input) {
      cart {
        ...CartFragment
      }
      transactionId
      paymentId
    }
  }
  ${CartFragmentFragmentDoc}
`
export type CreatePaymentTransactionMutationFn = Apollo.MutationFunction<
  CreatePaymentTransactionMutation,
  CreatePaymentTransactionMutationVariables
>

/**
 * __useCreatePaymentTransactionMutation__
 *
 * To run a mutation, you first call `useCreatePaymentTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentTransactionMutation, { data, loading, error }] = useCreatePaymentTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentTransactionMutation,
    CreatePaymentTransactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePaymentTransactionMutation,
    CreatePaymentTransactionMutationVariables
  >(CreatePaymentTransactionDocument, options)
}
export type CreatePaymentTransactionMutationHookResult = ReturnType<
  typeof useCreatePaymentTransactionMutation
>
export type CreatePaymentTransactionMutationResult =
  Apollo.MutationResult<CreatePaymentTransactionMutation>
export type CreatePaymentTransactionMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePaymentTransactionMutation,
    CreatePaymentTransactionMutationVariables
  >
export const GetCartDocument = gql`
  query GetCart($input: GetCartInput!) {
    getCart(input: $input) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`

/**
 * __useGetCartQuery__
 *
 * To run a query within a React component, call `useGetCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCartQuery(
  baseOptions: Apollo.QueryHookOptions<GetCartQuery, GetCartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCartQuery, GetCartQueryVariables>(
    GetCartDocument,
    options
  )
}
export function useGetCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCartQuery, GetCartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCartQuery, GetCartQueryVariables>(
    GetCartDocument,
    options
  )
}
export type GetCartQueryHookResult = ReturnType<typeof useGetCartQuery>
export type GetCartLazyQueryHookResult = ReturnType<typeof useGetCartLazyQuery>
export type GetCartQueryResult = Apollo.QueryResult<
  GetCartQuery,
  GetCartQueryVariables
>
export const GetCheckoutStatusDocument = gql`
  query GetCheckoutStatus($input: CheckoutStatusInput!) {
    getCheckoutStatus(input: $input) {
      bookingRef
      paymentSuccessful
      confirmSuccessful
      orderSuccessful
      busTransferSuccessful
      finished
      error
      inProgress
    }
  }
`

/**
 * __useGetCheckoutStatusQuery__
 *
 * To run a query within a React component, call `useGetCheckoutStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckoutStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckoutStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCheckoutStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCheckoutStatusQuery,
    GetCheckoutStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCheckoutStatusQuery,
    GetCheckoutStatusQueryVariables
  >(GetCheckoutStatusDocument, options)
}
export function useGetCheckoutStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCheckoutStatusQuery,
    GetCheckoutStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCheckoutStatusQuery,
    GetCheckoutStatusQueryVariables
  >(GetCheckoutStatusDocument, options)
}
export type GetCheckoutStatusQueryHookResult = ReturnType<
  typeof useGetCheckoutStatusQuery
>
export type GetCheckoutStatusLazyQueryHookResult = ReturnType<
  typeof useGetCheckoutStatusLazyQuery
>
export type GetCheckoutStatusQueryResult = Apollo.QueryResult<
  GetCheckoutStatusQuery,
  GetCheckoutStatusQueryVariables
>
export const ProductsCatalogDocument = gql`
  query ProductsCatalog($input: ProductsCatalogInput!) {
    productsCatalog(input: $input) {
      productId
      productType
      productSubType
      fullName
      displayName
      company
      property
      location
      price
      fixedDiscountPercentage
      discountedPrice
      availabilityBasedPricing
      roomType
    }
  }
`

/**
 * __useProductsCatalogQuery__
 *
 * To run a query within a React component, call `useProductsCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsCatalogQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductsCatalogQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductsCatalogQuery,
    ProductsCatalogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductsCatalogQuery, ProductsCatalogQueryVariables>(
    ProductsCatalogDocument,
    options
  )
}
export function useProductsCatalogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsCatalogQuery,
    ProductsCatalogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ProductsCatalogQuery,
    ProductsCatalogQueryVariables
  >(ProductsCatalogDocument, options)
}
export type ProductsCatalogQueryHookResult = ReturnType<
  typeof useProductsCatalogQuery
>
export type ProductsCatalogLazyQueryHookResult = ReturnType<
  typeof useProductsCatalogLazyQuery
>
export type ProductsCatalogQueryResult = Apollo.QueryResult<
  ProductsCatalogQuery,
  ProductsCatalogQueryVariables
>
export const ProductsAvailabilityDocument = gql`
  query ProductsAvailability($input: ProductAvailabilitiesInput!) {
    productAvailabilities(input: $input) {
      productId
      available
      lengthOfStay
      price
      fields
      time
      offerId
      subOffers {
        offerId
        date
        price
      }
    }
  }
`

/**
 * __useProductsAvailabilityQuery__
 *
 * To run a query within a React component, call `useProductsAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsAvailabilityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductsAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductsAvailabilityQuery,
    ProductsAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ProductsAvailabilityQuery,
    ProductsAvailabilityQueryVariables
  >(ProductsAvailabilityDocument, options)
}
export function useProductsAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsAvailabilityQuery,
    ProductsAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ProductsAvailabilityQuery,
    ProductsAvailabilityQueryVariables
  >(ProductsAvailabilityDocument, options)
}
export type ProductsAvailabilityQueryHookResult = ReturnType<
  typeof useProductsAvailabilityQuery
>
export type ProductsAvailabilityLazyQueryHookResult = ReturnType<
  typeof useProductsAvailabilityLazyQuery
>
export type ProductsAvailabilityQueryResult = Apollo.QueryResult<
  ProductsAvailabilityQuery,
  ProductsAvailabilityQueryVariables
>
export const RemoveGiftCardFromCartDocument = gql`
  mutation RemoveGiftCardFromCart($input: RemoveGiftCardInput!) {
    removeGiftCard(input: $input) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export type RemoveGiftCardFromCartMutationFn = Apollo.MutationFunction<
  RemoveGiftCardFromCartMutation,
  RemoveGiftCardFromCartMutationVariables
>

/**
 * __useRemoveGiftCardFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveGiftCardFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGiftCardFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGiftCardFromCartMutation, { data, loading, error }] = useRemoveGiftCardFromCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGiftCardFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGiftCardFromCartMutation,
    RemoveGiftCardFromCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveGiftCardFromCartMutation,
    RemoveGiftCardFromCartMutationVariables
  >(RemoveGiftCardFromCartDocument, options)
}
export type RemoveGiftCardFromCartMutationHookResult = ReturnType<
  typeof useRemoveGiftCardFromCartMutation
>
export type RemoveGiftCardFromCartMutationResult =
  Apollo.MutationResult<RemoveGiftCardFromCartMutation>
export type RemoveGiftCardFromCartMutationOptions = Apollo.BaseMutationOptions<
  RemoveGiftCardFromCartMutation,
  RemoveGiftCardFromCartMutationVariables
>
export const RemoveItemFromCartDocument = gql`
  mutation RemoveItemFromCart($input: RemoveCartItemInput!) {
    removeItem(input: $input) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export type RemoveItemFromCartMutationFn = Apollo.MutationFunction<
  RemoveItemFromCartMutation,
  RemoveItemFromCartMutationVariables
>

/**
 * __useRemoveItemFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromCartMutation, { data, loading, error }] = useRemoveItemFromCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveItemFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveItemFromCartMutation,
    RemoveItemFromCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveItemFromCartMutation,
    RemoveItemFromCartMutationVariables
  >(RemoveItemFromCartDocument, options)
}
export type RemoveItemFromCartMutationHookResult = ReturnType<
  typeof useRemoveItemFromCartMutation
>
export type RemoveItemFromCartMutationResult =
  Apollo.MutationResult<RemoveItemFromCartMutation>
export type RemoveItemFromCartMutationOptions = Apollo.BaseMutationOptions<
  RemoveItemFromCartMutation,
  RemoveItemFromCartMutationVariables
>
export const RemoveMembershipTokenFromCartDocument = gql`
  mutation RemoveMembershipTokenFromCart($input: RemoveMembershipTokenInput!) {
    removeMembershipToken(input: $input) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export type RemoveMembershipTokenFromCartMutationFn = Apollo.MutationFunction<
  RemoveMembershipTokenFromCartMutation,
  RemoveMembershipTokenFromCartMutationVariables
>

/**
 * __useRemoveMembershipTokenFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveMembershipTokenFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMembershipTokenFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMembershipTokenFromCartMutation, { data, loading, error }] = useRemoveMembershipTokenFromCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMembershipTokenFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMembershipTokenFromCartMutation,
    RemoveMembershipTokenFromCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveMembershipTokenFromCartMutation,
    RemoveMembershipTokenFromCartMutationVariables
  >(RemoveMembershipTokenFromCartDocument, options)
}
export type RemoveMembershipTokenFromCartMutationHookResult = ReturnType<
  typeof useRemoveMembershipTokenFromCartMutation
>
export type RemoveMembershipTokenFromCartMutationResult =
  Apollo.MutationResult<RemoveMembershipTokenFromCartMutation>
export type RemoveMembershipTokenFromCartMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveMembershipTokenFromCartMutation,
    RemoveMembershipTokenFromCartMutationVariables
  >
export const ResetCartDocument = gql`
  mutation ResetCart($input: ResetCartInput!) {
    resetCart(input: $input) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export type ResetCartMutationFn = Apollo.MutationFunction<
  ResetCartMutation,
  ResetCartMutationVariables
>

/**
 * __useResetCartMutation__
 *
 * To run a mutation, you first call `useResetCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCartMutation, { data, loading, error }] = useResetCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetCartMutation,
    ResetCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetCartMutation, ResetCartMutationVariables>(
    ResetCartDocument,
    options
  )
}
export type ResetCartMutationHookResult = ReturnType<
  typeof useResetCartMutation
>
export type ResetCartMutationResult = Apollo.MutationResult<ResetCartMutation>
export type ResetCartMutationOptions = Apollo.BaseMutationOptions<
  ResetCartMutation,
  ResetCartMutationVariables
>
export const RoomTypeRestrictionsDocument = gql`
  query roomTypeRestrictions($input: RoomTypeRestrictionsInput!) {
    roomTypeRestrictions(input: $input) {
      ...RestrictionFragment
    }
  }
  ${RestrictionFragmentFragmentDoc}
`

/**
 * __useRoomTypeRestrictionsQuery__
 *
 * To run a query within a React component, call `useRoomTypeRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomTypeRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomTypeRestrictionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRoomTypeRestrictionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoomTypeRestrictionsQuery,
    RoomTypeRestrictionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RoomTypeRestrictionsQuery,
    RoomTypeRestrictionsQueryVariables
  >(RoomTypeRestrictionsDocument, options)
}
export function useRoomTypeRestrictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoomTypeRestrictionsQuery,
    RoomTypeRestrictionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RoomTypeRestrictionsQuery,
    RoomTypeRestrictionsQueryVariables
  >(RoomTypeRestrictionsDocument, options)
}
export type RoomTypeRestrictionsQueryHookResult = ReturnType<
  typeof useRoomTypeRestrictionsQuery
>
export type RoomTypeRestrictionsLazyQueryHookResult = ReturnType<
  typeof useRoomTypeRestrictionsLazyQuery
>
export type RoomTypeRestrictionsQueryResult = Apollo.QueryResult<
  RoomTypeRestrictionsQuery,
  RoomTypeRestrictionsQueryVariables
>
export const RateCodeRestrictionsDocument = gql`
  query rateCodeRestrictions($input: RateCodeRestrictionsInput!) {
    rateCodeRestrictions(input: $input) {
      ...RestrictionFragment
    }
  }
  ${RestrictionFragmentFragmentDoc}
`

/**
 * __useRateCodeRestrictionsQuery__
 *
 * To run a query within a React component, call `useRateCodeRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateCodeRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateCodeRestrictionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRateCodeRestrictionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RateCodeRestrictionsQuery,
    RateCodeRestrictionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RateCodeRestrictionsQuery,
    RateCodeRestrictionsQueryVariables
  >(RateCodeRestrictionsDocument, options)
}
export function useRateCodeRestrictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RateCodeRestrictionsQuery,
    RateCodeRestrictionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RateCodeRestrictionsQuery,
    RateCodeRestrictionsQueryVariables
  >(RateCodeRestrictionsDocument, options)
}
export type RateCodeRestrictionsQueryHookResult = ReturnType<
  typeof useRateCodeRestrictionsQuery
>
export type RateCodeRestrictionsLazyQueryHookResult = ReturnType<
  typeof useRateCodeRestrictionsLazyQuery
>
export type RateCodeRestrictionsQueryResult = Apollo.QueryResult<
  RateCodeRestrictionsQuery,
  RateCodeRestrictionsQueryVariables
>
export const RestrictionsDocument = gql`
  query restrictions($input: RestrictionsInput!) {
    restrictions(input: $input) {
      rateCodeRestriction
      roomTypeRestriction
      propertyRestriction
    }
  }
`

/**
 * __useRestrictionsQuery__
 *
 * To run a query within a React component, call `useRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestrictionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestrictionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RestrictionsQuery,
    RestrictionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RestrictionsQuery, RestrictionsQueryVariables>(
    RestrictionsDocument,
    options
  )
}
export function useRestrictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RestrictionsQuery,
    RestrictionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RestrictionsQuery, RestrictionsQueryVariables>(
    RestrictionsDocument,
    options
  )
}
export type RestrictionsQueryHookResult = ReturnType<
  typeof useRestrictionsQuery
>
export type RestrictionsLazyQueryHookResult = ReturnType<
  typeof useRestrictionsLazyQuery
>
export type RestrictionsQueryResult = Apollo.QueryResult<
  RestrictionsQuery,
  RestrictionsQueryVariables
>
export const RoomAvailabilityDocument = gql`
  query RoomAvailability($input: RoomAvailabilityInput!) {
    roomAvailability(input: $input) {
      productId
      rateCodes
      available
      lengthOfStay
      allowedGuests
      allowedAdults
      allowedChildren
      allowedInfants
      price
      fields
    }
  }
`

/**
 * __useRoomAvailabilityQuery__
 *
 * To run a query within a React component, call `useRoomAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomAvailabilityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRoomAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoomAvailabilityQuery,
    RoomAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RoomAvailabilityQuery, RoomAvailabilityQueryVariables>(
    RoomAvailabilityDocument,
    options
  )
}
export function useRoomAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoomAvailabilityQuery,
    RoomAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RoomAvailabilityQuery,
    RoomAvailabilityQueryVariables
  >(RoomAvailabilityDocument, options)
}
export type RoomAvailabilityQueryHookResult = ReturnType<
  typeof useRoomAvailabilityQuery
>
export type RoomAvailabilityLazyQueryHookResult = ReturnType<
  typeof useRoomAvailabilityLazyQuery
>
export type RoomAvailabilityQueryResult = Apollo.QueryResult<
  RoomAvailabilityQuery,
  RoomAvailabilityQueryVariables
>
export const RoomRangeAvailabilityDocument = gql`
  query RoomRangeAvailability($input: RoomRangeAvailabilityInput!) {
    roomRangeAvailability(input: $input) {
      date
      roomType
      property
      totalRooms
      availableRooms
      validArrivalDate
      validDepartureDate
    }
  }
`

/**
 * __useRoomRangeAvailabilityQuery__
 *
 * To run a query within a React component, call `useRoomRangeAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomRangeAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomRangeAvailabilityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRoomRangeAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoomRangeAvailabilityQuery,
    RoomRangeAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RoomRangeAvailabilityQuery,
    RoomRangeAvailabilityQueryVariables
  >(RoomRangeAvailabilityDocument, options)
}
export function useRoomRangeAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoomRangeAvailabilityQuery,
    RoomRangeAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RoomRangeAvailabilityQuery,
    RoomRangeAvailabilityQueryVariables
  >(RoomRangeAvailabilityDocument, options)
}
export type RoomRangeAvailabilityQueryHookResult = ReturnType<
  typeof useRoomRangeAvailabilityQuery
>
export type RoomRangeAvailabilityLazyQueryHookResult = ReturnType<
  typeof useRoomRangeAvailabilityLazyQuery
>
export type RoomRangeAvailabilityQueryResult = Apollo.QueryResult<
  RoomRangeAvailabilityQuery,
  RoomRangeAvailabilityQueryVariables
>
export const RoomRangeAvailabilityRestrictionsDocument = gql`
  query RoomRangeAvailabilityRestrictions($input: RoomRangeAvailabilityInput!) {
    roomRangeAvailabilityRestrictions(input: $input) {
      date
      roomType
      property
      totalRooms
      availableRooms
      validArrivalDate
      validDepartureDate
    }
  }
`

/**
 * __useRoomRangeAvailabilityRestrictionsQuery__
 *
 * To run a query within a React component, call `useRoomRangeAvailabilityRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomRangeAvailabilityRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomRangeAvailabilityRestrictionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRoomRangeAvailabilityRestrictionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoomRangeAvailabilityRestrictionsQuery,
    RoomRangeAvailabilityRestrictionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RoomRangeAvailabilityRestrictionsQuery,
    RoomRangeAvailabilityRestrictionsQueryVariables
  >(RoomRangeAvailabilityRestrictionsDocument, options)
}
export function useRoomRangeAvailabilityRestrictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoomRangeAvailabilityRestrictionsQuery,
    RoomRangeAvailabilityRestrictionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RoomRangeAvailabilityRestrictionsQuery,
    RoomRangeAvailabilityRestrictionsQueryVariables
  >(RoomRangeAvailabilityRestrictionsDocument, options)
}
export type RoomRangeAvailabilityRestrictionsQueryHookResult = ReturnType<
  typeof useRoomRangeAvailabilityRestrictionsQuery
>
export type RoomRangeAvailabilityRestrictionsLazyQueryHookResult = ReturnType<
  typeof useRoomRangeAvailabilityRestrictionsLazyQuery
>
export type RoomRangeAvailabilityRestrictionsQueryResult = Apollo.QueryResult<
  RoomRangeAvailabilityRestrictionsQuery,
  RoomRangeAvailabilityRestrictionsQueryVariables
>
export const UpdateHotelRoomCartItemDocument = gql`
  mutation UpdateHotelRoomCartItem($input: UpdateHotelRoomCartItemInput!) {
    updateHotelRoomCartItem(input: $input) {
      ...HotelRoomCartItem
    }
  }
  ${HotelRoomCartItemFragmentDoc}
`
export type UpdateHotelRoomCartItemMutationFn = Apollo.MutationFunction<
  UpdateHotelRoomCartItemMutation,
  UpdateHotelRoomCartItemMutationVariables
>

/**
 * __useUpdateHotelRoomCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateHotelRoomCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHotelRoomCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHotelRoomCartItemMutation, { data, loading, error }] = useUpdateHotelRoomCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHotelRoomCartItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHotelRoomCartItemMutation,
    UpdateHotelRoomCartItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateHotelRoomCartItemMutation,
    UpdateHotelRoomCartItemMutationVariables
  >(UpdateHotelRoomCartItemDocument, options)
}
export type UpdateHotelRoomCartItemMutationHookResult = ReturnType<
  typeof useUpdateHotelRoomCartItemMutation
>
export type UpdateHotelRoomCartItemMutationResult =
  Apollo.MutationResult<UpdateHotelRoomCartItemMutation>
export type UpdateHotelRoomCartItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateHotelRoomCartItemMutation,
  UpdateHotelRoomCartItemMutationVariables
>
export const UpdateProductCartItemDocument = gql`
  mutation UpdateProductCartItem($input: UpdateProductCartItemInput!) {
    updateProductCartItem(input: $input) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export type UpdateProductCartItemMutationFn = Apollo.MutationFunction<
  UpdateProductCartItemMutation,
  UpdateProductCartItemMutationVariables
>

/**
 * __useUpdateProductCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateProductCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCartItemMutation, { data, loading, error }] = useUpdateProductCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductCartItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductCartItemMutation,
    UpdateProductCartItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateProductCartItemMutation,
    UpdateProductCartItemMutationVariables
  >(UpdateProductCartItemDocument, options)
}
export type UpdateProductCartItemMutationHookResult = ReturnType<
  typeof useUpdateProductCartItemMutation
>
export type UpdateProductCartItemMutationResult =
  Apollo.MutationResult<UpdateProductCartItemMutation>
export type UpdateProductCartItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductCartItemMutation,
  UpdateProductCartItemMutationVariables
>
export const CreateGiftCardDocument = gql`
  mutation createGiftCard($info: GiftCardInfo!) {
    createGiftCard(info: $info) {
      giftCardNo
    }
  }
`
export type CreateGiftCardMutationFn = Apollo.MutationFunction<
  CreateGiftCardMutation,
  CreateGiftCardMutationVariables
>

/**
 * __useCreateGiftCardMutation__
 *
 * To run a mutation, you first call `useCreateGiftCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGiftCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGiftCardMutation, { data, loading, error }] = useCreateGiftCardMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useCreateGiftCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGiftCardMutation,
    CreateGiftCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateGiftCardMutation,
    CreateGiftCardMutationVariables
  >(CreateGiftCardDocument, options)
}
export type CreateGiftCardMutationHookResult = ReturnType<
  typeof useCreateGiftCardMutation
>
export type CreateGiftCardMutationResult =
  Apollo.MutationResult<CreateGiftCardMutation>
export type CreateGiftCardMutationOptions = Apollo.BaseMutationOptions<
  CreateGiftCardMutation,
  CreateGiftCardMutationVariables
>
export const FinalizeGiftCardDocument = gql`
  mutation finalizeGiftCard($info: FinalizeGiftCardInfo!) {
    finalizeGiftCard(info: $info) {
      giftCard
    }
  }
`
export type FinalizeGiftCardMutationFn = Apollo.MutationFunction<
  FinalizeGiftCardMutation,
  FinalizeGiftCardMutationVariables
>

/**
 * __useFinalizeGiftCardMutation__
 *
 * To run a mutation, you first call `useFinalizeGiftCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeGiftCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeGiftCardMutation, { data, loading, error }] = useFinalizeGiftCardMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useFinalizeGiftCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalizeGiftCardMutation,
    FinalizeGiftCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FinalizeGiftCardMutation,
    FinalizeGiftCardMutationVariables
  >(FinalizeGiftCardDocument, options)
}
export type FinalizeGiftCardMutationHookResult = ReturnType<
  typeof useFinalizeGiftCardMutation
>
export type FinalizeGiftCardMutationResult =
  Apollo.MutationResult<FinalizeGiftCardMutation>
export type FinalizeGiftCardMutationOptions = Apollo.BaseMutationOptions<
  FinalizeGiftCardMutation,
  FinalizeGiftCardMutationVariables
>
export const FindGiftCardDocument = gql`
  query findGiftCard($where: FindGiftCardPayload!) {
    findGiftCard(where: $where) {
      amount
      customerName
      customerEmail
      number
      externalDocumentNo
      status
      status
      reasonBlocked
      validTo
      recipientName
      Message
      recipientEmail
      recipientAddress
      recipientAddress2
      recipientPostCode
      recipientPostCode
      recipientCountryCode
      recipientPhoneNumber
      url
      title
      isExperience
      giftCardName
      shippingMethod
      shippingAmount
    }
  }
`

/**
 * __useFindGiftCardQuery__
 *
 * To run a query within a React component, call `useFindGiftCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindGiftCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindGiftCardQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindGiftCardQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindGiftCardQuery,
    FindGiftCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindGiftCardQuery, FindGiftCardQueryVariables>(
    FindGiftCardDocument,
    options
  )
}
export function useFindGiftCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindGiftCardQuery,
    FindGiftCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindGiftCardQuery, FindGiftCardQueryVariables>(
    FindGiftCardDocument,
    options
  )
}
export type FindGiftCardQueryHookResult = ReturnType<
  typeof useFindGiftCardQuery
>
export type FindGiftCardLazyQueryHookResult = ReturnType<
  typeof useFindGiftCardLazyQuery
>
export type FindGiftCardQueryResult = Apollo.QueryResult<
  FindGiftCardQuery,
  FindGiftCardQueryVariables
>
export const OrderGiftCardDocument = gql`
  mutation orderGiftCard($info: OrderGiftCardInfo!) {
    orderGiftCard(info: $info) {
      giftCardNo
      acsInfo {
        acsForm
        acsFormData {
          name
          value
        }
      }
      tdsmInfo {
        tdsmContent
      }
    }
  }
`
export type OrderGiftCardMutationFn = Apollo.MutationFunction<
  OrderGiftCardMutation,
  OrderGiftCardMutationVariables
>

/**
 * __useOrderGiftCardMutation__
 *
 * To run a mutation, you first call `useOrderGiftCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderGiftCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderGiftCardMutation, { data, loading, error }] = useOrderGiftCardMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useOrderGiftCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderGiftCardMutation,
    OrderGiftCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OrderGiftCardMutation,
    OrderGiftCardMutationVariables
  >(OrderGiftCardDocument, options)
}
export type OrderGiftCardMutationHookResult = ReturnType<
  typeof useOrderGiftCardMutation
>
export type OrderGiftCardMutationResult =
  Apollo.MutationResult<OrderGiftCardMutation>
export type OrderGiftCardMutationOptions = Apollo.BaseMutationOptions<
  OrderGiftCardMutation,
  OrderGiftCardMutationVariables
>
export const ValidateGiftCardAcsDocument = gql`
  mutation validateGiftCardACS($info: ValidateGiftCardACSInfo!) {
    validateGiftCardACS(info: $info) {
      giftCardNo
    }
  }
`
export type ValidateGiftCardAcsMutationFn = Apollo.MutationFunction<
  ValidateGiftCardAcsMutation,
  ValidateGiftCardAcsMutationVariables
>

/**
 * __useValidateGiftCardAcsMutation__
 *
 * To run a mutation, you first call `useValidateGiftCardAcsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateGiftCardAcsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateGiftCardAcsMutation, { data, loading, error }] = useValidateGiftCardAcsMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useValidateGiftCardAcsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateGiftCardAcsMutation,
    ValidateGiftCardAcsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ValidateGiftCardAcsMutation,
    ValidateGiftCardAcsMutationVariables
  >(ValidateGiftCardAcsDocument, options)
}
export type ValidateGiftCardAcsMutationHookResult = ReturnType<
  typeof useValidateGiftCardAcsMutation
>
export type ValidateGiftCardAcsMutationResult =
  Apollo.MutationResult<ValidateGiftCardAcsMutation>
export type ValidateGiftCardAcsMutationOptions = Apollo.BaseMutationOptions<
  ValidateGiftCardAcsMutation,
  ValidateGiftCardAcsMutationVariables
>
export const ValidateGiftCardTdsmDocument = gql`
  mutation validateGiftCardTDSM($info: ValidateGiftCardTDSMInfo!) {
    validateGiftCardTDSM(info: $info) {
      giftCardNo
      acsInfo {
        acsForm
        acsFormData {
          name
          value
        }
      }
    }
  }
`
export type ValidateGiftCardTdsmMutationFn = Apollo.MutationFunction<
  ValidateGiftCardTdsmMutation,
  ValidateGiftCardTdsmMutationVariables
>

/**
 * __useValidateGiftCardTdsmMutation__
 *
 * To run a mutation, you first call `useValidateGiftCardTdsmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateGiftCardTdsmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateGiftCardTdsmMutation, { data, loading, error }] = useValidateGiftCardTdsmMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useValidateGiftCardTdsmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateGiftCardTdsmMutation,
    ValidateGiftCardTdsmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ValidateGiftCardTdsmMutation,
    ValidateGiftCardTdsmMutationVariables
  >(ValidateGiftCardTdsmDocument, options)
}
export type ValidateGiftCardTdsmMutationHookResult = ReturnType<
  typeof useValidateGiftCardTdsmMutation
>
export type ValidateGiftCardTdsmMutationResult =
  Apollo.MutationResult<ValidateGiftCardTdsmMutation>
export type ValidateGiftCardTdsmMutationOptions = Apollo.BaseMutationOptions<
  ValidateGiftCardTdsmMutation,
  ValidateGiftCardTdsmMutationVariables
>
export const MassageAndFloatAvailabilityDocument = gql`
  query massageAndFloatAvailability(
    $date: String!
    $arrivalTime: String!
    $locale: String!
  ) {
    massageAndFloatAvailability(
      date: $date
      arrivalTime: $arrivalTime
      locale: $locale
    ) {
      massages {
        available
        duration
        id
        price
        title
        type
        validForCount
        slots {
          available
          time
        }
      }
      floats {
        available
        duration
        id
        price
        title
        type
        validForCount
        slots {
          available
          time
        }
      }
    }
  }
`

/**
 * __useMassageAndFloatAvailabilityQuery__
 *
 * To run a query within a React component, call `useMassageAndFloatAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useMassageAndFloatAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMassageAndFloatAvailabilityQuery({
 *   variables: {
 *      date: // value for 'date'
 *      arrivalTime: // value for 'arrivalTime'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useMassageAndFloatAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    MassageAndFloatAvailabilityQuery,
    MassageAndFloatAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    MassageAndFloatAvailabilityQuery,
    MassageAndFloatAvailabilityQueryVariables
  >(MassageAndFloatAvailabilityDocument, options)
}
export function useMassageAndFloatAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MassageAndFloatAvailabilityQuery,
    MassageAndFloatAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MassageAndFloatAvailabilityQuery,
    MassageAndFloatAvailabilityQueryVariables
  >(MassageAndFloatAvailabilityDocument, options)
}
export type MassageAndFloatAvailabilityQueryHookResult = ReturnType<
  typeof useMassageAndFloatAvailabilityQuery
>
export type MassageAndFloatAvailabilityLazyQueryHookResult = ReturnType<
  typeof useMassageAndFloatAvailabilityLazyQuery
>
export type MassageAndFloatAvailabilityQueryResult = Apollo.QueryResult<
  MassageAndFloatAvailabilityQuery,
  MassageAndFloatAvailabilityQueryVariables
>
export const PackageAvailabilityDocument = gql`
  query packageAvailability($date: String!, $subscription: String) {
    packageAvailability(date: $date, subscription: $subscription) {
      date
      slots {
        time
        packages {
          available
          price
          type
          slots {
            time
            available
            price
            type
          }
        }
      }
    }
  }
`

/**
 * __usePackageAvailabilityQuery__
 *
 * To run a query within a React component, call `usePackageAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageAvailabilityQuery({
 *   variables: {
 *      date: // value for 'date'
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function usePackageAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    PackageAvailabilityQuery,
    PackageAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PackageAvailabilityQuery,
    PackageAvailabilityQueryVariables
  >(PackageAvailabilityDocument, options)
}
export function usePackageAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PackageAvailabilityQuery,
    PackageAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PackageAvailabilityQuery,
    PackageAvailabilityQueryVariables
  >(PackageAvailabilityDocument, options)
}
export type PackageAvailabilityQueryHookResult = ReturnType<
  typeof usePackageAvailabilityQuery
>
export type PackageAvailabilityLazyQueryHookResult = ReturnType<
  typeof usePackageAvailabilityLazyQuery
>
export type PackageAvailabilityQueryResult = Apollo.QueryResult<
  PackageAvailabilityQuery,
  PackageAvailabilityQueryVariables
>
export const DayAvailabilityDocument = gql`
  query dayAvailability($startDate: String!, $endDate: String!) {
    dayAvailability(startDate: $startDate, endDate: $endDate) {
      available
      date
      lowestPrice
      soldOut
    }
  }
`

/**
 * __useDayAvailabilityQuery__
 *
 * To run a query within a React component, call `useDayAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useDayAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDayAvailabilityQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDayAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    DayAvailabilityQuery,
    DayAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DayAvailabilityQuery, DayAvailabilityQueryVariables>(
    DayAvailabilityDocument,
    options
  )
}
export function useDayAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DayAvailabilityQuery,
    DayAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DayAvailabilityQuery,
    DayAvailabilityQueryVariables
  >(DayAvailabilityDocument, options)
}
export type DayAvailabilityQueryHookResult = ReturnType<
  typeof useDayAvailabilityQuery
>
export type DayAvailabilityLazyQueryHookResult = ReturnType<
  typeof useDayAvailabilityLazyQuery
>
export type DayAvailabilityQueryResult = Apollo.QueryResult<
  DayAvailabilityQuery,
  DayAvailabilityQueryVariables
>
export const GetTransactionStatusDocument = gql`
  query getTransactionStatus($where: TransactionStatusQuery!) {
    getTransactionStatus(where: $where) {
      data {
        refNo
        data {
          transactionId
          status
          refNo
          detail {
            authorize {
              amount
            }
            fail {
              reason
              message
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetTransactionStatusQuery__
 *
 * To run a query within a React component, call `useGetTransactionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionStatusQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTransactionStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTransactionStatusQuery,
    GetTransactionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTransactionStatusQuery,
    GetTransactionStatusQueryVariables
  >(GetTransactionStatusDocument, options)
}
export function useGetTransactionStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransactionStatusQuery,
    GetTransactionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTransactionStatusQuery,
    GetTransactionStatusQueryVariables
  >(GetTransactionStatusDocument, options)
}
export type GetTransactionStatusQueryHookResult = ReturnType<
  typeof useGetTransactionStatusQuery
>
export type GetTransactionStatusLazyQueryHookResult = ReturnType<
  typeof useGetTransactionStatusLazyQuery
>
export type GetTransactionStatusQueryResult = Apollo.QueryResult<
  GetTransactionStatusQuery,
  GetTransactionStatusQueryVariables
>
export const InitializePaymentDocument = gql`
  mutation initializePayment($body: PaymentInitialize!) {
    initializePayment(body: $body) {
      transactionId
    }
  }
`
export type InitializePaymentMutationFn = Apollo.MutationFunction<
  InitializePaymentMutation,
  InitializePaymentMutationVariables
>

/**
 * __useInitializePaymentMutation__
 *
 * To run a mutation, you first call `useInitializePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializePaymentMutation, { data, loading, error }] = useInitializePaymentMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useInitializePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitializePaymentMutation,
    InitializePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InitializePaymentMutation,
    InitializePaymentMutationVariables
  >(InitializePaymentDocument, options)
}
export type InitializePaymentMutationHookResult = ReturnType<
  typeof useInitializePaymentMutation
>
export type InitializePaymentMutationResult =
  Apollo.MutationResult<InitializePaymentMutation>
export type InitializePaymentMutationOptions = Apollo.BaseMutationOptions<
  InitializePaymentMutation,
  InitializePaymentMutationVariables
>
export const RestaurantAvailabilityDocument = gql`
  query restaurantAvailability($date: String!) {
    restaurantAvailability(date: $date) {
      date
      slots {
        available
        time
      }
    }
  }
`

/**
 * __useRestaurantAvailabilityQuery__
 *
 * To run a query within a React component, call `useRestaurantAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantAvailabilityQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useRestaurantAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    RestaurantAvailabilityQuery,
    RestaurantAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RestaurantAvailabilityQuery,
    RestaurantAvailabilityQueryVariables
  >(RestaurantAvailabilityDocument, options)
}
export function useRestaurantAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RestaurantAvailabilityQuery,
    RestaurantAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RestaurantAvailabilityQuery,
    RestaurantAvailabilityQueryVariables
  >(RestaurantAvailabilityDocument, options)
}
export type RestaurantAvailabilityQueryHookResult = ReturnType<
  typeof useRestaurantAvailabilityQuery
>
export type RestaurantAvailabilityLazyQueryHookResult = ReturnType<
  typeof useRestaurantAvailabilityLazyQuery
>
export type RestaurantAvailabilityQueryResult = Apollo.QueryResult<
  RestaurantAvailabilityQuery,
  RestaurantAvailabilityQueryVariables
>
export const TransportationDataDocument = gql`
  query transportationData {
    transportationData {
      airport {
        id
        name
      }
      prices {
        adultPrice
      }
    }
  }
`

/**
 * __useTransportationDataQuery__
 *
 * To run a query within a React component, call `useTransportationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransportationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransportationDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransportationDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TransportationDataQuery,
    TransportationDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TransportationDataQuery,
    TransportationDataQueryVariables
  >(TransportationDataDocument, options)
}
export function useTransportationDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransportationDataQuery,
    TransportationDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TransportationDataQuery,
    TransportationDataQueryVariables
  >(TransportationDataDocument, options)
}
export type TransportationDataQueryHookResult = ReturnType<
  typeof useTransportationDataQuery
>
export type TransportationDataLazyQueryHookResult = ReturnType<
  typeof useTransportationDataLazyQuery
>
export type TransportationDataQueryResult = Apollo.QueryResult<
  TransportationDataQuery,
  TransportationDataQueryVariables
>
export const DepartureDetailsDocument = gql`
  query departureDetails($date: String!, $arrivalTime: String) {
    departureDetails(date: $date, arrivalTime: $arrivalTime) {
      airport {
        availability
        time
      }
      city {
        availability
        time
      }
      error
    }
  }
`

/**
 * __useDepartureDetailsQuery__
 *
 * To run a query within a React component, call `useDepartureDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartureDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartureDetailsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      arrivalTime: // value for 'arrivalTime'
 *   },
 * });
 */
export function useDepartureDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DepartureDetailsQuery,
    DepartureDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DepartureDetailsQuery, DepartureDetailsQueryVariables>(
    DepartureDetailsDocument,
    options
  )
}
export function useDepartureDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DepartureDetailsQuery,
    DepartureDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DepartureDetailsQuery,
    DepartureDetailsQueryVariables
  >(DepartureDetailsDocument, options)
}
export type DepartureDetailsQueryHookResult = ReturnType<
  typeof useDepartureDetailsQuery
>
export type DepartureDetailsLazyQueryHookResult = ReturnType<
  typeof useDepartureDetailsLazyQuery
>
export type DepartureDetailsQueryResult = Apollo.QueryResult<
  DepartureDetailsQuery,
  DepartureDetailsQueryVariables
>
export const DirectionsDocument = gql`
  query directions(
    $startLatitude: Float!
    $startLongitude: Float!
    $endLatitude: Float!
    $endLongitude: Float!
  ) {
    directions(
      startLatitude: $startLatitude
      startLongitude: $startLongitude
      endLatitude: $endLatitude
      endLongitude: $endLongitude
    ) {
      coordinates
    }
  }
`

/**
 * __useDirectionsQuery__
 *
 * To run a query within a React component, call `useDirectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectionsQuery({
 *   variables: {
 *      startLatitude: // value for 'startLatitude'
 *      startLongitude: // value for 'startLongitude'
 *      endLatitude: // value for 'endLatitude'
 *      endLongitude: // value for 'endLongitude'
 *   },
 * });
 */
export function useDirectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DirectionsQuery,
    DirectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DirectionsQuery, DirectionsQueryVariables>(
    DirectionsDocument,
    options
  )
}
export function useDirectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DirectionsQuery,
    DirectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DirectionsQuery, DirectionsQueryVariables>(
    DirectionsDocument,
    options
  )
}
export type DirectionsQueryHookResult = ReturnType<typeof useDirectionsQuery>
export type DirectionsLazyQueryHookResult = ReturnType<
  typeof useDirectionsLazyQuery
>
export type DirectionsQueryResult = Apollo.QueryResult<
  DirectionsQuery,
  DirectionsQueryVariables
>
export const DropoffLocationsDocument = gql`
  query dropoffLocations {
    dropoffLocations {
      id
      name
      latitude
      longitude
      bus
      address
      busStop {
        id
        name
        number
        address
        latitude
        longitude
      }
    }
  }
`

/**
 * __useDropoffLocationsQuery__
 *
 * To run a query within a React component, call `useDropoffLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDropoffLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDropoffLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDropoffLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DropoffLocationsQuery,
    DropoffLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DropoffLocationsQuery, DropoffLocationsQueryVariables>(
    DropoffLocationsDocument,
    options
  )
}
export function useDropoffLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DropoffLocationsQuery,
    DropoffLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DropoffLocationsQuery,
    DropoffLocationsQueryVariables
  >(DropoffLocationsDocument, options)
}
export type DropoffLocationsQueryHookResult = ReturnType<
  typeof useDropoffLocationsQuery
>
export type DropoffLocationsLazyQueryHookResult = ReturnType<
  typeof useDropoffLocationsLazyQuery
>
export type DropoffLocationsQueryResult = Apollo.QueryResult<
  DropoffLocationsQuery,
  DropoffLocationsQueryVariables
>
export const PickupDetailsDocument = gql`
  query pickupDetails($date: String!, $arrivalTime: String) {
    pickupDetails(date: $date, arrivalTime: $arrivalTime) {
      airport {
        availability
        time
      }
      city {
        availability
        time
      }
      error
    }
  }
`

/**
 * __usePickupDetailsQuery__
 *
 * To run a query within a React component, call `usePickupDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupDetailsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      arrivalTime: // value for 'arrivalTime'
 *   },
 * });
 */
export function usePickupDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PickupDetailsQuery,
    PickupDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PickupDetailsQuery, PickupDetailsQueryVariables>(
    PickupDetailsDocument,
    options
  )
}
export function usePickupDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PickupDetailsQuery,
    PickupDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PickupDetailsQuery, PickupDetailsQueryVariables>(
    PickupDetailsDocument,
    options
  )
}
export type PickupDetailsQueryHookResult = ReturnType<
  typeof usePickupDetailsQuery
>
export type PickupDetailsLazyQueryHookResult = ReturnType<
  typeof usePickupDetailsLazyQuery
>
export type PickupDetailsQueryResult = Apollo.QueryResult<
  PickupDetailsQuery,
  PickupDetailsQueryVariables
>
export const PickupLocationsDocument = gql`
  query pickupLocations {
    pickupLocations {
      id
      name
      latitude
      longitude
      bus
      address
      busStop {
        id
        name
        number
        address
        latitude
        longitude
      }
    }
  }
`

/**
 * __usePickupLocationsQuery__
 *
 * To run a query within a React component, call `usePickupLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePickupLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PickupLocationsQuery,
    PickupLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PickupLocationsQuery, PickupLocationsQueryVariables>(
    PickupLocationsDocument,
    options
  )
}
export function usePickupLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PickupLocationsQuery,
    PickupLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PickupLocationsQuery,
    PickupLocationsQueryVariables
  >(PickupLocationsDocument, options)
}
export type PickupLocationsQueryHookResult = ReturnType<
  typeof usePickupLocationsQuery
>
export type PickupLocationsLazyQueryHookResult = ReturnType<
  typeof usePickupLocationsLazyQuery
>
export type PickupLocationsQueryResult = Apollo.QueryResult<
  PickupLocationsQuery,
  PickupLocationsQueryVariables
>
