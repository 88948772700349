import { gql } from '@apollo/client'

export const GET_DEPARTURE_DETAILS = gql`
  query departureDetails($date: String!, $arrivalTime: String) {
    departureDetails(date: $date, arrivalTime: $arrivalTime) {
      airport {
        availability
        time
      }
      city {
        availability
        time
      }
      error
    }
  }
`
