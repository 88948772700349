import { breakpoints } from '../constants/breakpoints'

type BetweenOptions = {
  fromWidth?: number
  toWidth?: number
  slopeUnit?: string
}

/**
 * Computes a CSS calc function that betweens a value from
 * A to B over viewport-width A to viewport-width B.
 * Requires a media query to cap the value at B.
 */

/**
 * @deprecated Use ui-primitives/src/utils/between instead
 */
export function between(from, to, options: BetweenOptions = {}) {
  if (!to || !from) {
    return ''
  }

  const {
    fromWidth = breakpoints.sm,
    toWidth = breakpoints.lg,
    slopeUnit = 'vw',
  } = options

  const slope = Number((to - from) / (toWidth - fromWidth)).toPrecision(5)
  const base = Number(from - Number(slope) * fromWidth).toPrecision(5)

  return `calc(${base}px + 100${slopeUnit} * ${slope})`
}
