import { gql } from '@apollo/client'

export const GET_ACTIVE_MASSAGES = gql`
  query massageAndFloatAvailability(
    $date: String!
    $arrivalTime: String!
    $locale: String!
  ) {
    massageAndFloatAvailability(
      date: $date
      arrivalTime: $arrivalTime
      locale: $locale
    ) {
      massages {
        available
        duration
        id
        price
        title
        type
        validForCount
        slots {
          available
          time
        }
      }
      floats {
        available
        duration
        id
        price
        title
        type
        validForCount
        slots {
          available
          time
        }
      }
    }
  }
`
