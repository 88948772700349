import { gql } from '@apollo/client'

export const CHECK_MEMBERSHIP_TDSM = gql`
  mutation checkMembershipTdsm($info: CheckMembershipTdsmInfo!) {
    checkMembershipTdsm(info: $info) {
      membershipRef
      acsInfo {
        acsForm
        acsFormData {
          name
          value
        }
      }
    }
  }
`
